import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import {
  CartPlaceOrderBtn,
  CartTotalText,
  CartTotalWrapper,
} from "../styled/Cart";
import { ProductPrice } from "../styled/ProductBox";

const CartTotal = ({ totalItems, totalAmount, clickHandler, btnText }) => {
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  return (
    <CartTotalWrapper py={1.5} px={2}>
      <Stack direction="row" mx={2.5}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex",
          }}
        >
          <CartTotalText>{totalItems} Total item</CartTotalText>
          {totalAmount > 0 && (
            <ProductPrice color='#32842B' mt={1}>
              {currencySymbol} {totalAmount}
            </ProductPrice>
          )}
        </Box>
        <Box ml="auto">
          <CartPlaceOrderBtn
            endIcon={<ArrowForwardIcon />}
            sx={{ py: 1.25, px: 4, textTransform: "capitalize" }}
            onClick={clickHandler}
            disabled={+totalItems === 0}
          >
            {btnText}
          </CartPlaceOrderBtn>
        </Box>
      </Stack>
    </CartTotalWrapper>
  );
};

export default CartTotal;
