import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, useState } from "react";
import { formatAMPM } from "../../utils/funcs";
import { HCTheme } from "../../utils/theme";
import { PolicyLinkNew, PolicyText } from "../styled/HotelDetails";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { PageSubHeading } from "../styled/PageSubHeading";
import { ProductPaper, ProductPrice, ProductTitle } from "../styled/ProductBox";
import open from '../../assets/svg/open.svg'
import close from '../../assets/svg/close.svg'
import CONSTANT from "../../utils/constant";

const TimingsHotelDetails = ({ restaurantTimings, specialServiceTimings }) => {
  const [isItemsOpen, setIsItemsOpen] = useState(true)
  const [isSpecialService, setIsSpecialService] = useState(true)
  return (
    <div>

      <Stack flex='1' sx={{ mr: 2 }} direction='row' justifyContent="space-between" >
        <PageSubHeading sx={{ mt: 2 }}>Timings</PageSubHeading>
        <div style={{
          marginTop: '20px',
          cursor: 'pointer'
        }} onClick={() => {
          setIsItemsOpen(prev => !prev)
        }}>
          <img src={isItemsOpen ? close : open} alt="close" style={{ width: '15px' }} />
        </div>
      </Stack>
      {isItemsOpen ? <Stack
        spacing={2}
        sx={{ p: 2, borderRadius: 2 }}
      >
        {restaurantTimings?.length ? (
          <>
            {restaurantTimings.map((timing) => (

              <div style={{ margin: '4px 0px' }}>
                <ProductPaper sx={{ py: 3, px: 2.5 }}>
                  <Stack>
                    <ProductTitle>
                      {timing?.name}
                    </ProductTitle>

                    <Stack direction='row' sx={{ mt: '6px', ml: '-6px' }}>
                      <div style={{ margin: '0px 5px' }}>
                        <AccessTimeIcon sx={{ fontSize: '18px' }} />
                      </div>
                      <div style={{ margin: '0px 0px', fontSize: '12px', color: '#757575', fontWeight: '600' }}>
                        {formatAMPM(timing?.startTime)} to {formatAMPM(timing?.endTime)}
                      </div>
                    </Stack>
                  </Stack>
                </ProductPaper>
              </div>
            ))}
          </>
        ) : (
          <Stack
            spacing={3}
            p={10}
            sx={{ background: "#DEDEDE", borderRadius: 2 }}
          >
            <PageSubHeading sx={{ textAlign: "center" }}>
              No timings found.
            </PageSubHeading>
          </Stack>
        )}
      </Stack> : <></>}


      <Stack flex='1' sx={{ mr: 2 }} direction='row' justifyContent="space-between" >
        <PageSubHeading sx={{ mt: 2.5 }}>Special Services</PageSubHeading>
        <div style={{
          marginTop: '20px',
          cursor: 'pointer'
        }} onClick={() => {
          setIsSpecialService(prev => !prev)
        }}>
          <img src={isSpecialService ? close : open} alt="close" style={{ width: '15px' }} />
        </div>
      </Stack>
      {isSpecialService ? <Stack
        spacing={2}
        sx={{ p: 2, borderRadius: 2 }}
      >
        {specialServiceTimings?.length ? (
          <>
            {specialServiceTimings.map((timing) => (
              <div style={{ margin: '4px 0px' }}>
                <ProductPaper sx={{ py: 3, px: 2.5 }}>
                  <Stack>
                    <ProductTitle>
                      {timing?.name}
                    </ProductTitle>

                    <Stack direction='row' sx={{ mt: '6px', ml: '-6px' }}>
                      <div style={{ margin: '0px 5px' }}>
                        <AccessTimeIcon sx={{ fontSize: '18px' }} />
                      </div>
                      <div style={{ margin: '0px 0px', fontSize: '12px', color: '#757575', fontWeight: '600' }}>
                        {formatAMPM(timing?.startTime)} to {formatAMPM(timing?.endTime)}
                      </div>
                    </Stack>
                  </Stack>
                </ProductPaper>
              </div>
            ))}
          </>
        ) : (
          <>
            <Stack
              spacing={3}
              p={10}
              mt={2}
              sx={{ background: "#DEDEDE", borderRadius: 2 }}
            >
              <PageSubHeading sx={{ textAlign: "center" }}>
                No timings found.
              </PageSubHeading>
            </Stack>
          </>
        )}
      </Stack> : <></>}

      {(specialServiceTimings?.length !== 0 || restaurantTimings?.length) && <PolicyLinkNew sx={{ mt: 2.5, textAlign: "center" }} style={{
        maxWidth: '170px',
        textAlign: 'center',
        margin: ' 0px auto',
        marginTop: '10px'
      }}>
        <a
          href={CONSTANT.hotelcomPolicyUrl}
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions of Hotelcom
        </a>
      </PolicyLinkNew>}
    </div>
  );
};

export default TimingsHotelDetails;
