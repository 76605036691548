import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const PageHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "25px",
  lineHeight: "34px",
  color: "#0C0F0A",
}));
