import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
} from "@mui/material";
import { Container } from "@mui/system";
import { onValue, ref } from "firebase/database";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CartItem from "../components/cart/CartItem";
import CartSummary from "../components/cart/CartSummary";
import RoomServiceCartProduct from "../components/general/RoomServiceCartProduct";
import SendingOrder from "../components/general/SendingOrder";
import { ButtonHC } from "../components/styled/Button";
import { PageHeading } from "../components/styled/PageHeading";
import { PageSubHeading } from "../components/styled/PageSubHeading";
import useHttp from "../Hooks/use-http";
import CONSTANT from "../utils/constant";
import { notify } from "../utils/funcs";
import { HCTheme } from "../utils/theme";
import database from "./../utils/firebase-config";
import gifInReview from "./../assets/gif/in-review.gif";
import gifAccepted from "./../assets/gif/accepted.gif";

let gt;

const Cart = () => {
  const search = useLocation().search;
  const section = new URLSearchParams(search).get("section");
  let { statusId: parentId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const getMyAllOrders = useHttp();
  const confirmOrders = useHttp();
  const cancelOrders = useHttp();
  const [orders, setOrders] = useState([]);
  const [showPendingGif, setShowPendingGif] = useState(true);
  const [gif, setGif] = useState(gifInReview);
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [isConfirmCancelOrderDialog, setIsConfirmCancelOrderDialog] =
    useState(false);
  const [calculatedData, setCalculatedData] = useState({
    cgst: 0,
    sgst: 0,
    CGSTValue: 0,
    SGSTValue: 0,
    itemTotal: 0,
    grandTotal: 0,
    taxTotal: 0,
  });

  const handleClose = () => {
    setIsConfirmDialog(false);
  };

  const handlerGetMyAllOrders = useCallback((responseData) => {
    setOrders(responseData?.data?.orders);
    setCalculatedData({
      itemTotal: responseData?.data?.itemTotal,
      grandTotal: responseData?.data?.grandTotal,
      taxTotal: responseData?.data?.taxTotal,
    });
    gt = responseData?.data?.grandTotal;
  }, []);

  const loadMyAllOrders = () => {
    let getMyAllOrdersUrl = {
      type: "GET",
      endpoint: `/orders/users?parentId=${parentId || localStorage.getItem("statusId")
        }&section`,
    };

    getMyAllOrders.sendRequest(getMyAllOrdersUrl, handlerGetMyAllOrders, {});
  };

  useEffect(() => {
    loadMyAllOrders();
    const statusId = localStorage.getItem("statusId");
    const ordersRef = ref(database, `/orders/${statusId}`);
    onValue(ordersRef, (snapshot) => {
      const data = snapshot.val();
      // setShowPendingGif(data?.status === "Done" ? false : true);
      if (data?.status === "Done") {
        setGif(gifAccepted);
        setTimeout(() => {
          setShowPendingGif(false);
        }, 3000);
      } else {
        setGif(gifInReview);
        setTimeout(() => {
          setShowPendingGif(true);
        }, 3000);
      }
    });
  }, []);

  useEffect(() => {
    loadMyAllOrders();
  }, [showPendingGif]);

  const handlerConfirmOrders = useCallback((responseData) => {
    notify.success("Order placed successfully.");
    navigate(`/order-history`);
  }, []);

  const handlerPlaceOrder = () => {
    let confirmOrdersUrl = {
      type: "PATCH",
      endpoint: "/orders/confirm/with/cash",
    };

    if (section) {
      confirmOrdersUrl.endpoint = `/orders/confirm/with/cash?section=${section}`;
    }

    confirmOrders.sendRequest(confirmOrdersUrl, handlerConfirmOrders, {
      parentId,
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const { data } = await fetch(
      `${process.env.REACT_APP_BASE_URL}/transactions/pay/online`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
        body: JSON.stringify({
          section: section || "",
          amount: gt,
          parentId: parentId,
        }),
      }
    )
      .then((t) => t.json())
      .catch((err) => res.send(err));

    const options = {
      key: data.RAZORPAY_KEY_ID,
      currency: data.order.currency,
      amount: data.order.amount,
      order_id: data.order.id,
      name: "Hotelcom",
      description: "Thank you for nothing. Please give us some money",
      notes: data.order.notes,

      handler: function (response) {
        navigate(`/order-history`);
        if (response.razorpay_payment_id) {
          notify.success(
            "Payment successful & your order placed successfully."
          );
        }
      },
      prefill: {
        name: "",
        email: "",
        phone_number: "",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  window.history.pushState(null, null, window.location.href);

  window.onpopstate = function (event) {
    window.history.go(1);
  };
  // }

  const handlerCancelOrders = () => {
    cancelOrders.sendRequest(
      CONSTANT.URLS.cancelOrders,
      () => {
        navigate("/home");
      },
      {
        parentId,
      },
      "Your orders cancled successfuly"
    );
  };

  return (
    <Fragment>
      {showPendingGif && <SendingOrder parentId={parentId} gif={gif} />}
      {!showPendingGif && (
        <Container
          maxWidth="sm"
          sx={{ minHeight: "100vh", background: HCTheme.whiteColorHC }}
        >
          <Box>
            <IconButton
              aria-label="back"
              sx={{ p: 0, mt: 2.5 }}
              disableRipple
            ></IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <PageHeading>Confirm order</PageHeading>
          </Box>

          <Box sx={{ my: 2.5 }}>
            <Stack spacing={2.5}>
              {orders.length !== 0 ? (
                orders.map((order) => {
                  if (order?.quantity && order?.restaurantItemId) {
                    return <CartItem order={order} />;
                  }
                  if (order?.quantity && order?.houseKeepingItemId) {
                    return (
                      <RoomServiceCartProduct key={order?.id} order={order} />
                    );
                  }
                })
              ) : (
                <Stack
                  spacing={3}
                  p={10}
                  sx={{ background: "#DEDEDE", borderRadius: 2 }}
                >
                  <PageSubHeading sx={{ textAlign: "center" }}>
                    No orders found.
                  </PageSubHeading>
                </Stack>
              )}
            </Stack>
          </Box>
          <CartSummary
            totalAmount={calculatedData.grandTotal}
            itemTotal={calculatedData.itemTotal}
          />
          {orders.filter((order) => order?.status === "Accepted").length !==
            0 && (
              <Box sx={{ my: 2.5 }}>
                <ButtonHC
                  variant="contained"
                  fullWidth
                  sx={{ textTransform: "capitalize" }}
                  disabled={confirmOrders?.isLoading}
                  onClick={() => {
                    handlerPlaceOrder();
                  }}
                >
                  Confirm Order
                </ButtonHC>
                <Button
                  sx={{
                    mt: 2,
                    textTransform: "unset",
                    color: "#FC5012",
                    fontWeight: "normal",
                  }}
                  onClick={() => {
                    setIsConfirmCancelOrderDialog(true);
                  }}
                  fullWidth
                >
                  Press here, if you want to cancel this order
                </Button>
              </Box>
            )}
          {orders.filter((order) => order?.status === "Rejected").length ===
            orders.length && (
              <Box sx={{ my: 2.5 }}>
                <ButtonHC
                  variant="contained"
                  fullWidth
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    navigate(`/${section}`);
                  }}
                >
                  Browse Menu
                </ButtonHC>
              </Box>
            )}

          <div>
            <Dialog
              open={isConfirmDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="confirm-discard-order-dialog"
            >
              <DialogTitle id="alert-dialog-title">
                Your orders will be discarded.
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your orders will be discarded.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} disableRipple>
                  Cancel
                </Button>
                <Button onClick={() => navigate("/home")} disableRipple>
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog
              open={isConfirmCancelOrderDialog}
              onClose={() => {
                setIsConfirmCancelOrderDialog(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="confirm-discard-order-dialog"
            >
              <DialogTitle id="alert-dialog-title">
                Your orders will be discarded.
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Your orders will be discarded.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setIsConfirmCancelOrderDialog(false);
                  }}
                  disableRipple
                >
                  Cancel
                </Button>
                <Button onClick={handlerCancelOrders} disableRipple>
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <React.Fragment>
              <Drawer anchor="bottom" open={false} onClose={() => { }}>
                <Box sx={{ p: 3 }}>
                  <Stack spacing={2.5} my={3}>
                    <ButtonHC
                      fullWidth
                      sx={{ textTransform: "none" }}
                      onClick={displayRazorpay}
                    >
                      Pay Online
                    </ButtonHC>
                    <ButtonHC
                      sx={{ textTransform: "none" }}
                      onClick={handlerPlaceOrder}
                    >
                      Pay With Cash
                    </ButtonHC>
                  </Stack>
                </Box>
              </Drawer>
            </React.Fragment>
          </div>
        </Container>
      )}
    </Fragment>
  );
};

export default Cart;
