import { useCallback, useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import { Services } from "../../services/service";
import CONSTANT from "../../utils/constant";
import { filterItems, filterService } from "../../utils/funcs";
import CartTotal from "../cart/CartTotal";
import CatSec from "../general/CatSec";
import DrawerComponent from "../general/DrawerComponent";
import { ChipHC } from "../styled/Chip";
import { PolicyLinkNew } from "../styled/HotelDetails";
import { PageSubHeading } from "../styled/PageSubHeading";
import image from '../../assets/svg/image 1.svg'
import RestaurantList from "./RestaurantList";
import LayoutComponent from "../general/LayoutComponent";


// const dummy = [
//   {
//     id: 1,
//     name: 'testing',
//     openTime: '10 AM',
//     closeTime: '12 PM',
//     isVeg: true,
//     image,
//     description: 'dsfjaskfjaskfjask;fasjfafja;klf Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in'
//   },
//   {
//     id: 2,
//     name: 'test',
//     openTime: '10 AM',
//     closeTime: '10 PM',
//     image,
//     isVeg: false,
//     description: 'fadjkuernkafuuoewamm,aodal;fkd Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in'
//   },
//   {
//     id: 2,
//     name: 'test',
//     openTime: '10 AM',
//     closeTime: '10 PM',
//     image,
//     isVeg: false,
//     description: 'fadjkuernkafuuoewamm,aodal;fkd Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in'
//   },
//   {
//     id: 3,
//     name: 'test',
//     openTime: '10 AM',
//     closeTime: '10 PM',
//     image,
//     isVeg: false,
//     description: 'fadjkuernkafuuoewamm,aodal;fkd Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in'
//   },
//   {
//     id: 4,
//     name: 'test',
//     openTime: '10 AM',
//     closeTime: '10 PM',
//     image,
//     isVeg: false,
//     description: 'fadjkuernkafuuoewamm,aodal;fkd Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in'
//   },
// ]


export default function NewRestaurantDrawer(props) {
  let navigate = useNavigate();
  const getAllRestaurantItems = useHttp();
  const getAllItems = useHttp();

  const [restaurantItems, setRestaurantItems] = useState({});
  const [restaurantList, setRestaurantList] = useState([])
  const [myCart, setMyCart] = useState({ cartItem: [] });
  const [calculatedCart, setCalculatedCart] = useState({
    totalItem: 0,
    totalAmount: 0,
  });
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [curCatId, setCurCatId] = useState(restaurantCategories[0]?.id);
  const [curCategory, setCurCategory] = useState({});
  const [filterString, setFilterString] = useState("");

  // console.log(restaurantList);


  const handlerFilterChange = (e) => {
    setFilterString(e.target.value);
  };

  const handlerGetAllRestaurantItems = useCallback((responseData) => {
    setRestaurantItems(responseData?.data);
  }, []);

  const restaurantItemsIncrement = (price, cart) => {
    setMyCart(cart);
    setCalculatedCart((prev) => ({
      totalItem: prev.totalItem + 1,
      totalAmount: prev.totalAmount + price,
    }));
    props.onChangeCart();
  };

  const restaurantItemsDecrement = (price, cart) => {
    setMyCart(cart);
    setCalculatedCart((prev) => ({
      totalItem: prev.totalItem - 1,
      totalAmount: prev.totalAmount - price,
    }));
    props.onChangeCart();
  };

  const handlerUpdateVisibility = (catId_, visibility_) => {
    if (restaurantCategories.length > 0) {
      const x = restaurantCategories.map((cat) => {
        if (cat?.id == catId_) {
          cat.isVisible = visibility_;
        }
        return cat;
      });
      setRestaurantCategories(x);
      const curCat = x.filter((cat) => cat?.isVisible)[0];
      if (!CONSTANT.isChangingOnClick) {
        setCurCategory(curCat);
        setCurCatId(curCat?.id);
      }
    }
  };

  const restaurantItemsArray = [];

  for (const [key, value] of Object.entries(restaurantItems)) {
    restaurantItemsArray.push({
      categoryName: restaurantCategories.filter(
        (category) => category?.id == key
      )[0]?.name,
      id: key,
      items: value,
    });
  }


  let restaurantItemsArrayIds = restaurantItemsArray.map((item) => item?.id);

  let restaurantCategoriesFiltered = restaurantCategories.filter((category) =>
    restaurantItemsArrayIds.includes("" + category?.id)
  );

  let filteredRestaurantItems = filterService(restaurantList, filterString);



  const loadAllRestaurantItems = () => {
    getAllRestaurantItems.sendRequest(
      CONSTANT.URLS.getAllRestaurantItems,
      handlerGetAllRestaurantItems,
      {}
    );
  };

  useEffect(() => {
    const getCartAndAllRestautantCategories = async (userId) => {
      const getMyCartUrl = {
        type: "GET",
        endpoint: "/carts/users?section=restaurant",
      };

      const endpoints = [
        `${CONSTANT.baseUrl}/categories?section=restaurant`,
        `${CONSTANT.baseUrl}${getMyCartUrl.endpoint}`,
      ];

      try {
        return await axios
          .all(endpoints.map((endpoint) => Services.get(endpoint)))
          .then((data) => data);
      } catch (error) {
        return error;
      }
    };

    getCartAndAllRestautantCategories().then((data) => {
      setMyCart(data[1]?.data?.data || { cartItem: [] });
      const getTotal = (total, cartItem) => {
        return total + cartItem?.quantity;
      };
      const getTotalAmount = (total, cartItem) => {
        return total + cartItem?.quantity * cartItem?.restaurantItem?.price;
      };

      setCalculatedCart((prev) => ({
        totalItem: data[1]?.data?.data?.cartItem.reduce(getTotal, 0),
        totalAmount: data[1]?.data?.data?.cartItem.reduce(getTotalAmount, 0),
      }));

      setRestaurantCategories(data[0]?.data?.data.reverse());

      if (data[0]?.data?.data?.length) {
        let cats = data[0]?.data?.data.map((cat) => {
          cat.isVisible = false;
          return cat;
        });
        setCurCategory(cats[0]);
      }
    });
  }, []);

  useEffect(() => {
    loadAllRestaurantItems();

    const GET_RESTAURANT_API = { ...CONSTANT.URLS.getRestaurant }

    getAllItems.sendRequest(GET_RESTAURANT_API, (res) => {
      setRestaurantList(res?.data?.restaurents);
    })

  }, []);

  return (
    <Box>
      <LayoutComponent
        isSearchable={true}
        handlerFilterChange={handlerFilterChange}
        filterString={filterString}
        height={true}
      >

        <Stack style={{ marginTop: '0px' }}>
          <p style={{ fontWeight: '600', fontSize: '20px' }}>
            Restaurants
          </p>
        </Stack>

        <Box className="overFlowScroll">
          {restaurantList.length !== 0 ? (
            <Stack className="overFlowScroll" >
              {filteredRestaurantItems?.map(ele => <RestaurantList key={ele?.id} {...ele} />)}

              <PolicyLinkNew style={{
                maxWidth: '170px',
                textAlign: 'center',
                margin: ' 0px auto',
                marginTop: '10px'
              }} sx={{ mt: 2.5, textAlign: "center" }}>
                <a
                  href={CONSTANT.hotelcomPolicyUrl}
                  target="_blank"
                  rel="noreferrer"

                >
                  Terms and Conditions of Hotelcom
                </a>
              </PolicyLinkNew>
            </Stack>
          ) : (
            <Stack
              spacing={3}
              mt={2.5}
              p={10}
              sx={{ background: "#EDEDED", borderRadius: 2 }}

            >
              <PageSubHeading sx={{ textAlign: "center" }}>
                No items found.
              </PageSubHeading>
            </Stack>
          )}
        </Box>

        <Stack
          spacing={3}
          mt={2.5}
          p={10}
          sx={{ background: "#EDEDED", borderRadius: 2 }}
        >
          <CircularProgress sx={{ mx: "auto" }} />
        </Stack>

      </LayoutComponent>
      {calculatedCart?.totalItem > 0 && (
        <Box my={2} maxWidth="sm">
          <CartTotal
            totalItems={calculatedCart?.totalItem}
            totalAmount={((+myCart?.itemTotal)).toFixed(2)}
            clickHandler={() => {
              navigate("/pre-cart?section=restaurant", {
                state: { title: "Order Summary" },
              });
            }}
            btnText="Next"
          />
        </Box>
      )}
    </Box>
  );
}
