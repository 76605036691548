import { Stack } from "@mui/system";
import Guide from "../general/Guide";

const Guides = ({ guides }) => {
  return (
    <Stack spacing={3}>
      {guides?.map((guide) => (
        <Guide key={guide?.id} guide={guide} />
      ))}
    </Stack>
  );
};

export default Guides;
