import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewRestaurantDrawer from "../components/Restaurant/NewRestaurantDrawer";
import RestaurantHeader from "../components/Restaurant/RestaurantHeader";
import useHttp from "../Hooks/use-http";
import CONSTANT from "../utils/constant";
import { HCTheme } from "../utils/theme";
import RestaurantItem from "../components/Restaurant/RestaurantItem";

const Restaurant = ({ roomNum }) => {
  const getBadgeData = useHttp();
  const [badgeData, setBadgeData] = useState({});

  const navigate = useNavigate();

  const handlerGetBadgeData = useCallback((responseData) => {
    setBadgeData(responseData?.data?.track);
  }, []);

  const loadBadgeData = () => {
    getBadgeData.sendRequest(
      CONSTANT.URLS.getBadgeData,
      handlerGetBadgeData,
      {}
    );
  };

  const handlerChangeCart = () => {
    loadBadgeData();
  };

  useEffect(() => {
    loadBadgeData();
  }, []);

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          minHeight: "100vh",
          background: HCTheme.blackColorHC,
          display: "flex",
          flexDirection: "column",
          p: 0,
        }}
        id="cntr"
      >
        <Box>
          <RestaurantHeader
            title={
              <>
                Food and beverages
              </>
            }
            roomNum={roomNum}
            navigatePath="/home"
            onClickCartIcon={() => {
              navigate("/pre-cart?section=restaurant", {
                state: { title: "My Cart" },
              });
            }}
            // onClickoOrderHistoryIcon={() => {
            //   navigate(`/order-history?section=restaurant`);
            // }}

            badgeData={badgeData}
            showIcons
          />
        </Box>

        <NewRestaurantDrawer onChangeCart={handlerChangeCart} />
      </Container>


    </>
  );
};

export default Restaurant;
