import { List, ListItem } from "@mui/material";
import { CartSummaryListItemText } from "../styled/Cart";
import { ProductPrice } from "../styled/ProductBox";

const CartSummary = ({ totalAmount, itemTotal }) => {
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  return (
    <List sx={{ width: "100%" }}>
      <ListItem>
        <CartSummaryListItemText id="switch-list-label-wifi">
          Total Amount

        </CartSummaryListItemText>

        <ProductPrice color="#32842B" sx={{ ml: "auto" }}>
          {currencySymbol} {itemTotal}
        </ProductPrice>
      </ListItem>
      <p style={{ fontSize: '12px', marginTop: '-10px', marginLeft: '17px' }}>
        *Inclusive all taxes

      </p>
    </List>
  );
};

export default CartSummary;
