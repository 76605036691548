import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import * as React from "react";
import { formatAMPM, getDateTime } from "../../utils/funcs";
import {
  TrackOrderTimelinePrimaryText,
  TrackOrderTimelineSecondaryText,
} from "../styled/TrackOrder";
import deliveringIcon from "./../../assets/svg/delivering.svg";
import orderAcceptedIcon from "./../../assets/svg/order-accepted-icon.svg";
import orderPlacedIcon from "./../../assets/svg/order-placed-icon.svg";
import restaurantDeliveringIcon from "./../../assets/svg/placed.svg";
import roomPlacedIcon from '../../assets/svg/placed.svg'

const getIcon = {
  restaurant: restaurantDeliveringIcon,
  houseKeeping: deliveringIcon,
};
const offSet = localStorage.getItem("timezone");

const TrackOrderTimeline = ({ order, itemType, status }) => {
  const isAccepted =
    order?.createdAt !== "00:00:00" && order?.createdAt != null;
  const isConfirmed =
    order?.confirmedAt !== "00:00:00" && order?.confirmedAt != null;
  const isCompleted =
    order?.completedAt !== "00:00:00" && order?.completedAt != null;

  const acceptedOrder = order?.find(ele => {
    if (ele?.status === 'Accepted' || ele?.status === 'Completed') {
      return ele;
    }
    return;
  })
  // const compltedOrder = order?.find(ele => {
  //   if (ele?.status === 'Completed') {
  //     return ele;
  //   }
  //   return;
  // })

  // console.log(acceptedOrder);

  return (
    <Timeline
      sx={{
        pl: 0.5,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <img className="timeline-icon" src={orderPlacedIcon} alt="" />
          {(status === 'Accepted' || status === 'Completed') && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <TrackOrderTimelinePrimaryText variant="h6" style={{ color: (status === 'Accepted' || status === 'Completed') ? '#32842B' : '#FC5012' }} component="span" >
            Order {status === 'Accepted' ? 'Placed' : status}
          </TrackOrderTimelinePrimaryText>
          <TrackOrderTimelineSecondaryText>
            {getDateTime(order[0]?.createdAt, offSet, "hh:mm A")}
          </TrackOrderTimelineSecondaryText>
        </TimelineContent>
      </TimelineItem>
      {(status === 'Accepted' || status === 'Completed') && (
        <TimelineItem>
          <TimelineSeparator>
            <img className="timeline-icon" src={orderAcceptedIcon} alt="" />
            {(status === 'Accepted' || status === 'Completed') && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <TrackOrderTimelinePrimaryText variant="h6" component="span">
              Order Confirmed
            </TrackOrderTimelinePrimaryText>
            <TrackOrderTimelineSecondaryText>
              {acceptedOrder?.acceptedAt
                ? getDateTime(acceptedOrder?.acceptedAt, offSet, "hh:mm A") : "--:-- --"}
            </TrackOrderTimelineSecondaryText>
          </TimelineContent>
        </TimelineItem>
      )}
      {(status === 'Accepted' || status === 'Completed') && (
        <TimelineItem>
          <TimelineSeparator>
            <img className="timeline-icon" src={roomPlacedIcon} alt="" />
            {isCompleted && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <TrackOrderTimelinePrimaryText variant="h6" component="span">
              Delivering to your room
            </TrackOrderTimelinePrimaryText>
            <TrackOrderTimelineSecondaryText>
              {acceptedOrder?.completedAt
                ? getDateTime(acceptedOrder?.completedAt, offSet, "hh:mm A")
                : "--:-- --"}
            </TrackOrderTimelineSecondaryText>
          </TimelineContent>
        </TimelineItem>
      )}
      {isCompleted && (
        <TimelineItem>
          <TimelineSeparator>
            <ShoppingBagIcon sx={{ my: 1.5, fontSize: 24 }} />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <TrackOrderTimelinePrimaryText variant="h6" component="span">
              Order Completed
            </TrackOrderTimelinePrimaryText>
            <TrackOrderTimelineSecondaryText>
              {getDateTime(order?.completedAt, offSet, "hh:mm A")}
            </TrackOrderTimelineSecondaryText>
          </TimelineContent>
        </TimelineItem>
      )}
    </Timeline>
  );
};

export default TrackOrderTimeline;
