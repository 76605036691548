import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const CallBoxPaper = styled(Paper)(({ theme }) => ({
  background: "#EDEDED",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
}));

export const CallBoxRoleText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#0C0F0A",
}));
export const CallBoxRoleName = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-end",
  color: "#AEAFAF",
}));
