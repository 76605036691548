import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import NewCallServicesDrawer from "../components/CallServices/NewCallServicesDrawer";
import RestaurantHeader from "../components/Restaurant/RestaurantHeader";
import { HCTheme } from "../utils/theme";

const CallServices = ({ roomNum }) => {
  const [open, setOpen] = useState(true);

  const handlerOpenDrawer = () => {
    setOpen(true);
  };
  const handlerCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.blackColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
      id="cntr"
    >
      <Box>
        <RestaurantHeader
          roomNum={roomNum}
          title={'Help Desk'}
          open={open}
          navigatePath="/home"

          showText={<>
            Call us for <br />
            anything!
          </>}
        />
      </Box>
      <NewCallServicesDrawer
        onOpenDrawer={handlerOpenDrawer}
        onCloseDrawer={handlerCloseDrawer}
        open={open}
      />
    </Container>
  );
};

export default CallServices;
