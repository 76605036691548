import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { HCTheme } from "../../utils/theme";

export const PageSubHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  color: "#0C0F0A",
}));

export const WelcomeHeading = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "italic",
  fontWeight: 700,
  fontSize: "25px",
  lineHeight: "34px",
  textAlign: "center",
  color: HCTheme.blackColorHC,
  "& span": {
    fontFamily: "'Lobster', cursive",
    fontWeight: 400,
    fontSize: "35px",
    lineHeight: "73.75px",
  },
}));
export const ButtonQR = styled(Button)(({ Button }) => ({
  background: "#272125",
  boxShadow: "0px 5px 18px rgba(46, 46, 46, 0.15)",
  borderRadius: "116px",
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "22px",
  lineHeight: "30px",
  color: "#FFFFFF",
  padding: "18px 36px",
  "&:hover": {
    boxShadow: "none",
    background: "#272125",
  },
  "&:active": {
    boxShadow: "none",
    background: "#272125",
  },
  "&:focus": {
    boxShadow: "none",
    background: "#272125",
  },
}));
