import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { IconButton } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { Fragment, useState } from "react";
import QrReader from "react-qr-reader";
import { HCTheme } from "../../utils/theme";
import { PageSubHeading } from "../styled/PageSubHeading";

const SignInWithQRCode = () => {
  const [isFronCam, setIsFronCam] = useState(false);

  const handleErrorWebCam = (error) => {};

  const handleScanWebCam = (result) => {
    if (result) {
      console.log(result);
      window.location.assign(result);
    }
  };

  return (
    <Fragment>
      <Container
        maxWidth="sm"
        disableGutters={true}
        sx={{
          position: "relative",
          minHeight: window.innerHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "strach",
          overflow: "hidden",
          bgcolor: HCTheme.whiteColorHC,
        }}
      >
        <Stack
          sx={{
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box my={1} display="flex" justifyContent="flex-end" width="80%">
            <IconButton
              color="primary"
              aria-label="add to shopping cart"
              onClick={() => {
                setIsFronCam((prev) => !prev);
              }}
            >
              <CameraswitchIcon sx={{ fontSize: "32px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <QrReader
              facingMode={isFronCam ? "user" : "environment"}
              className="qr-code-scanner"
              delay={300}
              onError={handleErrorWebCam}
              onScan={handleScanWebCam}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              my: 8,
            }}
          >
            <PageSubHeading>Scan QR to Check In</PageSubHeading>
          </Box>
        </Stack>
      </Container>
    </Fragment>
  );
};

export default SignInWithQRCode;
