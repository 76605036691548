import { Stack } from "@mui/system";
import RoomServiceProduct from "../general/RoomServiceProduct";

const RoomServiceProducts = ({
  items,
  onIncrement,
  onDecrement,
  onAddToCart,
  myCart,
  onChangeCart,
}) => {
  return (
    <Stack spacing={3}>
      {items.map((item) => (
        <RoomServiceProduct
          key={item?.id}
          item={item}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onAddToCart={onAddToCart}
          myCart={myCart}
          onChangeCart={onChangeCart}
        />
      ))}
    </Stack>
  );
};

export default RoomServiceProducts;
