import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const TrackOrderHeader = styled(Box)(({ theme, status }) => ({
  border: "1px solid red",
  height: "100%",
}));

export const TrackOrderHeaderID = styled(Typography)(({ theme, status }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "16px",
  display: "flex",
  alignItems: "flex-end",
  textAlign: "center",
  color: "#0C0F0A",
  "& span": {
    fontWeight: 900,
  },
}));

export const TrackOrderConfirmedText = styled(Typography)(
  ({ theme, status }) => ({
    fontFamily: "Satoshi-Variable",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "34px",
    textAlign: "center",
    color: "#0C0F0A",
  })
);
export const TrackOrderItemPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: "1px solid #D0D0D0",
  padding: theme.spacing(1.5),
  background: "#fff",
  minWidth: "100% !important",
  width: "100%",
}));

export const ProductPrice = styled(Typography)(({ theme, color }) => ({
  fontFamily: "Satoshi-Variable",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22px",
  color: color || theme.palette.secondary.main,
}));

export const TrackOrderItemTime = styled(Box)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#FC5012",
}));

export const TrackOrderSecondaryText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "11px",
  display: "flex",
  alignItems: "flex-end",
  color: "#AEAFAF",
}));

export const TrackOrderTimelinePrimaryText = styled(Typography)(
  ({ theme }) => ({
    fontFamily: "Satoshi-Variable",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "flex-end",
    textAlign: "center",
    color: "#000000",
  })
);
export const TrackOrderTimelineSecondaryText = styled(Typography)(
  ({ theme }) => ({
    fontFamily: "Satoshi-Variable",
    fontWeight: 500,
    fontSize: "8px",
    lineHeight: "11px",
    display: "flex",
    alignItems: "flex-end",
    textAlign: "center",
    color: "#AEAFAF",
  })
);
