import { Avatar, CircularProgress, Stack, fabClasses } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import { Services } from "../../services/service";
import CONSTANT from "../../utils/constant";
import { filterItems } from "../../utils/funcs";
import CartTotal from "../cart/CartTotal";
import CatSec from "../general/CatSec";
import DrawerComponent from "../general/DrawerComponent";
import { ChipHC } from "../styled/Chip";
import { PolicyLinkNew } from "../styled/HotelDetails";
import { PageSubHeading } from "../styled/PageSubHeading";
import LayoutComponent from "../general/LayoutComponent";

export default function RestaurantItemDrawer(props) {
    let navigate = useNavigate();
    const getAllRestaurantItems = useHttp();
    const getAllItems = useHttp();

    const [restaurantItems, setRestaurantItems] = useState([]);
    const [myCart, setMyCart] = useState({ cartItem: [] });
    const [calculatedCart, setCalculatedCart] = useState({
        totalItem: 0,
        totalAmount: 0,
    });
    const [restaurantCategories, setRestaurantCategories] = useState([]);
    const [curCatId, setCurCatId] = useState(restaurantCategories[0]?.id);
    const [curCategory, setCurCategory] = useState({});
    const [filterString, setFilterString] = useState("");
    const { id } = useParams();

    const handlerFilterChange = (e) => {
        setFilterString(e.target.value);
    };


    const handlerGetAllRestaurantItems = useCallback((responseData) => {
        // console.log(responseData);
        setRestaurantItems(responseData?.data);
    }, []);

    const restaurantItemsIncrement = (price, cart) => {
        setMyCart(cart);
        setCalculatedCart((prev) => ({
            totalItem: prev.totalItem + 1,
            totalAmount: prev.totalAmount + price,
        }));
        props.onChangeCart();
    };

    // console.log(myCart);
    // console.log(calculatedCart);
    const restaurantItemsDecrement = (price, cart) => {
        setMyCart(cart);
        setCalculatedCart((prev) => ({
            totalItem: prev.totalItem - 1,
            totalAmount: prev.totalAmount - price,
        }));
        props.onChangeCart();
    };

    const handlerUpdateVisibility = (catId_, visibility_) => {
        if (restaurantCategories.length > 0) {
            const x = restaurantCategories.map((cat) => {
                if (cat?.id == catId_) {
                    cat.isVisible = visibility_;
                }
                return cat;
            });
            setRestaurantCategories(x);
            const curCat = x.filter((cat) => cat?.isVisible)[0];
            if (!CONSTANT.isChangingOnClick) {
                setCurCategory(curCat);
                setCurCatId(curCat?.id);
            }
        }
    };

    const restaurantItemsArray = [];
    let restaurantObj = {}


    restaurantItems?.map(ele => {


        if (ele?.categoryId in restaurantObj) {
            restaurantObj[ele?.categoryId]?.items?.push(ele)
        }
        else {
            restaurantObj = {
                ...restaurantObj, [ele?.categoryId]: {
                    items: [ele]
                }
            }
        }
    })

    for (const [key, value] of Object.entries(restaurantObj)) {

        if (restaurantCategories.filter((category) => category?.id === +key)[0]?.name) {
            restaurantItemsArray.push({
                categoryName: restaurantCategories.filter(
                    (category) => category?.id === +key
                )[0]?.name,
                id: key,
                items: value?.items,
            });
        }
    }
    // restaurantItems?.map(ele => console.log(ele))



    let restaurantItemsArrayIds = restaurantItemsArray.map((item) => item?.id);

    let restaurantCategoriesFiltered = restaurantCategories.filter((category) =>
        restaurantItemsArrayIds.includes("" + category?.id)
    );

    let filteredRestaurantItems = filterItems(restaurantItemsArray, filterString);
    // let filteredRestaurantItems = [];

    // console.log(restaurantItems);
    // console.log(restaurantItemsArray, 'resitem');
    // console.log(obj, 'adfa');
    // console.log(filteredRestaurantItems, 'filter');
    // filteredRestaurantItems?.map(cat => {
    //     console.log(cat);
    // })


    const loadAllRestaurantItems = () => {
        const RESTAURANT_ITEMS_API = { ...CONSTANT.URLS.getAllRestaurantItems }
        RESTAURANT_ITEMS_API.endpoint = `${RESTAURANT_ITEMS_API.endpoint}?restaurentId=${id}`
        getAllRestaurantItems.sendRequest(
            RESTAURANT_ITEMS_API,
            handlerGetAllRestaurantItems,
            {}
        );
    };

    useEffect(() => {
        const getCartAndAllRestautantCategories = async (userId) => {
            const getMyCartUrl = {
                type: "GET",
                endpoint: "/carts/users?section=restaurant",
            };

            const endpoints = [
                `${CONSTANT.baseUrl}/categories?section=restaurant&restaurentId=${id}`,
                `${CONSTANT.baseUrl}${getMyCartUrl.endpoint}`,
            ];

            try {
                return await axios
                    .all(endpoints.map((endpoint) => Services.get(endpoint)))
                    .then((data) => data);
            } catch (error) {
                return error;
            }
        };

        getCartAndAllRestautantCategories().then((data) => {
            setMyCart(data[1]?.data?.data || { cartItem: [] });
            const getTotal = (total, cartItem) => {
                return total + cartItem?.quantity;
            };
            const getTotalAmount = (total, cartItem) => {
                return total + cartItem?.quantity * cartItem?.restaurantItem?.price;
            };

            setCalculatedCart((prev) => ({
                totalItem: data[1]?.data?.data?.cartItem.reduce(getTotal, 0),
                totalAmount: data[1]?.data?.data?.cartItem.reduce(getTotalAmount, 0),
            }));

            setRestaurantCategories(data[0]?.data?.data.reverse());

            if (data[0]?.data?.data?.length) {
                let cats = data[0]?.data?.data.map((cat) => {
                    cat.isVisible = false;
                    return cat;
                });
                setCurCategory(cats[0]);
            }
        });
    }, []);

    useEffect(() => {
        loadAllRestaurantItems();
    }, []);

    return (
        <Box>
            <LayoutComponent
                isSearchable={true}
                handlerFilterChange={handlerFilterChange}
                filterString={filterString}
                height={filteredRestaurantItems.length !== 0 ? true : false}
            >
                <Stack
                    direction="row"
                    spacing={1.25}
                    sx={{ overflow: "scroll", mt: 0.5, mb: 0.2 }}

                >
                    {restaurantCategories.length ? (
                        restaurantCategories.map((category) => (
                            <>
                                <a
                                    href={`#${category?.id}`}
                                    key={category?.id}
                                    className="category-link"
                                    style={{ maxWidth: '90px' }}
                                >
                                    <ChipHC

                                        style={{
                                            width: '90px',
                                            height: '90px',



                                        }}
                                        avatar={<Avatar variant="" alt="Natacha" style={{
                                            width: '70px',
                                            height: '70px',
                                            marginRight: '-19px'
                                        }} src={category?.image} />}
                                        color="primary"
                                        variant={
                                            category?.id == curCatId ||
                                                category?.id == curCategory?.id
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={() => {
                                            CONSTANT.isChangingOnClick = true;
                                            setTimeout(() => {
                                                CONSTANT.isChangingOnClick = false;
                                            }, 600);
                                            setCurCategory(category);
                                            setCurCatId(category?.id);
                                        }}
                                    />
                                    <p style={{ fontSize: '14px', fontWeight: 700, textAlign: 'center' }}>{category?.name}</p>
                                </a>
                            </>
                        ))
                    ) : (
                        <PageSubHeading >No categories found.</PageSubHeading>
                    )}
                </Stack>
                {!getAllItems.isLoading ? (
                    <Box className="overFlowScroll">
                        {filteredRestaurantItems.length !== 0 ? (
                            <Stack className="overFlowScroll">
                                {filteredRestaurantItems.map((category) => (
                                    <CatSec
                                        key={category?.id}
                                        category={category}
                                        onIncrement={restaurantItemsIncrement}
                                        onDecrement={restaurantItemsDecrement}
                                        onUpdateVisibility={handlerUpdateVisibility}
                                        myCart={myCart}
                                    ></CatSec>
                                ))}
                                <PolicyLinkNew sx={{ mt: 4, textAlign: "center" }} style={{
                                    maxWidth: '170px',
                                    textAlign: 'center',
                                    margin: ' 0px auto',
                                    marginTop: '10px'
                                }}>
                                    <a
                                        href={CONSTANT.hotelcomPolicyUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms and Conditions of Hotelcom
                                    </a>
                                </PolicyLinkNew>
                            </Stack>
                        ) : (
                            <Stack
                                spacing={3}
                                mt={2.5}
                                p={10}
                                sx={{ background: "#EDEDED", borderRadius: 2 }}


                            >
                                <PageSubHeading sx={{ textAlign: "center" }}>
                                    No items found.
                                </PageSubHeading>
                            </Stack>
                        )}
                    </Box>
                ) : (
                    <Stack
                        spacing={3}
                        mt={2.5}
                        p={10}
                        sx={{ background: "#EDEDED", borderRadius: 2 }}

                    >
                        <CircularProgress sx={{ mx: "auto" }} />
                    </Stack>
                )}
            </LayoutComponent>
            {calculatedCart?.totalItem > 0 && (
                <Box my={2} maxWidth="sm">
                    <CartTotal
                        totalItems={calculatedCart?.totalItem}
                        totalAmount={((myCart?.itemTotal)).toFixed(2)}
                        clickHandler={() => {
                            navigate("/pre-cart?section=restaurant", {
                                state: { title: "Order Summary" },
                            });
                        }}
                        btnText="Next"
                    />
                </Box>
            )}
        </Box>
    );
}
