import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { Avatar, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { ButtonHC } from "../styled/Button";
import {
  CallBoxPaper,
  CallBoxRoleName,
  CallBoxRoleText,
} from "../styled/call-services";
import person from '../../assets/svg/person.svg'

const CallBox = ({ name, role, ContactNumber }) => {
  return (
    <CallBoxPaper sx={{ py: 2, px: 2 }}>
      <Stack direction="row" justifyContent="space-between">

        <Stack direction="row" sx={{ mt: 0.5 }}>
          <div>
            <img style={{
              width: '50px',
              height: '50px'
            }} src={person} alt="avatar" />
          </div>
          <Box sx={{ ml: 1, mt: 0.5 }}>
            <CallBoxRoleText>{role}</CallBoxRoleText>
            <CallBoxRoleName sx={{ mt: 0 }}>{name}</CallBoxRoleName>
          </Box>
        </Stack>
        <Box sx={{ mt: 1.25 }}>
          <a href={`tel:+${ContactNumber}`}>
            <ButtonHC
              variant="contained"
              startIcon={<LocalPhoneOutlinedIcon sx={{ mr: 1 }} />}
              sx={{ textTransform: "capitalize", py: 1 }}
            >
              Call
            </ButtonHC>
          </a>
        </Box>
      </Stack>
    </CallBoxPaper>
  );
};

export default CallBox;
