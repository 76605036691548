import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "../services/service";
import { notify } from "../utils/funcs";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const sendRequest = useCallback(
    async (
      url,
      responseHandler,
      payload,
      successMessage,
      showErrorToast = true
    ) => {
      setIsLoading(true);
      try {
        let response;
        switch (url.type) {
          case "POST":
            response = await Services.post(url.endpoint, payload);
            break;

          case "GET":
            response = await Services.get(url.endpoint);
            break;

          case "PUT":
            response = await Services.put(url.endpoint, payload);

            break;
          case "DELETE":
            response = await Services.delete(url.endpoint);
            break;

          case "PATCH":
            response = await Services.patch(url.endpoint, payload);
            break;
        }

        const data = await response.data;

        if (successMessage) {
          notify.success(successMessage);
        }
        try {
          if (responseHandler) responseHandler(data);
        } catch (error) {}
      } catch (err) {
        if (
          err?.response?.data?.status === 500 ||
          err?.response?.data?.status === 401
        ) {
          if (showErrorToast) {
            notify.error("Something went wrong!");
          }
          localStorage.removeItem("authToken");
          navigate("/auth-fallback");
        } else {
          notify.error(err?.response?.data?.message);
        }
      }
      setIsLoading(false);
    },
    []
  );

  return {
    isLoading,
    sendRequest,
  };
};

export default useHttp;
