import { Stack } from "@mui/system";
import Product from "../general/Product";

const Products = ({
  items,
  onIncrement,
  onDecrement,
  onAddToCart,
  myCart,
  onChangeCart,
}) => {
  return (
    <Stack spacing={2}>
      {items.map((item) => (
        <Product
          key={item?.id}
          item={item}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onAddToCart={onAddToCart}
          myCart={myCart}
          onChangeCart={onChangeCart}
        />
      ))}
    </Stack>
  );
};

export default Products;
