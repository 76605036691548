import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import { Services } from "../../services/service";
import CONSTANT from "../../utils/constant";
import { filterItems, filterService } from "../../utils/funcs";
import CartTotal from "../cart/CartTotal";
import CatSecRestaurant from "../general/CatSecRestaurant";
import DrawerComponent from "../general/DrawerComponent";
import { ChipHC } from "../styled/Chip";
import { PolicyLinkNew } from "../styled/HotelDetails";
import { PageSubHeading } from "../styled/PageSubHeading";
import ServiceProviderList from "./ServiceProviderList";
import LayoutComponent from "../general/LayoutComponent";

function NewHousekeepingDrawer(props) {
  let navigate = useNavigate();
  const getAllHousekeepingItems = useHttp();
  const [providerList, setProviderList] = useState([])
  const [housekeepingCategories, setHousekeepingCategories] = useState([]);
  const [housekeepingItems, setHousekeepingItems] = useState({});
  const [myCart, setMyCart] = useState({ cartItem: [] });
  const [calculatedCart, setCalculatedCart] = useState({
    totalItem: 0,
    totalAmount: 0,
  });
  const [curCategory, setCurCategory] = useState({});
  const [curCatId, setCurCatId] = useState(housekeepingCategories[0]?.id);
  const [filterString, setFilterString] = useState("");
  const handlerFilterChange = (e) => {
    setFilterString(e.target.value);
  };

  const housekeepingItemsIncrement = (price, cart) => {
    setMyCart(cart);
    setCalculatedCart((prev) => ({
      totalItem: prev.totalItem + 1,
      totalAmount: prev.totalAmount + price,
    }));
    props.onChangeCart();
  };

  const housekeepingItemsDecrement = (price, cart) => {
    setMyCart(cart);
    setCalculatedCart((prev) => ({
      totalItem: prev.totalItem - 1,
      totalAmount: prev.totalAmount - price,
    }));
    props.onChangeCart();
  };

  const handlerUpdateVisibility = (catId_, visibility_) => {
    if (housekeepingCategories.length > 0) {
      const x = housekeepingCategories.map((cat) => {
        if (cat?.id == catId_) {
          cat.isVisible = visibility_;
        }
        return cat;
      });

      setHousekeepingCategories(x);

      const curCat = x.filter((cat) => cat?.isVisible == true)[0];

      if (!CONSTANT.isChangingOnClick) {
        setCurCategory(curCat);
        setCurCatId(curCat?.id);
      }
    }
  };

  const handlerGetAllHousekeepingItems = useCallback((responseData) => {
    setHousekeepingItems(responseData?.data);
  }, []);

  const loadAllHousekeepingItems = () => {
    getAllHousekeepingItems.sendRequest(
      CONSTANT.URLS.getAllHousekeepingItems,
      handlerGetAllHousekeepingItems,
      {}
    );
  };

  const housekeepingItemsArray = [];

  for (const [key, value] of Object.entries(housekeepingItems)) {
    housekeepingItemsArray.push({
      categoryName: housekeepingCategories.filter(
        (category) => category?.id == key
      )[0]?.name,
      id: key,
      items: value,
    });
  }

  let housekeepingItemsArrayIds = housekeepingItemsArray.map(
    (item) => item?.id
  );

  let housekeepingCategoriesFiltered = housekeepingCategories.filter(
    (category) => housekeepingItemsArrayIds.includes("" + category?.id)
  );

  let filteredHousekeepingItems = filterService(
    providerList,
    filterString
  );
  // console.log(filteredHousekeepingItems);

  useEffect(() => {
    const getCartAndAllHousekeepingCategories = async (userId) => {
      const getMyCartUrl = {
        type: "GET",
        endpoint: "/carts/users?section=houseKeeping",
      };

      const endpoints = [
        `${CONSTANT.baseUrl}/categories?section=houseKeeping`,
        `${CONSTANT.baseUrl}${getMyCartUrl.endpoint}`,
      ];

      try {
        return await axios
          .all(endpoints.map((endpoint) => Services.get(endpoint)))
          .then((data) => data);
      } catch (error) {
        return error;
      }
    };

    getCartAndAllHousekeepingCategories().then((data) => {
      setMyCart(data[1]?.data?.data || { cartItem: [] });
      const getTotal = (total, cartItem) => {
        return total + cartItem?.quantity;
      };
      const getTotalAmount = (total, cartItem) => {
        return total + cartItem?.quantity * cartItem?.houseKeepingItem?.price;
      };

      setCalculatedCart((prev) => ({
        totalItem: data[1]?.data?.data?.cartItem.reduce(getTotal, 0),
        totalAmount: data[1]?.data?.data?.cartItem.reduce(getTotalAmount, 0),
      }));

      setHousekeepingCategories(data[0]?.data?.data.reverse());
      if (data[0]?.data?.data?.length) {
        let cats = data[0]?.data?.data.map((cat) => {
          cat.isVisible = false;
          return cat;
        });
        setCurCategory(cats[0]);
      }
    });
  }, []);

  useEffect(() => {
    loadAllHousekeepingItems();

    const GET_SERVICE_PROVIDER_API = { ...CONSTANT.URLS.getServiceProvider }

    getAllHousekeepingItems.sendRequest(GET_SERVICE_PROVIDER_API, (res) => {
      setProviderList(res?.data?.serviceProviders)
    })

  }, []);

  return (
    <Box>
      <LayoutComponent
        isSearchable={true}
        handlerFilterChange={handlerFilterChange}
        filterString={filterString}
        height={true}
      >
        <Stack style={{ marginTop: '0px' }}>
          <p style={{ fontWeight: '600', fontSize: '20px' }}>
            Service Provider
          </p>
        </Stack>
        {providerList.length !== 0 ? (
          <Stack className="overFlowScroll">
            {filteredHousekeepingItems?.map(ele => <ServiceProviderList key={ele?.id} {...ele} />)}
            <PolicyLinkNew sx={{ mt: 2.5, textAlign: "center" }} style={{
              maxWidth: '170px',
              textAlign: 'center',
              margin: ' 0px auto',
              marginTop: '10px'
            }}>
              <a
                href={CONSTANT.hotelcomPolicyUrl}
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions of Hotelcom
              </a>
            </PolicyLinkNew>
          </Stack>
        ) : (
          <Stack
            spacing={3}
            mt={2.5}
            p={10}
            sx={{ background: "#EDEDED", borderRadius: 2 }}
          >
            <PageSubHeading sx={{ textAlign: "center" }}>
              No items found.
            </PageSubHeading>
          </Stack>
        )}
      </LayoutComponent>

      {calculatedCart?.totalItem > 0 && (
        <Box my={2} maxWidth="sm">
          <CartTotal
            totalItems={calculatedCart?.totalItem}
            totalAmount={((myCart?.itemTotal)).toFixed(2)}
            clickHandler={() => {
              navigate("/pre-cart?section=houseKeeping", {
                state: { title: "Order Summary" },
              });
            }}
            btnText="Next"
          />
        </Box>
      )}
    </Box>
  );
}

export default NewHousekeepingDrawer;
