import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RestaurantHeader from "./RestaurantHeader";
import useHttp from "../../Hooks/use-http";
import CONSTANT from "../../utils/constant";
import { HCTheme } from "../../utils/theme";
import RestaurantItem from "./RestaurantItem";
import RestaurantItemDrawer from "./RestaurantItemDrawer";

const RestaurantDetails = ({ roomNum }) => {
    const getBadgeData = useHttp();
    const [badgeData, setBadgeData] = useState({});
    const [restaurantDetails, setRestaurantDetails] = useState({})
    const { id } = useParams()

    const navigate = useNavigate();

    const handlerGetBadgeData = useCallback((responseData) => {
        setBadgeData(responseData?.data?.track);
    }, []);

    // console.log(restaurantDetails[0]?.name);

    const loadBadgeData = () => {
        getBadgeData.sendRequest(
            CONSTANT.URLS.getBadgeData,
            handlerGetBadgeData,
            {}
        );
    };

    const handlerChangeCart = () => {
        loadBadgeData();
    };


    useEffect(() => {
        loadBadgeData();

        const GET_RESTAURANT_API = { ...CONSTANT.URLS.getRestaurant }

        getBadgeData.sendRequest(GET_RESTAURANT_API, (res) => {
            //   setRestaurantList(res?.data?.restaurents);
            setRestaurantDetails(res?.data?.restaurents?.filter(ele => ele?.id === +id));
        })

    }, []);

    return (
        <>
            <Container
                maxWidth="sm"
                sx={{
                    minHeight: "100vh",
                    background: HCTheme.blackColorHC,
                    display: "flex",
                    flexDirection: "column",
                    p: 0,
                }}
                id="cntr"
            >
                <Box>
                    <RestaurantHeader
                        title={restaurantDetails[0]?.name}

                        roomNum={roomNum}
                        navigatePath="/restaurant"
                        onClickCartIcon={() => {
                            navigate("/pre-cart?section=restaurant", {
                                state: { title: "My Cart" },
                            });
                        }}
                        // onClickoOrderHistoryIcon={() => {
                        //     navigate(`/order-history?section=restaurant`);
                        // }}
                        badgeData={badgeData}
                        showIcons
                    />
                </Box>

                {/* <NewRestaurantDrawer onChangeCart={handlerChangeCart} /> */}
                <RestaurantItemDrawer onChangeCart={handlerChangeCart} />
            </Container>


        </>
    );
};

export default RestaurantDetails;
