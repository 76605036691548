import { Badge, Box, Stack } from "@mui/material";
import { Container } from "@mui/system";
import GradingIcon from "@mui/icons-material/Grading";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoImg from "../assets/images/logo.png";
import { RoomNumText, TrackYourOrderLink } from "../components/styled/Restaurant";
import useHttp from "../Hooks/use-http";
import CONSTANT from "../utils/constant";
import { HCTheme } from "../utils/theme";
import callServicesIcon from "./../assets/svg/call-services-icon.svg";
import deviceGuideIcon from "./../assets/svg/dg-icon.svg";
import hotelDetailsIcon from "./../assets/svg/hotel-details-icon.svg";
import housekeppingIcon from "./../assets/svg/housekepping-icon.svg";
import restauuranrIcon from "./../assets/svg/restauuranr-icon.svg";
import { LightTooltip } from "../components/styled/Tooltip";
let startAngle = 0,
  lastreading = 0,
  center = { x: 0, y: 0 },
  R2D = 180 / Math.PI;

const Home = ({ roomNum }) => {
  const getBadgeData = useHttp();
  const [curMenu, setCurMenu] = useState(0);
  const [badgeData, setBadgeData] = useState({});

  // console.log(badgeData);

  const logo = localStorage.getItem('logo');
  const plan = localStorage.getItem('plan');


  const menuList = [
    "Hotel Details",
    "Food & Beverages",
    "Services",
    "Videos",
    "Help Desk",
  ];

  const navigate = useNavigate();

  const handlerMenuButtonClick = (curMenu_, path_) => {
    if (curMenu !== curMenu_) {
      setCurMenu(curMenu_);
    } else {
      navigate(path_);
    }
  };

  const handlerDiskMove = (e) => {
    let x, y, d, rotation;
    x = e.clientX - center.x || e.touches[0].clientX - center.x;
    y = e.clientY - center.y || e.touches[0].clientY - center.y;

    d = R2D * Math.atan2(y, x);
    rotation = d - startAngle;

    if (+lastreading === 0) {
      lastreading = rotation;
    }

    let diff = lastreading - rotation;

    if (diff < 0) {
      diff = diff * -1;
    }
    if (diff > 4) {
      if (lastreading < rotation) {
        setCurMenu((previous) =>
          previous < menuList.length - 1 ? previous + 1 : previous + 0
        );
      } else {
        setCurMenu((previous) => (previous > 0 ? previous - 1 : previous - 0));
      }
      lastreading = rotation;
    }
  };

  const handlerGetBadgeData = useCallback((responseData) => {
    setBadgeData(responseData?.data?.track);
  }, []);

  const loadBadgeData = () => {
    getBadgeData.sendRequest(
      CONSTANT.URLS.getBadgeData,
      handlerGetBadgeData,
      {}
    );
  };

  useEffect(() => {
    loadBadgeData();
  }, []);

  return (
    <Container
      maxWidth="sm"
      disableGutters={true}
      sx={{
        position: "relative",
        minHeight: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "hidden",
        touchAction: "none",
      }}
    >

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <RoomNumText sx={{ mt: 2, ml: 2, color: HCTheme.blackColorHC }}>
          {roomNum}
        </RoomNumText>
        {+plan !== 2 && <div>
          <Stack direction="row">
            <RoomNumText sx={{ mt: 2.5, mr: 0, color: HCTheme.blackColorHC }}>
              Orders
            </RoomNumText>
            <RoomNumText sx={{ mt: 2, mr: 2, color: HCTheme.blackColorHC }}>
              <LightTooltip title="Order History">
                <TrackYourOrderLink onClick={() => {
                  navigate(`/order-history`);
                }}>
                  <Box>


                    <Badge
                      variant="dot"
                      invisible={!badgeData?.isRestaurantCartItems || !badgeData?.isHouseKeepingCartItems}
                    >
                      <GradingIcon sx={{ color: HCTheme.blackColorHC }} />
                    </Badge>
                  </Box>
                </TrackYourOrderLink>
              </LightTooltip>
            </RoomNumText>
          </Stack>
        </div>}

      </Stack>
      <div className="upper">
        <img src={logo} className="" alt="hotelcome_logo" width={300} height={300} />
      </div>
      <div className="upper" style={{ flex: 2 }}></div>
      <div
        className="middle"
        style={{
          position: "absolute",
          left: "50%",
          top: "70%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <p style={{ whiteSpace: "nowrap" }}>{menuList[curMenu]}</p>
      </div>

      <div
        className="lower"
        style={{
          position: "absolute",
          bottom: "0",
          transform: "translateY(50%)",
        }}
      >
        <div
          id="rotate"
          className="outer-circle"
          onTouchMove={handlerDiskMove}
          onTouchEnd={() => {
            lastreading = 0;
          }}
        >
          <div className="inner-circle"></div>
          <div
            className="menu-btn"
            style={{ transform: `rotate(-${curMenu * 45}deg)` }}
            onClick={() => {
              handlerMenuButtonClick(0, "/hotel-details");
            }}
          >
            <img
              alt=""
              src={hotelDetailsIcon}
              style={{ transform: `rotate(${curMenu * 45}deg)` }}
              className="circular-menu-icon"
            />
          </div>
          <div
            className="menu-btn"
            style={{ transform: `rotate(${(1 - curMenu) * 45}deg)` }}
            onClick={() => {
              handlerMenuButtonClick(1, "/restaurant");
            }}
          >
            <div
              style={{
                display:
                  badgeData.isRestaurantCartItems ||
                    badgeData.isRestaurantOrders
                    ? "block"
                    : "none",
              }}
              className="menu-btn_badge"
            ></div>
            <img
              alt=""
              src={restauuranrIcon}
              className="circular-menu-icon"
              style={{ transform: `rotate(${-(1 - curMenu) * 45}deg)` }}
            />
          </div>
          <div
            className="menu-btn"
            style={{ transform: `rotate(${(2 - curMenu) * 45}deg)` }}
            onClick={() => {
              handlerMenuButtonClick(2, "/room-services");
            }}
          >
            <div
              style={{
                display:
                  badgeData.isHouseKeepingCartItems ||
                    badgeData.isHouseKeepingOrders
                    ? "block"
                    : "none",
              }}
              className="menu-btn_badge"
            ></div>
            <img
              alt=""
              src={housekeppingIcon}
              className="circular-menu-icon"
              style={{ transform: `rotate(${-(2 - curMenu) * 45}deg)` }}
            />
          </div>
          <div
            className="menu-btn"
            style={{ transform: `rotate(${(3 - curMenu) * 45}deg)` }}
            onClick={() => {
              handlerMenuButtonClick(3, "/device-guide");
            }}
          >
            <img
              alt=""
              src={deviceGuideIcon}
              className="circular-menu-icon"
              style={{
                transform: `rotate(${-(3 - curMenu) * 45}deg)`,
                width: "30px",
                aspectRatio: 1,
              }}
            />
          </div>
          <div
            className="menu-btn"
            style={{ transform: `rotate(${(4 - curMenu) * 45}deg)` }}
            onClick={() => {
              handlerMenuButtonClick(4, "/call-services");
            }}
          >
            <img
              alt=""
              src={callServicesIcon}
              className="circular-menu-icon"
              style={{ transform: `rotate(${-(4 - curMenu) * 45}deg)` }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
