import React, { useState } from "react";
import { Avatar, Badge, Box } from "@mui/material";
import { Stack } from "@mui/system";

import { ProductPrice, ProductTitle } from "../styled/ProductBox";
import {
  TrackOrderItemPaper,
  TrackOrderItemTime,
  TrackOrderSecondaryText,
} from "../styled/TrackOrder";
import foodImg from '../../assets/svg/image 1.svg'
import open from '../../assets/svg/open.svg'
import close from '../../assets/svg/close.svg'

const DUMMY_DATA = [
  {
    id: 1,
    name: 'test 1',
    image: foodImg,
    quantity: 1,
    price: 4000
  },
  {
    id: 2,
    name: 'test 2',
    image: foodImg,
    quantity: 13,
    price: 4000
  },
  {
    id: 3,
    name: 'test 3',
    image: foodImg,
    quantity: 14,
    price: 4000
  },
  {
    id: 4,
    name: 'test 4',
    image: foodImg,
    quantity: 17,
    price: 4000
  },
]


const OrderIHistoryCompletedResraurantItem = ({ order, setType }) => {
  const [showMore, setShowMore] = useState(false)
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  const newArray = order.slice(1);

  const itemType = order[0]?.restaurantItemId ? 'restaurant' : 'houseKeeping'
  setType(itemType)

  // console.log(order);

  let sum = 0
  order?.forEach(ele => {
    sum = sum + +ele?.itemAmount + +ele?.taxAmount

  })

  // console.log(sum);
  return (
    <>
      <div style={{ marginTop: '12px' }}>
        {/* <Badge badgeContent='Accepted' color="success" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
        </Badge> */}
        {/* <Badge badgeContent='Cancelled' color="error" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
        </Badge> */}
        <Badge badgeContent={order[0]?.status === 'Completed' ? 'Completed' : null} color="success" sx={{ mr: 4 }} style={{ float: 'right' }}>
        </Badge>
        <Badge badgeContent={order[0]?.status === 'Accepted' ? 'Accepted' : null} color="success" sx={{ mr: 4 }} style={{ float: 'right' }}>
        </Badge>
        <Badge badgeContent={order[0]?.status === 'Rejected' ? 'Cancelled' : null} color="error" sx={{ mr: 4 }} style={{ float: 'right' }}>
        </Badge>
        <div style={{ marginTop: '0px' }}>


          <TrackOrderItemPaper elevation={0} style={{ background: '#EDEDED' }}>

            <Stack direction="" justifyContent="space-between" >
              <Avatar
                variant="rounded"
                alt="Remy Sharp"
                src={order[0]?.restaurantItem?.image || order[0]?.houseKeepingItem?.image}
                sx={{ width: 64, height: 64, mr: 1.25 }}
              />
              <Stack justifyContent="center" flex="1">
                <ProductTitle>{order[0]?.restaurantItem?.name || order[0]?.houseKeepingItem?.name} </ProductTitle>

              </Stack>
              <Box display="flex" flexDirection='column' alignItems="center" ml={3} mr={2} mt={1.5}>
                <TrackOrderSecondaryText><Stack direction="row" alignItems="center">
                  {order[0]?.quantity}&nbsp;
                </Stack> Qty</TrackOrderSecondaryText>
                <ProductPrice color={order[0]?.status === 'Rejected' ? '#CD0000' : '#32842B'}>
                  {currencySymbol} {+order[0]?.itemAmount + +order[0]?.taxAmount}
                </ProductPrice>
              </Box>
            </Stack>

          </TrackOrderItemPaper>

        </div>
      </div>


      {showMore && newArray.map(ele =>
        <div>

          {/* <Badge badgeContent='Accepted' color="success" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
          </Badge>
          <Badge badgeContent='Cancelled' color="error" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
          </Badge> */}

          <Badge badgeContent={ele?.status === 'Completed' ? 'Completed' : null} color="success" sx={{ mr: 4 }} style={{ float: 'right' }}>
          </Badge>
          <Badge badgeContent={ele?.status === 'Accepted' ? 'Accepted' : null} color="success" sx={{ mr: 4 }} style={{ float: 'right' }}>
          </Badge>
          <Badge badgeContent={ele?.status === 'Rejected' ? 'Cancelled' : null} color="error" sx={{ mr: 4 }} style={{ float: 'right' }}>
          </Badge>

          <div>
            <TrackOrderItemPaper elevation={1} style={{ background: '#EDEDED' }}>
              <div style={{ marginTop: '0px' }}>
                <Stack direction="" justifyContent="space-between">
                  <Avatar
                    variant="rounded"
                    alt="Remy Sharp"
                    src={ele?.restaurantItem?.image || ele?.houseKeepingItem?.image}
                    sx={{ width: 64, height: 64, mr: 1.25 }}
                  />
                  <Stack justifyContent="center" flex="1">
                    <ProductTitle>{ele?.restaurantItem?.name || ele?.houseKeepingItem?.name} </ProductTitle>
                    <Stack direction="row" alignItems="start">
                      <Box>


                      </Box>
                    </Stack>
                  </Stack>
                  <Box display="flex" flexDirection='column' alignItems="center" ml={3} mr={2} mt={1.5}>
                    <TrackOrderSecondaryText><Stack direction="row" alignItems="center">
                      {ele?.quantity}&nbsp;
                    </Stack> Qty</TrackOrderSecondaryText>
                    <ProductPrice color={ele?.status === 'Rejected' ? '#CD0000' : '#32842B'} >
                      {currencySymbol} {+ele?.itemAmount + +ele?.taxAmount}
                    </ProductPrice>
                  </Box>
                </Stack>
              </div>
            </TrackOrderItemPaper>
          </div>
        </div>)}
      {order?.length > 1 && <div onClick={() => {
        setShowMore(prev => !prev)
      }} style={{ margin: '4px auto', }} >
        <img src={!showMore ? close : open} /> View {!showMore ? 'More' : 'Less'}
      </div>}

    </>

  );
};

export default OrderIHistoryCompletedResraurantItem;
