import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import useHttp from "../../Hooks/use-http";
import CONSTANT from "../../utils/constant";
import DeviceSec from "../general/DeviceSec";
import DrawerComponent from "../general/DrawerComponent";
import { ChipHC } from "../styled/Chip";
import { PolicyLinkNew } from "../styled/HotelDetails";
import { PageSubHeading } from "../styled/PageSubHeading";
import LayoutComponent from "../general/LayoutComponent";

let oneTime = true;

export default function NewHotelDetailsDrawer(props) {
  const [deviceGuideCategories, setDeviceGuideCategories] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const getAllItems = useHttp();
  const DeviceHeight = window.innerHeight;
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const dispatch = useDispatch();

  const handlerGetAllDeviceGuideCategories = React.useCallback(
    (responseData) => {
      // console.log(responseData?.data, 'ads');
      // setDeviceGuideCategories(Object.keys(responseData?.data).map(
      //   (setDeviceGuideCategoriesItem, index) => {
      //     return {
      //       ...responseData?.data[setDeviceGuideCategoriesItem][0].category,
      //       isVisible: index === 0 ? true : false,
      //       Items: responseData?.data[setDeviceGuideCategoriesItem],
      //     };
      //   }
      // ))
      setDeviceGuideCategories(
        (responseData?.data).map(
          (setDeviceGuideCategoriesItem, index) => {
            return {
              ...setDeviceGuideCategoriesItem?.category,
              isVisible: index === 0 ? true : false,
              Items: [setDeviceGuideCategoriesItem],
            };
          }
        )
      );
    },
    []
  );

  // console.log(deviceGuideCategories);

  const loadAllDeviceGuideCategories = () => {
    const getAllDeviceGuideItems = {
      type: "GET",
      endpoint: `/device/guides`,
    };
    getAllItems.sendRequest(
      getAllDeviceGuideItems,
      handlerGetAllDeviceGuideCategories,
      {}
    );
  };

  const handlerUpdateVisibility = (catId_, visibility_) => {
    if (deviceGuideCategories.length > 0) {
      setDeviceGuideCategories((prev) => {
        const x = prev.map((cat) => {
          if (cat?.id == catId_) {
            cat.isVisible = visibility_;
          }
          return cat;
        });
        return x;
      });
    }
  };

  React.useEffect(() => {
    loadAllDeviceGuideCategories();
  }, []);

  return (
    <Box >
      <LayoutComponent isSearchable={false}>
        {/* Heading */}
        <Stack
          direction="row"
          spacing={1.25}
          sx={{ overflow: "scroll", mt: 1 }}
        >
          {deviceGuideCategories.length ? (
            deviceGuideCategories.map((category, index) => (
              // <Fragment key={category?.id}>
              //   <a href={`#${category?.id}`} className="category-link">
              //     <ChipHC
              //       label={category?.name}
              //       color="primary"
              //       variant={
              //         index ==
              //           deviceGuideCategories.findIndex(
              //             (deviceGuideCategoriesItem) =>
              //               deviceGuideCategoriesItem.isVisible
              //           )
              //           ? "contained"
              //           : "outlined"
              //       }
              //       // onClick={() => setCurCategory(category)}
              //       onClick={() => {
              //         CONSTANT.isChangingOnClick = true;
              //         setTimeout(() => {
              //           CONSTANT.isChangingOnClick = false;
              //         }, 600);
              //       }}
              //     />
              //   </a>
              // </Fragment>
              <>
                <p style={{ fontWeight: '600', fontSize: '20px' }}>
                  Guides
                </p>
              </>
            ))
          ) : (
            <PageSubHeading>No categories found.</PageSubHeading>
          )}
        </Stack>
        {/* Body */}
        {deviceGuideCategories.length !== 0 ? (
          // <Stack mt={13.5}>
          <Stack className="overFlowScroll">
            {deviceGuideCategories.map((category) => (
              <DeviceSec
                key={category?.id}
                category={category}
                onUpdateVisibility={handlerUpdateVisibility}
              ></DeviceSec>
            ))}
            <PolicyLinkNew sx={{ mt: 2.5, textAlign: "center" }} style={{
              maxWidth: '170px',
              textAlign: 'center',
              margin: ' 0px auto',
              marginTop: '10px'
            }}>
              <a
                href={CONSTANT.hotelcomPolicyUrl}
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions of Hotelcom
              </a>
            </PolicyLinkNew>
          </Stack>
        ) : (
          <Stack
            spacing={3}
            mt={2.5}
            p={10}
            sx={{ background: "#EDEDED", borderRadius: 2 }}
          >
            <PageSubHeading sx={{ textAlign: "center" }}>
              No items found.
            </PageSubHeading>
          </Stack>
        )}
      </LayoutComponent>
    </Box>
  );
}

/* <Box>
  <PageSubHeading>AC</PageSubHeading>
  <video
    width="100%"
    // height={240}
    className="device-guide-video"
    controls
  >
    <source src={vidTest} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</Box> */
