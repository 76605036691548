import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Box, Container, Stack } from "@mui/system";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { HCTheme } from "../../utils/theme";
import { ButtonQR } from "../styled/PageSubHeading";
import LogoImg from "./../../assets/images/logo.png";

const AuthFallback = ({ hotelName }) => {
  return (
    <Fragment>
      <Container
        maxWidth="sm"
        disableGutters={true}
        sx={{
          position: "relative",
          minHeight: window.innerHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "strach",
          overflow: "hidden",
          bgcolor: HCTheme.whiteColorHC,
        }}
      >
        <Stack
          sx={{
            minHeight: "100vh",
            justifyContent: "space-between",
            alignItems: "center",
            py: "20vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            className="auth-falback-logo-box"
          >
            <img src={LogoImg} className="main-logo" alt="hotelcome_logo" />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Link to="/signin-with-qrcode" className="">
              <ButtonQR sx={{ display: "flex", alignItems: "center" }}>
                <QrCodeScannerIcon sx={{ fontSize: 28, mr: 2 }} />
                Scan QR
              </ButtonQR>
            </Link>
          </Box>
        </Stack>
      </Container>
    </Fragment>
  );
};

export default AuthFallback;
