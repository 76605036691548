import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { HCTheme } from "../../utils/theme";

export const ButtonHC = styled(Button)(({ theme, size }) => ({
  borderRadius: theme.spacing(1),

  fontFamily: "Satoshi-Variable",
  fontSize: theme.spacing(size === "small" ? 1.5 : 2),
  width: '100%',
  fontWeight: "700",

  lineHeight: "20px",
  letterSpacing: "0em",
  border: "2px solid #7A7A7A",
  padding: size === "small" ? "8px 12px" : "5px 18px",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "none",
    border: "2px solid #7A7A7A",
  },
  "&:active": {
    boxShadow: "none",
    border: "2px solid #7A7A7A",
  },
  "&:focus": {
    boxShadow: "none",
    border: "2px solid #7A7A7A",
  },
  "&:disabled": {
    background: theme.palette.primary.main,
    color: HCTheme.whiteColorHC,
    opacity: "0.8",
    boxShadow: "none",
    border: "2px solid #7A7A7A",
    cursor: "not-allowed",
  },
}));
export const ButtonHCSmall = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  fontFamily: "Satoshi-Variable",
  fontSize: theme.spacing(1.5),
  fontWeight: "700",
  lineHeight: "12px",
  letterSpacing: "0em",
  border: "1px solid #7A7A7A",
  padding: "8px 8px",
  "&:hover": {
    boxShadow: "none",
    border: "1px solid #7A7A7A",
  },
  "&:active": {
    boxShadow: "none",
    border: "1px solid #7A7A7A",
  },
  "&:focus": {
    boxShadow: "none",
    border: "1px solid #7A7A7A",
  },
  "&:disabled": {
    background: "red",
    color: HCTheme.whiteColorHC,
    opacity: "0.8",
    boxShadow: "none",
    border: "1px solid #7A7A7A",
    cursor: "not-allowed",
  },
}));
