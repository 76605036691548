import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import useHttp from "../../Hooks/use-http";
import { HCTheme } from "../../utils/theme";
import { ButtonHC } from "../styled/Button";
import { CartCountText } from "../styled/Cart";
import {
  ProductDescription,
  ProductPaper,
  ProductPrice,
  ProductTime,
  ProductTitle,
} from "../styled/ProductBox";
import veg from '../../assets/svg/veg.svg'
import nonVeg from '../../assets/svg/nonVeg.svg'


const TruncateText = ({ text, maxWords }) => {
  const words = text.split(' ');

  if (words.length > maxWords) {
    const truncatedText = words.slice(0, maxWords).join(' ') + '...';
    return <ProductDescription>{truncatedText}</ProductDescription>;
  }

  return <ProductDescription>{text}</ProductDescription>;
};

const Product = ({ item, onIncrement, onDecrement, myCart, onChangeCart }) => {
  const isUnAvailable = !item?.isAvailable;

  const planRes = +(localStorage.getItem('plan'))
  // const planRes = 3
  // console.log(planRes);
  // console.log(item);


  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");

  const addToCart = useHttp();
  const removeFromCart = useHttp();
  const increaseQnntByOne = useHttp();
  const decreaseQnntByOne = useHttp();

  const [count, setCount] = useState(
    myCart?.cartItem.filter(
      (cartItem) => cartItem?.restaurantItem?.id == item?.id
    )[0]?.quantity || 0
  );

  const [cartId, setCartId] = useState(
    myCart?.cartItem.filter(
      (cartItem) => cartItem?.restaurantItem?.id == item?.id
    )[0]?.id
  );

  const isLoading = () => {
    return (
      addToCart.isLoading ||
      removeFromCart.isLoading ||
      increaseQnntByOne.isLoading ||
      decreaseQnntByOne.isLoading
    );
  };

  const handlerAddToCartResponse = useCallback((responseData) => {
    setCount((prev) => prev + 1);
    setCartId(responseData?.data?.id);
    onIncrement(item?.price, responseData?.cart);
  }, []);

  const handlerIncreaseQnntByOneResponse = useCallback((responseData) => {
    setCount((prev) => prev + 1);
    onIncrement(item?.price, responseData?.cart);
  }, []);

  const handlerDecreaseQnntByOneResponse = useCallback((responseData) => {
    setCount((prev) => prev - 1);
    onDecrement(item?.price, responseData?.cart);
  }, []);

  const handlerRemoveFromCartResponse = useCallback((responseData) => {
    setCount(0);
    onDecrement(item?.price, responseData?.cart);
  }, []);

  const handlerAddToCart = (id) => {
    const payload = {
      quantity: 1,
      restaurantItemId: id,
      houseKeepingItemId: null,
    };

    let addToCartUrl = {
      type: "POST",
      endpoint: "/cart/items/add/to/cart?section=restaurant",
    };

    addToCart.sendRequest(addToCartUrl, handlerAddToCartResponse, payload);
  };

  const handlerIncreaseQnntByOne = () => {
    const patchIncreaseQnntByOne = {
      type: "PATCH",
      endpoint: `/cart/items/increase/quantity/id/${cartId}?section=restaurant`,
    };

    increaseQnntByOne.sendRequest(
      patchIncreaseQnntByOne,
      handlerIncreaseQnntByOneResponse,
      {}
    );
  };

  const handlerDecreaseQnntByOne = () => {
    const patchDecreaseQnntByOne = {
      type: "PATCH",
      endpoint: `/cart/items/decrease/quantity/id/${cartId}?section=restaurant`,
    };

    decreaseQnntByOne.sendRequest(
      patchDecreaseQnntByOne,
      handlerDecreaseQnntByOneResponse,
      {}
    );
  };

  const handlerRemoveFromCart = (id) => {
    const urlRemoveFromCart = {
      type: "DELETE",
      endpoint: `/cart/items/remove/cart/items/${cartId}?section=restaurant`,
    };

    removeFromCart.sendRequest(
      urlRemoveFromCart,
      handlerRemoveFromCartResponse,
      {}
    );
  };

  const handlerDecrement = (id) => {
    if (+count === 1) {
      handlerRemoveFromCart(id);
    } else {
      handlerDecreaseQnntByOne();
    }
  };

  useEffect(() => {
    setCount(
      myCart?.cartItem.filter(
        (cartItem) => cartItem?.restaurantItem?.id == item?.id
      )[0]?.quantity || 0
    );

    setCartId(
      myCart?.cartItem.filter(
        (cartItem) => cartItem?.restaurantItem?.id == item?.id
      )[0]?.id
    );
  }, [item, myCart]);

  return (
    <ProductPaper isUnAvailable={isUnAvailable}>
      <Stack direction="row" justifyContent="space-between">
        <Avatar
          variant="rounded"
          alt="Remy Sharp"
          src={item?.image}
          sx={{
            width: 135,
            height: 150,
            mr: 1.25,
            filter: isUnAvailable ? "brightness(0.7)" : "unset",
          }}
        />
        <Stack justifyContent="center" flex="1">
          <div>
            <img src={item?.isVeg ? veg : nonVeg} alt='veg' width={18} height={18} />
          </div>
          <ProductTitle>
            {item?.name} {item?.cartId}
          </ProductTitle>

          <div>
            <ProductDescription>

              {item?.description}

            </ProductDescription>
          </div>

          <ProductTime sx={false ? {} : { mt: 0.5 }}>

            <Stack direction="row" alignItems="" sx={{ mb: 0.5 }}>
              <ProductPrice color="#32842B">
                {currencySymbol} {item?.price}
              </ProductPrice>

              <AccessTimeIcon sx={{ ml: 1, mr: 0.5, mt: 0.5, fontSize: '16px' }} /> <span style={{ marginTop: '3px', fontSize: '12px' }}>{item?.time}</span>

            </Stack>
          </ProductTime>
          {(!isUnAvailable && planRes !== 2) && (
            <Box direction="row" display="flex" justifyContent='center' alignItems="center" >
              {count > 0 ? (
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  sx={{ background: "rgb(8, 10, 7)", width: '100%', display: 'flex', justifyContent: 'space-between' }}
                  style={{
                    height: "34px",
                  }}

                >
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={() => handlerDecrement(item?.id)}
                    disabled={+count === 0 || isLoading()}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <IconButton variant="contained" color="secondary">
                    {isLoading() ? (
                      <CircularProgress
                        style={{
                          color: HCTheme.whiteColorHC,
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    ) : (
                      <CartCountText>{count}</CartCountText>
                    )}
                  </IconButton>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    onClick={handlerIncreaseQnntByOne}
                    disabled={isLoading()}
                  >
                    <AddIcon />
                  </IconButton>
                </ButtonGroup>
              ) : (
                <ButtonHC
                  variant="contained"
                  startIcon={isLoading() ? null : <ShoppingCartOutlinedIcon />}
                  onClick={() => handlerAddToCart(item?.id)}
                  disabled={isLoading()}
                >
                  {isLoading() ? (
                    <CircularProgress
                      style={{
                        color: HCTheme.whiteColorHC,
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  ) : (
                    "ADD"
                  )}
                </ButtonHC>
              )}
            </Box>
          )}
        </Stack>

      </Stack>

    </ProductPaper>
  );
};

export default Product;
