import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import {
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import { Fragment, useCallback, useEffect, useState } from "react";
import useHttp from "../../Hooks/use-http";
import { HCTheme } from "../../utils/theme";
import { ButtonHC } from "../styled/Button";
import { CartCountText } from "../styled/Cart";
import {
  ProductDescription,
  ProductPaper,
  ProductPrice,
  ProductTime,
  ProductTitle,
} from "../styled/ProductBox";

const TruncateText = ({ text, maxWords }) => {
  const words = text.split(' ');

  if (words.length > maxWords) {
    const truncatedText = words.slice(0, maxWords).join(' ') + '...';
    return <ProductDescription>{truncatedText}</ProductDescription>;
  }

  return <ProductDescription>{text}</ProductDescription>;
};

const RoomServiceProduct = ({ item, onIncrement, onDecrement, myCart }) => {
  const addToCart = useHttp();
  const removeFromCart = useHttp();
  const increaseQnntByOne = useHttp();
  const decreaseQnntByOne = useHttp();
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");

  const [count, setCount] = useState(
    myCart?.cartItem.filter(
      (cartItem) => cartItem?.houseKeepingItem?.id == item?.id
    )[0]?.quantity || 0
  );

  const [cartId, setCartId] = useState(
    myCart?.cartItem.filter(
      (cartItem) => cartItem?.houseKeepingItem?.id == item?.id
    )[0]?.id
  );
  const isUnAvailable = !item?.isAvailable;
  const planRes = +(localStorage.getItem('plan'))
  // console.log(item);

  const isLoading = () => {
    return (
      addToCart.isLoading ||
      removeFromCart.isLoading ||
      increaseQnntByOne.isLoading ||
      decreaseQnntByOne.isLoading
    );
  };

  const handlerAddToCartResponse = useCallback((responseData) => {
    setCount((prev) => prev + 1);
    setCartId(responseData.data.id);
    onIncrement(item?.price, responseData?.cart);
  }, []);

  const handlerAddToCart = (id) => {
    const payload = {
      quantity: 1,
      restaurantItemId: null,
      houseKeepingItemId: id,
    };

    let addToCartUrl = {
      type: "POST",
      endpoint: "/cart/items/add/to/cart?section=houseKeeping",
    };

    addToCart.sendRequest(addToCartUrl, handlerAddToCartResponse, payload);
  };

  const handlerIncreaseQnntByOneResponse = useCallback((responseData) => {
    setCount((prev) => prev + 1);
    onIncrement(item?.price, responseData?.cart);
  }, []);

  const handlerIncreaseQnntByOne = () => {
    const patchIncreaseQnntByOne = {
      type: "PATCH",
      endpoint: `/cart/items/increase/quantity/id/${cartId}?section=houseKeeping`,
    };

    increaseQnntByOne.sendRequest(
      patchIncreaseQnntByOne,
      handlerIncreaseQnntByOneResponse,
      {}
    );
  };

  const handlerDecreaseQnntByOneResponse = useCallback((responseData) => {
    setCount((prev) => prev - 1);
    onDecrement(item?.price, responseData?.cart);
  }, []);

  const handlerDecreaseQnntByOne = () => {
    const patchDecreaseQnntByOne = {
      type: "PATCH",
      endpoint: `/cart/items/decrease/quantity/id/${cartId}?section=houseKeeping`,
    };

    decreaseQnntByOne.sendRequest(
      patchDecreaseQnntByOne,
      handlerDecreaseQnntByOneResponse,
      {}
    );
  };

  const handlerRemoveFromCartResponse = useCallback((responseData) => {
    setCount(0);
    onDecrement(item?.price, responseData?.cart);
  }, []);

  const handlerRemoveFromCart = (id) => {
    const urlRemoveFromCart = {
      type: "DELETE",
      endpoint: `/cart/items/remove/cart/items/${cartId}?section=houseKeeping`,
    };

    removeFromCart.sendRequest(
      urlRemoveFromCart,
      handlerRemoveFromCartResponse,
      {}
    );
  };

  const handlerDecrement = (id) => {
    if (+count === 1) {
      handlerRemoveFromCart(id);
    } else {
      handlerDecreaseQnntByOne();
    }
  };

  useEffect(() => {
    setCount(
      myCart?.cartItem.filter(
        (cartItem) => cartItem?.houseKeepingItem?.id == item?.id
      )[0]?.quantity || 0
    );
    setCartId(
      myCart?.cartItem.filter(
        (cartItem) => cartItem?.houseKeepingItem?.id == item?.id
      )[0]?.id
    );
  }, [item, myCart]);

  return (
    <ProductPaper isUnAvailable={isUnAvailable}>
      <Stack direction="row" justifyContent="space-between">
        <Avatar
          variant="rounded"
          alt={item?.name}
          src={item?.image}
          style={{
            objectFit: 'cover'
          }}
          sx={{
            width: 135,
            height: 150,
            mr: 1.25,
            filter: isUnAvailable ? "brightness(0.7)" : "unset",
          }}
        />
        <Stack justifyContent="center" flex="1">
          <ProductTitle>
            {item?.name} {item?.cartId}
          </ProductTitle>

          <TruncateText text={item?.description} maxWords={10} />

          <ProductTime sx={false ? {} : { mt: 0.5 }}>

            <Stack direction="row" alignItems="" sx={{ mb: 0.5 }}>
              <ProductPrice color="#32842B">
                {currencySymbol} {item?.price}
              </ProductPrice>

              <AccessTimeIcon sx={{ ml: 1, mr: 0.5, mt: 0.5, fontSize: '16px' }} /> <span style={{ marginTop: '3px', fontSize: '12px' }}>{item?.time} </span>

            </Stack>
          </ProductTime>
          {(!isUnAvailable && planRes !== 2) && (
            <Fragment>
              {item?.price >= 0 && (
                <Box display="flex" alignItems="center" justifyContent='center'>
                  {count > 0 ? (
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                      sx={{ background: "rgb(8, 10, 7)", width: '100%', display: 'flex', justifyContent: 'space-between' }}
                      style={{
                        height: "34px",
                      }}
                    >
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={() => handlerDecrement(item?.id)}
                        disabled={+count === 0}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton variant="contained" color="secondary">
                        <CartCountText>{count}</CartCountText>
                      </IconButton>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={handlerIncreaseQnntByOne}
                      >
                        <AddIcon />
                      </IconButton>
                    </ButtonGroup>
                  ) : (
                    <ButtonHC
                      variant="contained"
                      startIcon={
                        isLoading() ? null : <ShoppingCartOutlinedIcon />
                      }
                      onClick={() => handlerAddToCart(item?.id)}
                      disabled={isLoading()}
                    >
                      {isLoading() ? (
                        <CircularProgress
                          style={{
                            color: HCTheme.whiteColorHC,
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      ) : (
                        "ADD"
                      )}
                    </ButtonHC>
                  )}
                </Box>
              )}

              {!item?.price > 0 && (
                <Box display="flex" alignItems="center" ml={4.5}>
                  {count > 0 ? (
                    <ButtonHC
                      variant="contained"
                      startIcon={isLoading() ? null : <RemoveShoppingCartIcon />}
                      onClick={() => handlerRemoveFromCart(item?.id)}
                      size="small"
                    >
                      {isLoading() ? (
                        <CircularProgress
                          style={{
                            color: HCTheme.whiteColorHC,
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      ) : (
                        "REMOVE"
                      )}
                    </ButtonHC>
                  ) : (
                    <ButtonHC
                      variant="contained"
                      startIcon={
                        isLoading() ? null : <ShoppingCartOutlinedIcon />
                      }
                      onClick={() => handlerAddToCart(item?.id)}
                      disabled={count > 0}
                    >
                      {isLoading() ? (
                        <CircularProgress
                          style={{
                            color: HCTheme.whiteColorHC,
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      ) : (
                        "ADD"
                      )}
                    </ButtonHC>
                  )}
                </Box>
              )}
            </Fragment>
          )}
        </Stack>


      </Stack>
    </ProductPaper>
  );
};

export default RoomServiceProduct;
