const CONSTANT = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  hotelcomPolicyUrl: "https://hotelcom.live/terms-conditions.html",
  URLS: {
    getContacts: {
      type: "GET",
      endpoint: "/contacts",
    },
    getDeviceGuides: {
      type: "GET",
      endpoint: "/device/guides",
    },
    addToCart: {
      type: "POST",
      endpoint: "/cart/items/add/to/cart",
    },
    getMyCart: {
      type: "GET",
      endpoint: "/carts/users?section=houseKeeping/restaurant",
    },
    getRestaurant: {
      type: "GET",
      endpoint: "/restaurents",
    },
    getServiceProvider: {
      type: "GET",
      endpoint: "/service-providers",
    },
    checkout: {
      type: "PATCH",
      endpoint: "/orders/users/checkout",
    },
    getMyAllOrders: {
      type: "GET",
      endpoint: `/orders/users?parentId=${localStorage.getItem("statusId")}`,
    },
    submitFeedback: {
      type: "POST",
      endpoint: "/guests/feedbacks",
    },
    confirmOrders: {
      type: "PATCH",
      endpoint: "/orders/users/confirm",
    },
    updateFcmToken: {
      type: "PATCH",
      endpoint: `/rooms/fcm/${localStorage.getItem('roomId')}`,
    },
    getOrdersByParentId: {
      type: "GET",
      endpoint: "/orders/users",
    },
    cancelOrders: {
      type: "PATCH",
      endpoint: "/orders/users/cancel",
    },
    getAllConfirmedOrders: {
      type: "GET",
      endpoint: "/orders/users/confirmed",
    },
    getHotelsDetails: {
      type: "GET",
      endpoint: "/hotels/details",
    },

    loginToken: {
      type: "POST",
      endpoint: "/tokens/login/",
    },
    checkToken: {
      type: "POST",
      endpoint: "/tokens/check/isactive",
    },
    increaseScan: {
      type: "PATCH",
      endpoint: "/tokens/increase/scans",
    },
    getBadgeData: {
      type: "POST",
      endpoint: "/orders/track/cart/orders",
    },
    getAllRestaurantItems: {
      type: "GET",
      endpoint: "/restaurants/items/guests",
    },
    getAllHousekeepingItems: {
      type: "GET",
      endpoint: "/housekeeping/items/guests",
    },
  },
  isChangingOnClick: false,
};

// console.log(object);

export const Token = {
  token: "",
};

export default CONSTANT;
