import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CartSummary from "../components/cart/CartSummary";
import PreCartHouseKeepingItem from "../components/cart/PreCartHouseKeepingItem";
import PreCartItem from "../components/cart/PreCartItem";
import { ButtonHC } from "../components/styled/Button";
import { PageHeading } from "../components/styled/PageHeading";
import { PageSubHeading } from "../components/styled/PageSubHeading";
import useHttp from "../Hooks/use-http";
import { HCTheme } from "../utils/theme";
import { TextArea } from "../components/styled/TextArea";
import { Label } from "@mui/icons-material";

const PreCart = ({ onUpdateParentId }) => {
  const search = useLocation().search;
  const section = new URLSearchParams(search).get("section");
  let navigate = useNavigate();
  const { state } = useLocation();
  const getMyCart = useHttp();
  const checkout = useHttp();

  const [myCart, setMyCart] = useState({ cartItem: [] });
  const [notesValue, setNotesValue] = useState('')

  // console.log(notesValue);

  const handlerGetMyCart = useCallback((responseData) => {
    setMyCart(responseData?.data);
  }, []);

  const loadMyCart = () => {
    let getMyCartUrl = {
      type: "GET",
      endpoint: "/carts/users",
    };

    if (section) {
      getMyCartUrl.endpoint = `/carts/users?section=${section}`;
    }
    getMyCart.sendRequest(getMyCartUrl, handlerGetMyCart, {});
  };

  const handlerCheckoutResponse = useCallback((responseData) => {
    localStorage.setItem("statusId", responseData?.orderId);
    onUpdateParentId(responseData?.orderId);
    // navigate(`/cart/${responseData?.orderId}?section=${section}`);
    navigate(`/order-history`);
  }, []);

  const handlerCheckout = () => {

    let checkoutUrl = {
      type: "PATCH",
      endpoint: "/orders/users/checkout",
    };

    const payload = {
      notes: notesValue
    }
    // console.log(payload);

    if (section) {
      checkoutUrl.endpoint = `/orders/users/checkout?section=${section}`;
    }
    checkout.sendRequest(checkoutUrl, handlerCheckoutResponse, payload);
  };

  const restaurantItemsIncrement = (cart) => {
    setMyCart(cart);
  };

  const restaurantItemsDecrement = (cart) => {
    setMyCart(cart);
  };

  useEffect(() => {
    loadMyCart();
  }, []);

  // console.log(myCart);

  return (
    <Fragment>
      <Container
        maxWidth="sm"
        sx={{ minHeight: "100vh", background: HCTheme.whiteColorHC }}
      >
        <Box>
          <IconButton
            aria-label="back"
            sx={{ p: 0, mt: 2.5 }}
            disableRipple
            onClick={() => {
              if (section === 'restaurant') {
                navigate('/restaurant')
              }
              else if (section === 'houseKeeping') {
                navigate('/room-services')
              }
            }} style={{
              margin: '8px 0px'

            }}
          >
            <ArrowBackIcon sx={{ color: HCTheme.blackColorHC }} />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          <PageHeading>{state.title}</PageHeading>
        </Box>

        <Box sx={{ my: 2.5 }}>
          <Stack spacing={2.5}>
            {myCart?.cartItem.length !== 0 ? (
              myCart?.cartItem.map((cartItem) => {
                if (cartItem?.quantity && cartItem?.restaurantItemId) {
                  return (
                    <PreCartItem
                      item={cartItem}
                      onIncrement={restaurantItemsIncrement}
                      onDecrement={restaurantItemsDecrement}
                      myCart={myCart}
                    />
                  );
                }
                if (cartItem?.quantity && cartItem?.houseKeepingItemId) {
                  return (
                    <PreCartHouseKeepingItem
                      onIncrement={restaurantItemsIncrement}
                      onDecrement={restaurantItemsDecrement}
                      myCart={myCart}
                      item={cartItem}
                    />
                  );
                }
              })
            ) : (
              <Stack
                spacing={3}
                p={10}
                sx={{ background: "#DEDEDE", borderRadius: 2 }}
              >
                <PageSubHeading sx={{ textAlign: "center" }}>
                  No items found.
                </PageSubHeading>
              </Stack>
            )}
          </Stack>
        </Box>
        {myCart?.cartItem.length !== 0 && <div className="cart-dash-border" onClick={() => { window.history.back(); }} style={{
          margin: '8px 0px'

        }}>
          <p style={{ textAlign: 'center', color: '#757575', padding: '8px 0px', fontWeight: 700 }}>+ Add More Items</p>
        </div>}

        {myCart?.cartItem.length !== 0 && <div>
          <p className="" style={{ fontWeight: 700 }}>
            Notes
          </p>
          <TextArea
            sx={{ mt: 1.25, p: 1, color: HCTheme.blackColorHC, background: '#D9D9D9' }}
            name="notes"
            id="notes"
            placeholder="Add Notes"
            fullWidth
            multiline
            minRows="6"
            onChange={(e) => {
              if (e) {
                return setTimeout(() => {
                  setNotesValue(e.target.value)
                }, 800);
              }
            }}
          ></TextArea>
        </div>}
        <div >
          {myCart?.cartItem.length !== 0 && <CartSummary
            totalAmount={myCart.itemTotal * 1}
            totalItems={myCart?.cartItem?.length}
            itemTotal={((+myCart?.itemTotal) + (+myCart?.taxTotal)).toFixed(2)}
          />}
          {myCart?.cartItem.length !== 0 && <Box sx={{ my: 1 }}>
            <ButtonHC
              variant="contained"
              fullWidth
              sx={{ textTransform: "capitalize", py: 1.5 }}
              onClick={handlerCheckout}
              disabled={myCart?.cartItem.length === 0}

            >
              Place Order
            </ButtonHC>
          </Box>}
        </div>
      </Container>
    </Fragment >
  );
};

export default PreCart;
