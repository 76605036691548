import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const FeedbackQastion = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "22px",
  lineHeight: "30px",
  color: "#262626",
}));

export const FeedbackInfoText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "rgba(38, 38, 38, 0.3)",
}));
