import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { HCTheme } from "../../utils/theme";
import {
  TrackOrderConfirmedText,
  TrackOrderHeaderID,
} from "../styled/TrackOrder";
import TrackOrderSVG from "./TrackOrderSVG";
import logo from '../../assets/images/logo.png'
import gifLogo from '../../assets/gif/pending.gif'
import cancelLogo from '../../assets/svg/cancel.svg'

const TrackOrderHeader = ({ orderId, itemType, curOrder, status }) => {
  const search = useLocation().search;
  const section = new URLSearchParams(search).get("section");

  // console.log(status);

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <Link to={`/order-history`}>
          <IconButton aria-label="back" sx={{ p: 0, mt: 2.5 }} disableRipple>
            <ArrowBackIcon sx={{ color: HCTheme.blackColorHC }} />
          </IconButton>
        </Link>
        {/* <TrackOrderHeaderID sx={{ mt: 2.5, ml: "auto" }}>
          Track your order Item :{" "}
          <span style={{ marginLeft: "0.2rem" }}>{orderId}</span>
        </TrackOrderHeaderID> */}
      </Stack>
      <Box display="flex" sx={{ mt: 1 }} flex="1" justifyContent="center" alignItems="center">
        {/* {status === 'pending' ? <img src={logo} /> : <TrackOrderSVG />} */}
        {status === 'Cancelled' && <img src={cancelLogo} height={250} />}
        {status === 'Pending' && <img src={gifLogo} height={250} />}
        {(status === 'Accepted' || status === 'Completed') && <TrackOrderSVG />}

      </Box>
      <Box my={2}>
        <TrackOrderConfirmedText>
          Order {status}!
        </TrackOrderConfirmedText>
      </Box>
    </Stack>
  );
};

export default TrackOrderHeader;
