import { InputBase } from "@mui/material";
import { styled } from "@mui/system";

export const TextArea = styled(InputBase)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "19px",
  color: "rgba(38, 38, 38, 0.2)",
  border: "2px solid rgba(38, 38, 38, 0.1)",
  borderRadius: "8px",
  "&:hover": {
    boxShadow: "none",
    outline: "1px solid rgba(38, 38, 38, 0.1)",
  },
  "&:active": {
    boxShadow: "none",
    outline: "1px solid rgba(38, 38, 38, 0.1)",
  },
  "&:focus": {
    boxShadow: "none",
    outline: "1px solid rgba(38, 38, 38, 0.1)",
  },
}));
