import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import CallServices from "../../pages/CallServices";
import Cart from "../../pages/Cart";
import DevicesGuide from "../../pages/DevicesGuide";
import Feedback from "../../pages/Feedback";
import Home from "../../pages/Home";
import HotelDetails from "../../pages/HotelDetails";
import OrderHistory from "../../pages/OrderHistory";
import PreCart from "../../pages/PreCart";
import Restaurant from "../../pages/Restaurant";
import RoomServices from "../../pages/RoomServices";
import TrackOrder from "../../pages/TrackOrder";
import AuthFallback from "../general/AuthFallback";
import SendingOrder from "../general/SendingOrder";
import SignInWithQRCode from "../general/SignInWithQRCode";
import SignInWithURL from "../general/SignInWithURL";
import SuccessMessage from "../general/SuccessMessage";
import RestaurantDetails from "../Restaurant/RestaurantDetails";
import ProviderDetails from "../RoomServices/ProviderDetails";

const PrivateRoutes = ({ hotelName, roomNum }) => {
  const [parentId, setParentId] = useState(null);

  const handlerUpdateParentId = (id) => {
    setParentId(id);
  };

  return (
    <Routes>
      <Route path="/" element={<SuccessMessage hotelName={hotelName} />} />
      <Route path="/home" element={<Home roomNum={roomNum} />} />
      <Route
        path="/pre-cart"
        element={<PreCart onUpdateParentId={handlerUpdateParentId} />}
      />
      <Route path="/cart" element={<Cart parentId={parentId} />} />
      <Route path="/cart/:statusId" element={<Cart parentId={parentId} />} />
      <Route
        path="/trackorder/:orderId"
        element={<TrackOrder hotelName={hotelName} />}
      />
      <Route
        path="/order-history"
        element={<OrderHistory hotelName={hotelName} />}
      />
      <Route path="/restaurant" element={<Restaurant roomNum={roomNum} />} />
      <Route path="/restaurant/:id" element={<RestaurantDetails roomNum={roomNum} />} />
      <Route
        path="/room-services"
        element={<RoomServices roomNum={roomNum} />}
      />
      <Route
        path="/room-services/:id"
        element={<ProviderDetails roomNum={roomNum} />}
      />
      <Route
        path="/device-guide"
        element={<DevicesGuide roomNum={roomNum} />}
      />
      <Route
        path="/hotel-details"
        element={<HotelDetails roomNum={roomNum} />}
      />
      <Route path="/feedback" element={<Feedback roomNum={roomNum} />} />
      <Route
        path="/call-services"
        element={<CallServices roomNum={roomNum} />}
      />
      <Route path="/products" element={<h1>Products</h1>} />
      <Route path="/products/:productId" element={<h1>Products - Id</h1>} />
      <Route path="/signin-with-url/:token" element={<SignInWithURL />} />
      <Route path="/signin-with-qrcode" element={<SignInWithQRCode />} />
      <Route path="/auth-fallback" element={<AuthFallback />} />
      <Route path="/sending-order" element={<SendingOrder />} />
      <Route
        path="/success-message"
        element={<SuccessMessage hotelName={hotelName} />}
      />
    </Routes>
  );
};

export default PrivateRoutes;
