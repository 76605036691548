import { Badge, Box, CircularProgress, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OrderHistoryRestaurantProduct from "../components/general/OrderHistoryRestaurantProduct";
import OrderIHistoryCompletedHouseKeepingItem from "../components/general/OrderIHistoryCompletedHouseKeepingItem";
import OrderIHistoryCompletedResraurantItem from "../components/general/OrderIHistoryCompletedResraurantItem";
import OrderHistoryRoomServiceProduct from "../components/general/RoomServiceOrderHistoryProduct";
import { OrderHistoryOrderIDText } from "../components/styled/OrderHistory";
import { PageHeading } from "../components/styled/PageHeading";
import { PageSubHeading } from "../components/styled/PageSubHeading";
import OrderHistoryHeader from "../components/track-order copy/OrderHistoryHeader";
import useHttp from "../Hooks/use-http";
import { HCTheme } from "../utils/theme";
import OrderList from "../components/general/OrderList";
import { ProductPaper, ProductTitle } from "../components/styled/ProductBox";

const OrderHistory = ({ hotelName }) => {
  const search = useLocation().search;
  const section = new URLSearchParams(search).get("section");
  const getAllConfirmedOrders = useHttp();
  const { state } = useLocation();
  const [orders, setOrders] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");

  // console.log(invoiceDetails);

  // order.parentId


  let currentOrders = [];
  let completedOrders = [];

  const classifyOrders = (orders) => {
    currentOrders = [];
    completedOrders = [];



    // console.log(status);
    // const temp = orders?.sort((a, b) => b.parentId - a.parentId);

    // console.log(orders, "orders");
    // console.log(temp, "temp");





    // orders.map((order) => {
    //   if (order?.status === "Pending" || order?.status === "Accepted") {
    //     currentOrders.push(order);
    //   }
    //   if (order?.status === "Completed") {
    //     completedOrders.push(order);
    //   }

    // });
  };

  // classifyOrders(confirmedOrders);

  // currentOrders.sort((a, b) => b.parentId - a.parentId);
  // completedOrders.sort((a, b) => b.parentId - a.parentId);
  const handlerGetAllConfirmedOrders = useCallback((responseData) => {
    setOrders(responseData?.data);
    classifyOrders(responseData?.data);
    setInvoiceDetails(responseData?.amounts);
  }, []);

  const loadAllConfirmedOrders = () => {
    let getAllConfirmedOrdersUrl = {
      type: "GET",
      endpoint: "/orders/users/current/completed",
    };



    getAllConfirmedOrders.sendRequest(
      getAllConfirmedOrdersUrl,
      handlerGetAllConfirmedOrders,
      {}
    );
  };

  let ords = {}

  orders?.map(item => {
    // console.log(item?.parentId in ords);
    if (item?.parentId in ords) {
      ords[item?.parentId].items?.push(item);
      ords[item?.parentId].sts?.push(item?.status);


    }
    else {
      ords = {
        ...ords, [item?.parentId]: {
          orderTime: item?.createdAt,
          items: [item],
          sts: [item?.status],
          name: item?.restaurantItem?.category?.restaurent?.name || item?.houseKeepingItem?.category?.serviceProvider?.name,
          image: item?.restaurantItem?.category?.restaurent?.image || item?.houseKeepingItem?.category?.serviceProvider?.image,
          parentId: item?.parentId,

        }
      }


    }
  })

  // Segregate status array as one status for Order
  const latestCurrentOrders = Object.values(ords).map(obj => {
    const items = obj.items;
    const statuses = items.map(item => item.status);

    let displayStatus = '';
    let displayType = 'current'

    if (statuses.every(status => status === 'Rejected')) {
      displayStatus = 'cancelled';
      displayType = 'completed'
    } else if (statuses.includes('Completed')) {
      displayStatus = 'completed';
      displayType = 'completed';
    } else if (statuses.includes('Accepted')) {
      displayStatus = 'accepted';
    } else if (statuses.includes('Pending')) {
      displayStatus = 'pending';
    }



    return { ...obj, displayStatus, displayType };
  });

  const completedOrderList = latestCurrentOrders?.filter(ele => ele?.displayType === 'completed')
  const currentOrderList = latestCurrentOrders?.filter(ele => ele?.displayType === 'current')

  // console.log(latestCurrentOrders);
  // console.log(completedOrderList, 'compl');
  // console.log(currentOrderList, 'current');




  // let completedOrdersObjs = {}

  // completedOrders.map(item => {
  //   // console.log(item?.parentId in ords);
  //   if (item?.parentId in completedOrdersObjs) {
  //     completedOrdersObjs[item?.parentId].items?.push(item);
  //     completedOrdersObjs[item?.parentId].sts?.push(item?.status);

  //   }
  //   else {
  //     completedOrdersObjs = {
  //       ...completedOrdersObjs, [item?.parentId]: {
  //         orderTime: item?.createdAt,
  //         items: [item],
  //         sts: [item?.status],
  //         name: item?.restaurantItem?.category?.restaurent?.name || item?.houseKeepingItem?.category?.serviceProvider?.name,
  //         image: item?.restaurantItem?.category?.restaurent?.image || item?.houseKeepingItem?.category?.serviceProvider?.image,
  //         parentId: item?.parentId,

  //       }
  //     }


  //   }
  // })

  // // Segregate status array as one status for Order
  // const latestCompletedOrders = Object.values(completedOrdersObjs).map(obj => {
  //   const items = obj.items;
  //   const statuses = items.map(item => item.status);



  //   let displayStatus = '';

  //   if (statuses.every(status => status === 'Rejected')) {
  //     displayStatus = 'cancelled';
  //   } else if (statuses.includes('Completed')) {
  //     displayStatus = 'completed';
  //   } else if (statuses.includes('Accepted')) {
  //     displayStatus = 'accepted';
  //   } else if (statuses.includes('Pending')) {
  //     displayStatus = 'pending';
  //   }
  //   // const ans = { ...obj, sts }



  //   return { ...obj, displayStatus };
  // });




  useEffect(() => {
    loadAllConfirmedOrders();
  }, []);


  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.whiteColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
    >
      <Box px={2}>
        <OrderHistoryHeader />
      </Box>

      <Box px={2} pb={2} flex="1" bgcolor="#fff" borderRadius="0px 0px 0 0">
        <PageHeading mt={2.5} mb={2.5}>Billing Amount</PageHeading>
        <ProductPaper sx={{ mt: 2.5 }}>
          <Stack direction="row" justifyContent="space-between">
            <p style={{ fontSize: '14px', marginTop: '2px', fontWeight: '600', color: '#757575' }}>Grand total</p>
            <p style={{ fontSize: '14px', fontWeight: '600' }} sx={{ mt: 0 }}>
              {currencySymbol}  {(+invoiceDetails?.pendingAmount) + (+invoiceDetails?.paidAmount)}
            </p>
          </Stack>
          <Stack direction="row" sx={{ mt: 1 }} justifyContent="space-between">
            <p style={{ fontSize: '14px', fontWeight: '600', color: '#757575' }}>Paid Amount</p>
            <p style={{ fontSize: '14px', color: '#32842B', fontWeight: '600' }} sx={{ mt: 0 }}>
              - {currencySymbol}  {invoiceDetails?.paidAmount}
            </p>
          </Stack>
          <div className="dash-border" style={{ margin: '10px 0px' }} />
          <Stack direction='row' justifyContent="space-between">
            <p style={{ fontSize: '16px', fontWeight: '700', color: '#757575' }}>Balance Due Amount</p>
            <p style={{ fontSize: '16px', fontWeight: '700' }} sx={{ mt: 0 }}>
              {currencySymbol}  {invoiceDetails?.pendingAmount}
            </p>

          </Stack>
        </ProductPaper>

        <PageHeading mt={2.5} mb={2.5}>Current Orders</PageHeading>
        {getAllConfirmedOrders.isLoading && (
          <Stack
            spacing={3}
            mt={2.5}
            p={10}
            sx={{ background: "#EDEDED", borderRadius: 2 }}
          >
            <PageSubHeading sx={{ textAlign: "center" }}>
              <CircularProgress
                style={{
                  color: HCTheme.blackColorHC,
                  width: "24px",
                  height: "24px",
                }}
              />
            </PageSubHeading>
          </Stack>
        )}
        {!getAllConfirmedOrders.isLoading && (
          <>
            {currentOrderList.length === 0 ? (
              <Stack
                spacing={0}
                mt={2.5}
                p={10}
                sx={{ background: "#EDEDED", borderRadius: 2 }}
              >
                <PageSubHeading sx={{ textAlign: "center" }}>
                  No orders found.
                </PageSubHeading>
              </Stack>
            ) : (
              <Stack spacing={2} className="track-order-items" sx={{ pb: 0.5 }}>
                {currentOrderList &&
                  currentOrderList?.map((order, index) => {
                    return (
                      <div key={order?.id} style={{ margin: '15px 0px' }} >
                        <Badge badgeContent={order?.displayStatus === 'accepted' ? 'Accepted' : null} color="success" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
                        </Badge>
                        <Badge badgeContent={order?.displayStatus === 'pending' ? 'Place Order' : null} color="success" sx={{ mt: 0, mr: 4.5, width: 200 }} style={{ float: 'right' }}>
                        </Badge>
                        <Badge badgeContent={order?.displayStatus === 'completed' ? 'Completed' : null} color="success" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
                        </Badge>
                        <Badge badgeContent={order?.displayStatus === 'cancelled' ? 'Cancelled' : null} color="error" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
                        </Badge>
                        <OrderList status={order?.displayStatus} order={order} />
                        {/* {(index == 0 ||
                          currentOrders[index - 1].parentId !=
                          order.parentId) && <OrderList order={order} />} */}

                        {/* {order?.restaurantItemId && (
                          <OrderHistoryRestaurantProduct order={order} />
                        )} */}
                        {/* {order?.houseKeepingItemId && (
                          <OrderHistoryRoomServiceProduct
                            key={order?.id}
                            order={order}
                          />
                        )} */}
                      </div>
                    );
                  })}
              </Stack>
            )}
          </>
        )}

        <PageHeading mt={4}>Completed Orders</PageHeading>

        {getAllConfirmedOrders.isLoading && (
          <Stack
            spacing={3}
            mt={2.5}
            p={10}
            sx={{ background: "#EDEDED", borderRadius: 2 }}
          >
            <PageSubHeading sx={{ textAlign: "center" }}>
              <CircularProgress
                style={{
                  color: HCTheme.blackColorHC,
                  width: "24px",
                  height: "24px",
                }}
              />
            </PageSubHeading>
          </Stack>
        )}

        {!getAllConfirmedOrders.isLoading && (
          <>
            {completedOrderList.length === 0 ? (
              <Stack
                spacing={3}
                mt={2.5}
                p={10}
                sx={{ background: "#EDEDED", borderRadius: 2 }}
              >
                <PageSubHeading sx={{ textAlign: "center" }}>
                  No orders found.
                </PageSubHeading>
              </Stack>
            ) : (
              <Stack spacing={2} className="track-order-items" sx={{ pb: 0.5 }}>
                {completedOrderList &&
                  completedOrderList?.map((order, index) => {
                    return (
                      <div key={order?.id} style={{ margin: '15px 0px' }} >
                        <Badge badgeContent={order?.displayStatus === 'accepted' ? 'Accepted' : null} color="success" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
                        </Badge>
                        <Badge badgeContent={order?.displayStatus === 'pending' ? 'Place Order' : null} color="success" sx={{ mt: 0, mr: 4.5, width: 200 }} style={{ float: 'right' }}>
                        </Badge>
                        <Badge badgeContent={order?.displayStatus === 'completed' ? 'Completed' : null} color="success" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
                        </Badge>
                        <Badge badgeContent={order?.displayStatus === 'cancelled' ? 'Cancelled' : null} color="error" sx={{ mt: 0, mr: 4 }} style={{ float: 'right' }}>
                        </Badge>
                        <OrderList status={order?.displayStatus} order={order} />

                      </div>
                    );
                  })}

              </Stack>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default OrderHistory;
