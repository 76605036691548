import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RestaurantHeader from "../../components/Restaurant/RestaurantHeader";
import useHttp from "../../Hooks/use-http";
import CONSTANT from "../../utils/constant";
import { HCTheme } from "../../utils/theme";
import ServiceItemDrawer from "./ServiceItemDrawer";
import ServiceHeader from "./ServiceHeader";

const ProviderDetails = ({ roomNum }) => {
    const navigate = useNavigate();
    const getBadgeData = useHttp();
    const [badgeData, setBadgeData] = useState({});
    const [serviceProviderDetails, setServiceProviderDetails] = useState({})

    const { id } = useParams()

    const handlerGetBadgeData = useCallback((responseData) => {
        setBadgeData(responseData?.data?.track);
    }, []);

    const loadBadgeData = () => {
        getBadgeData.sendRequest(
            CONSTANT.URLS.getBadgeData,
            handlerGetBadgeData,
            {}
        );
    };

    const handlerChangeCart = () => {
        loadBadgeData();
    };

    useEffect(() => {
        loadBadgeData();
        const GET_SERVICE_PROVIDER_API = { ...CONSTANT.URLS.getServiceProvider }

        getBadgeData.sendRequest(GET_SERVICE_PROVIDER_API, (res) => {
            setServiceProviderDetails(res?.data?.serviceProviders?.filter(ele => ele?.id === +id))
        })
    }, []);
    return (
        <Container
            maxWidth="sm"
            sx={{
                minHeight: "100vh",
                background: HCTheme.blackColorHC,
                display: "flex",
                flexDirection: "column",
                p: 0,
            }}
            id="cntr"
        >
            <Box>
                <ServiceHeader
                    title={serviceProviderDetails[0]?.name}


                    roomNum={roomNum}
                    navigatePath="/room-services"
                    onClickCartIcon={() => {
                        navigate("/pre-cart?section=houseKeeping", {
                            state: { title: "My Cart" },
                        });
                    }}
                    // onClickoOrderHistoryIcon={() => {
                    //     navigate(`/order-history?section=houseKeeping`);
                    // }}
                    showIcons
                    badgeData={badgeData}
                />
            </Box>
            <ServiceItemDrawer onChangeCart={handlerChangeCart} />
        </Container>
    );
};

export default ProviderDetails;
