import { Box, Container, Stack } from "@mui/system";
import { Fragment } from "react";
import { HCTheme } from "../../utils/theme";
import {
  ButtonQR,
  PageSubHeading,
  WelcomeHeading,
} from "../styled/PageSubHeading";
import TrackOrderSVG from "../track-order/TrackOrderSVG";

const SuccessMessage = ({ hotelName }) => {
  return (
    <Fragment>
      <Container
        maxWidth="sm"
        disableGutters={true}
        sx={{
          position: "relative",
          minHeight: window.innerHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "strach",
          overflow: "hidden",
          bgcolor: HCTheme.whiteColorHC,
        }}
      >
        <Stack
          sx={{
            minHeight: "100vh",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TrackOrderSVG />
          </Box>
          <Box>
            <Box
              sx={{
                textAlign: "center",
                my: 8,
              }}
            >
              <WelcomeHeading style={{ fontStyle: "unset" }}>
                Welcome to
              </WelcomeHeading>
              <PageSubHeading sx={{ mt: 1.5 }}>{hotelName}</PageSubHeading>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                my: 8,
              }}
            >
              <a href="/home" className="">
                <ButtonQR sx={{ textTransform: "capitalize" }}>
                  Go to Home
                </ButtonQR>
              </a>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Fragment>
  );
};

export default SuccessMessage;
