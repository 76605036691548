import moment from "moment/moment";
import { toast } from "react-toastify";
import { Token } from "./constant";

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const notify = {
  success: (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error: (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};

export const calcCart = (cartItems) => {
  let totalItem = 0;
  let totalAmount = 0;

  cartItems.map((cartItem) => {
    totalItem += +cartItem.quantity;
    totalAmount +=
      +cartItem.quantity *
      (cartItem?.restaurantItemId
        ? +cartItem?.restaurantItem?.price
        : +cartItem?.houseKeepingItem?.price);
  });

  return {
    totalItem,
    totalAmount,
  };
};

export const calcCartHousekeeping = (updatedItems) => {
  let totalItem = 0;
  let totalAmount = 0;

  updatedItems.map((housekeepingItem) => {
    totalItem += +housekeepingItem.quantity;
    totalAmount += +housekeepingItem.quantity * +housekeepingItem.price;
  });

  return {
    totalItem,
    totalAmount,
  };
};

export const calcOrders = (orders) => {
  let totalItem = 0;
  let totalAmount = 0;

  orders.map((order) => {
    if (order?.restaurantItemId || order?.houseKeepingItemId) {
      totalItem += order?.quantity;
      totalAmount +=
        order?.quantity *
        (order?.restaurantItemId ? order?.restaurantItem?.price : 0);
    }
  });

  return {
    totalItem,
    totalAmount,
  };
};

let rotation = 0;

export const implementRotation = () => {
  let init,
    rotate,
    start,
    stop,
    active = false,
    angle = 0,
    startAngle = 0,
    center = { x: 0, y: 0 },
    R2D = 180 / Math.PI,
    rot = document.getElementById("rotate");

  init = function () {
    // for mobile
    rot.addEventListener("touchstart", start, false);
    document.addEventListener("touchmove", (event) => {
      if (active === true) {
        rotate(event);
      }
    });
    document.addEventListener("touchend", (event) => {
      stop(event);
    });
  };
  start = function (e) {
    e.preventDefault();
    let bb = this.getBoundingClientRect(),
      t = bb.top,
      l = bb.left,
      h = bb.height,
      w = bb.width,
      x,
      y;
    center = { x: l + w / 2, y: t + h / 2 };
    x = e.clientX - center.x || e.touches[0].clientX - center.x;
    y = e.clientY - center.y || e.touches[0].clientY - center.y;

    startAngle = R2D * Math.atan2(y, x);

    active = true;

    return active;
  };
  rotate = function (e) {
    let x, y, d, rotation;
    x = e.clientX - center.x || e.touches[0].clientX - center.x;
    y = e.clientY - center.y || e.touches[0].clientY - center.y;

    d = R2D * Math.atan2(y, x);
    rotation = d - startAngle;
  };
  stop = function () {
    return (active = false);
  };
  init();
};

export const filterItems = (array, query) => {

  let filteredArray = array
    .filter((element) =>
      element?.items?.some((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      )
    )
    .map((element) => {
      let n = Object.assign({}, element, {
        items: element.items.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        ),
      });
      return n;
    });

  return filteredArray;
};

export const filterService = (array, query) => {
  let filteredArray = array
    .filter((element) => element?.name.toLowerCase().includes(query.toLowerCase())
      // element?.some((item) =>
      //   item.name.toLowerCase().includes(query.toLowerCase())
      // )
    )


  // .map((element) => {
  //   let n = Object.assign({}, element, {
  //     items: element.items.filter((item) =>
  //       item.name.toLowerCase().includes(query.toLowerCase())
  //     ),
  //   });
  //   return n;
  // });

  return filteredArray;
};

export const formatAMPM = (date_) => {
  let date = new Date();

  let [h, m] = date_.split(":");

  date.setHours(+h);
  date.setMinutes(m);

  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const getToken = () => {

  return localStorage.getItem("authToken");
};

export const formatDateCompletedOrder = (date_) => {
  let completedAt = new Date(date_);

  let strTime = `Completed at : ${moment(completedAt).format(
    "DD MMM hh:mm A"
  )}`;
  return strTime;
};

export const formatDateConfirmedOrder = (date_) => {
  let confirmedAt = new Date(date_);

  let strTime = `Confirmed at : ${moment(confirmedAt).format(
    "DD MMM hh:mm A"
  )}`;
  return strTime;
};

export const getHeaderLogoHight = (deviceHeight) => {
  if (deviceHeight < 700) {
    return "90px";
  } else {
    return "120px";
  }
};

export const getHeaderLogoMargin = (deviceHeight) => {
  if (deviceHeight < 700) {
    return "16px";
  } else {
    return "16px";
  }
};

export const getHeaderLineDetails = (deviceHeight) => {
  if (deviceHeight < 700) {
    return { marginTop: "0px", fontSize: "22px" };
  } else {
    return { marginTop: "24px", fontSize: "25px" };
  }
};

export const detectScrollOnList = (
  to,
  from,
  previousState,
  isScrolled = false
) => {
  if (to - from > 40 && !isScrolled) {
    return false;
  } else if (to - from < -40) {
    return true;
  }
  return previousState;
};



export const getDateTime = (value, offSet, format) => {
  if (offSet) {
    return moment.utc(value).utcOffset(offSet?.split('UTC')[1]).format(format)
  }

  // return moment(value).format('DD/MM/YYYY hh:mm:ss A')
}