import { Navigate, Route, Routes } from "react-router-dom";
import AuthFallback from "../general/AuthFallback";
import SignInWithQRCode from "../general/SignInWithQRCode";
import SignInWithURL from "../general/SignInWithURL";
import SuccessMessage from "../general/SuccessMessage";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/signin-with-url/:token" element={<SignInWithURL />} />
      <Route path="/signin-with-qrcode" element={<SignInWithQRCode />} />
      <Route path="/auth-fallback" element={<AuthFallback />} />
      <Route path="/success-message" element={<SuccessMessage />} />
      <Route path="/*" element={<Navigate to="/auth-fallback" />} />
    </Routes>
  );
};

export default PublicRoutes;
