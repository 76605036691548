const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#0C0F0A",
    },
    secondary: {
      main: "#F1F1F1",
    },
    background: {
      default: "#f9f9f9",
      paper: "#EDEDED",
    },
    text: {
      primary: "#0c0f0a",
      secondary: "#f1f1f1",
    },
  },
  typography: {
    fontFamily: "Satoshi",
  },
};
export default themeOptions;

export const HCTheme = {
  circularMenuOuterBackground: "#D9D9D9",
  circularMenuInnerBackground: "#272125",
  whiteColorHC: "#F1F1F1",
  blackColorHC: "#0C0F0A",
};
