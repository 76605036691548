import { Box, Stack } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import useOnScreen from "../../Hooks/use-visible";
import Products from "../Restaurant/Products";
import { PageSubHeading } from "../styled/PageSubHeading";
import close from '../../assets/svg/close.svg';
import open from '../../assets/svg/open.svg';

const CatSec = ({
  category,
  onIncrement,
  onDecrement,
  onUpdateVisibility,
  myCart,
}) => {
  const catSecRef = useRef(null);
  const isVisible = useOnScreen(catSecRef);
  const [isItemsOpen, setIsItemsOpen] = useState(true)

  useEffect(() => {
    onUpdateVisibility(category?.id, isVisible);
  }, [isVisible]);

  return (
    <Box className="catSec overFlowScroll" id={category?.id} ref={catSecRef}>
      <Fragment>
        <Stack flex='1' sx={{ mr: 2 }} direction='row' justifyContent="space-between" >
          <PageSubHeading id={category?.id} sx={{ mt: 2.5, mb: 2 }}>
            {category?.categoryName}
          </PageSubHeading>
          <div style={{
            marginTop: '20px',
            cursor: 'pointer'
          }} onClick={() => {
            setIsItemsOpen(prev => !prev)
          }}>
            <img src={isItemsOpen ? close : open} alt="close" style={{ width: '15px' }} />
          </div>
        </Stack>
        {isItemsOpen ? <Products
          items={category?.items}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          myCart={myCart}
        /> : <div></div>}
      </Fragment>
    </Box>
  );
};

export default CatSec;
