import { Avatar, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useEffect } from "react";
import useHttp from "../../Hooks/use-http";
import CONSTANT from "../../utils/constant";
import DrawerComponent from "../general/DrawerComponent";
import GeneralHotelDetails from "../HotelDetails/GeneralHotelDetails";
import TimingsHotelDetails from "../HotelDetails/TimingsHotelDetails";
import WifiHotelDetails from "../HotelDetails/WifiHotelDetails";
import { ChipHC } from "../styled/Chip";
import LayoutComponent from "../general/LayoutComponent";
import timer from '../../assets/svg/timer.svg'
import general from '../../assets/svg/general.svg'
import { PolicyLinkNew } from "../styled/HotelDetails";

export default function NewHotelDetailsDrawer(props) {
  const getHotelsDetails = useHttp();
  const [curView, setCurView] = React.useState(0);
  const [hotelDetails, setHotelDetails] = React.useState({});
  const handlerGetHotelDetails = React.useCallback((responseData) => {

    setHotelDetails(responseData?.data);
  }, []);

  const loadHotelsDetails = () => {
    getHotelsDetails.sendRequest(
      CONSTANT.URLS.getHotelsDetails,
      handlerGetHotelDetails,
      {}
    );
  };
  // console.log(hotelDetails);
  useEffect(() => {
    loadHotelsDetails();
  }, []);

  const HeaderCategory = [

    {
      id: 1,
      label: "Timings",
      color: "primary",
      image: timer

    },
    {
      id: 2,
      label: "General",
      color: "primary",
      image: general
    },
  ];
  const HeaderItems = [
    // {
    //   id: 1,
    //   label: "Wifi",
    //   color: "primary",

    //   body: (
    //     <WifiHotelDetails
    //       name={hotelDetails?.wifiName}
    //       password={hotelDetails?.wifiPassword}
    //     />
    //   ),
    // },
    {
      id: 2,
      label: "Timings",
      color: "primary",
      body: (
        <TimingsHotelDetails
          restaurantTimings={hotelDetails?.restaurantTime}
          specialServiceTimings={hotelDetails?.specialService}
        />
      ),
    },
    {
      id: 3,
      label: "General",
      color: "primary",
      body: <GeneralHotelDetails policy={hotelDetails?.policy} />,
    },
  ];

  return (
    <Box>
      <LayoutComponent isSearchable={false}>

        <Stack
          direction="row"
          spacing={1.25}
          sx={{ overflow: "scroll", mt: 2, mb: 0.2 }}
        >
          {/* {HeaderCategory.map((HeaderItem, index) => (
            <ChipHC
              key={`GeneralHeading${HeaderItem.id}`}
              {...HeaderItem}
              variant={curView == index ? "contained" : "outlined"}
              onClick={() => {
                setCurView(index);
              }}
            />
          ))} */}
          {
            HeaderCategory.map((category, index) => (
              <>
                <a
                  href={`#${category?.id}`}
                  key={category?.id}
                  className="category-link"
                  style={{ maxWidth: '90px' }}
                >
                  <ChipHC

                    style={{
                      width: '90px',
                      height: '90px',



                    }}
                    avatar={<Avatar variant="" alt="Natacha" style={{
                      width: '70px',
                      height: '70px',
                      marginRight: '-19px'
                    }} src={category?.image} />}
                    color="primary"
                    variant={curView == index ? "contained" : "outlined"}
                    onClick={() => {
                      setCurView(index);
                    }}
                  />
                  <p style={{ fontSize: '14px', fontWeight: 700, textAlign: 'center' }}>{category?.label}</p>
                </a>
              </>
            ))
          }
        </Stack>
        {HeaderItems[curView].body}

      </LayoutComponent>
    </Box>
  );
}
