import { Global } from "@emotion/react";
import { CircularProgress, Rating } from "@mui/material";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PropTypes from "prop-types";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import CONSTANT from "../../utils/constant";
import { HCTheme } from "../../utils/theme";
import { ButtonHC } from "../styled/Button";
import { FeedbackInfoText, FeedbackQastion } from "../styled/feedback";
import { TextArea } from "../styled/TextArea";

import rating_1 from "./../../assets/svg/rating_1.svg";
import rating_2 from "./../../assets/svg/rating_2.svg";
import rating_3 from "./../../assets/svg/rating_3.svg";
import rating_4 from "./../../assets/svg/rating_4.svg";
import rating_5 from "./../../assets/svg/rating_5.svg";

const drawerBleeding = 24;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 50,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 25px)",
}));

function FeedbackDrawer(props) {
  const [rating, setRating] = useState(5);
  const [feedbackDesc, setFeedbackDesc] = useState("");
  const navigate = useNavigate();

  // console.log(rating);
  const customIcons = {
    1: {
      icon: (
        <img
          src={rating_1}
          alt=""
          className={`${rating < 1 && rating !== null ? "filter-grayscale" : ""
            } ${rating === 1 ? "enlarge_120" : ""}`}
        />
      ),
      label: "Very Dissatisfied",
    },
    2: {
      icon: (
        <img
          src={rating_2}
          alt=""
          className={`${rating < 2 && rating !== null ? "filter-grayscale" : ""
            } ${rating == 2 ? "enlarge_120" : ""}`}
        />
      ),
      label: "Dissatisfied",
    },
    3: {
      icon: (
        <img
          src={rating_3}
          alt=""
          className={`${rating < 3 && rating !== null ? "filter-grayscale" : ""
            } ${rating == 3 ? "enlarge_120" : ""}`}
        />
      ),
      label: "Neutral",
    },
    4: {
      icon: (
        <img
          src={rating_4}
          alt=""
          className={`${rating < 4 && rating !== null ? "filter-grayscale" : ""
            } ${rating == 4 ? "enlarge_120" : ""}`}
        />
      ),
      label: "Satisfied",
    },
    5: {
      icon: (
        <img
          src={rating_5}
          alt=""
          className={`${rating < 5 ? "filter-grayscale" : ""
            } ${rating == 5 ? "enlarge_120" : ""}`}
        />
      ),
      label: "Very Satisfied",
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const submitFeedback = useHttp();

  const { window } = props;
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handlersubmitFeedbackResponse = React.useCallback((responseData) => {
    navigate("/call-services");
  }, []);

  const handlerSubmitFeedback = () => {
    const payload = {
      rating: rating,
      feedback: feedbackDesc,
    };
    submitFeedback.sendRequest(
      CONSTANT.URLS.submitFeedback,
      handlersubmitFeedbackResponse,
      payload,
      "Feedback submited successfully."
    );
  };

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open="true"
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Box sx={{ p: 1.5 }}></Box>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            py: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box>
            <FeedbackQastion>
              How satisfied are you with our services?
            </FeedbackQastion>
            <Box my={2.5} display="flex">
              <StyledRating
                name="highlight-selected-only"
                value={rating}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly
                onChange={(event, newRating) => {
                  // console.log(newRating);

                  if (newRating) {
                    setRating(newRating);
                  }
                }}
                className="rating_hc"
              />
            </Box>
            <FeedbackQastion>
              Share your thoughts and suggestions with us.
            </FeedbackQastion>
            <FeedbackInfoText sx={{ mt: 0.5 }}>
              Tell us about any service that you would like to be improved in
              the future.
            </FeedbackInfoText>
            <TextArea
              sx={{ mt: 1.25, p: 1, color: HCTheme.blackColorHC }}
              placeholder="Type your thoughts"
              fullWidth
              multiline
              minRows="6"
              onChange={(e) => setFeedbackDesc(e.target.value)}
            ></TextArea>
            <ButtonHC
              variant="contained"
              fullWidth
              sx={{ textTransform: "capitalize", mt: 1.25, py: 1.5 }}
              onClick={handlerSubmitFeedback}
              disabled={submitFeedback.isLoading}
            >
              {submitFeedback.isLoading && (
                <CircularProgress
                  size={22}
                  sx={{
                    color: HCTheme.whiteColorHC,
                    mr: 2,

                  }}
                />
              )}
              Submit
            </ButtonHC>
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

FeedbackDrawer.propTypes = {
  window: PropTypes.func,
};

export default FeedbackDrawer;
