import { InputBase } from "@mui/material";
import { styled } from "@mui/system";

export const SearchBox = styled(InputBase)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "flex-end",
  color: "#727272",
  background: "#F9F9F9",
  border: "1px solid #727272",
  borderRadius: "8px",
  padding: "4px 10px",
  "&:hover": {
    boxShadow: "none",
    outline: "2px solid #727272",
  },
  "&:active": {
    boxShadow: "none",
    outline: "2px solid #727272",
  },
  "&:focus": {
    boxShadow: "none",
    outline: "2px solid #727272",
  },
}));
