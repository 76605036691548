import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HCTheme } from "../../utils/theme";
import { OrderHistorySecondaryText } from "../styled/OrderHistory";
import { TrackOrderConfirmedText } from "../styled/TrackOrder";

const OrderHistoryHeader = () => {
  let navigate = useNavigate();
  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <IconButton
          aria-label="back"
          sx={{ p: 0, mt: 2.5 }}
          disableRipple
          onClick={() => navigate("/home")}
        >
          <ArrowBackIcon sx={{ color: HCTheme.blackColorHC }} />
        </IconButton>
        <TrackOrderConfirmedText sx={{ mt: 2.5, ml: 1.25 }}>
          Orders
        </TrackOrderConfirmedText>
      </Stack>
      <Box mt={0.75} mb={2.5} ml={4}>
        <OrderHistorySecondaryText>
          Keep yourself posted with the status of your orders
        </OrderHistorySecondaryText>
      </Box>
    </Stack>
  );
};

export default OrderHistoryHeader;
