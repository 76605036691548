import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { HCTheme } from "../../../utils/theme";

export const ProductPaper = styled(Paper)(
  ({ theme, isRejected, isUnAvailable }) => ({
    borderRadius: theme.spacing(1),
    border: "1px solid #D9D9D9",
    padding: theme.spacing(1.25),
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    position: "relative",
    background:
      isRejected || isUnAvailable ? "#D9D9D9" : theme.palette.background.paper,
  })
);

export const ProductTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "22px",
  color: HCTheme.blackColorHC,
}));
export const ProductDescription = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontSize: "14px",
  lineHeight: "22px",
  color: HCTheme.blackColorHC,
  display: '-webkit-box',
  maxWidth: '200px',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',




}));

export const ProductPrice = styled(Typography)(({ theme, color }) => ({
  fontFamily: "Satoshi-Variable",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "22px",
  whiteSpace: "no-wrap",
  color: color || theme.palette.primary.main,
}));

export const ProductTime = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#AEAFAF",
}));
