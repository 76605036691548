import axios from "axios";
import { getToken } from "../utils/funcs";
import CONSTANT from "./../utils/constant";

export const Services = axios.create({
  baseURL: CONSTANT.baseUrl,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + getToken(),
  },
});
