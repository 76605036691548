import { Box } from "@mui/material";
import { Container } from "@mui/system";
import NewHotelDetailsDrawer from "../components/Restaurant/NewHotelDetailsDrawer";

import RestaurantHeader from "../components/Restaurant/RestaurantHeader";
import { HCTheme } from "../utils/theme";
import WifiHotelDetails from "../components/HotelDetails/WifiHotelDetails";

const HotelDetails = ({ roomNum }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.blackColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
      id="cntr"
    >
      <Box>

        <RestaurantHeader
          roomNum={roomNum}
          title={'Hotel Details'}
          navigatePath="/home"
        />
      </Box>
      <NewHotelDetailsDrawer />
    </Container>
  );
};

export default HotelDetails;
