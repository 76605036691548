import { Box } from "@mui/material";

const Guide = ({ guide }) => {
  return (
    <Box>
      <video width="100%" className="device-guide-video" controls>
        <source src={guide?.video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default Guide;
