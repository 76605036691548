import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Avatar, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { orderActions } from "../../store/order-slice";
import { ButtonHC } from "../styled/Button";
import {
  ProductPaper,
  ProductPrice,
  ProductTime,
  ProductTitle,
} from "../styled/ProductBox";
import veg from '../../assets/svg/veg.svg'
import nonVeg from '../../assets/svg/nonVeg.svg'



const OrderHistoryRestaurantProduct = ({ order, setPrice }) => {
  const dispatch = useDispatch();
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  // console.log(order);

  return (
    <>
      <div>

        <Stack direction="row" justifyContent="flex-start" sx={{ ml: 0, mt: 1 }}>
          <img src={order?.restaurantItem?.isVeg ? veg : nonVeg} alt='veg' width={20} height={20} />
          <Avatar
            variant="rounded"
            alt="Remy Sharp"
            src={order?.restaurantItem?.image}
            sx={{ width: 20, height: 20, mr: 1, ml: 1 }}
          />
          <Stack flex="1">
            <>
              <Stack direction='row' justifyContent="space-between">
                <p style={{ fontSize: '12px', marginTop: '2px', fontWeight: '700' }}> <span style={{ color: '#757575', fontWeight: '700' }}>{order?.quantity} X </span> {order?.restaurantItem?.name}</p>
                <ProductPrice color={order?.status === 'Rejected' ? '#CD0000' : '#32842B'} sx={{ mt: 0 }}>
                  {currencySymbol} {(+order?.itemAmount + +order?.taxAmount)}
                </ProductPrice>

              </Stack>
            </>
          </Stack>

        </Stack>

      </div>

    </>
  );
};

export default OrderHistoryRestaurantProduct;
