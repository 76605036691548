import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import NewHotelDetailsDrawer from "../components/DevicesGuide/NewDeviceGuideDrawer";
import RestaurantHeader from "../components/Restaurant/RestaurantHeader";
import { HCTheme } from "../utils/theme";

const DevicesGuide = ({ roomNum }) => {
  const [open, setOpen] = useState(true);

  const handlerOpenDrawer = () => {
    setOpen(true);
  };
  const handlerCloseDrawer = () => {
    setOpen(false);
  };
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.blackColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
      id="cntr"
    >
      <Box>
        <RestaurantHeader
          roomNum={roomNum}
          title={'Video'}
          navigatePath="/home"
          open={open}
        />
      </Box>
      <NewHotelDetailsDrawer
        onOpenDrawer={handlerOpenDrawer}
        onCloseDrawer={handlerCloseDrawer}
        open={open}
      />
    </Container>
  );
};

export default DevicesGuide;
