import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { PageSubHeading } from "../styled/PageSubHeading";

const GeneralHotelDetails = ({ policy }) => {

  return policy ? (
    <>
      <Box style={{ height: "100%" }}>
        <div><div dangerouslySetInnerHTML={{ __html: policy }} /></div>
      </Box>
    </>
  ) : (
    <>
      <Stack
        spacing={3}
        p={10}
        mt={2}
        sx={{ background: "#DEDEDE", borderRadius: 2 }}
      >
        <PageSubHeading sx={{ textAlign: "center" }}>
          No Policy found.
        </PageSubHeading>
      </Stack>
    </>
  );
};

export default GeneralHotelDetails;
