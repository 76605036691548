import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OrderIHistoryCompletedHouseKeepingItem from "../components/general/OrderIHistoryCompletedHouseKeepingItem";
import OrderIHistoryCompletedResraurantItem from "../components/general/OrderIHistoryCompletedResraurantItem";
import { ButtonHC } from "../components/styled/Button";
import { PageSubHeading } from "../components/styled/PageSubHeading";
import TrackOrderHeader from "../components/track-order/TrackOrderHeader";
import TrackOrderTimeline from "../components/track-order/TrackOrderTimeline";
import { HCTheme } from "../utils/theme";
import { TextArea } from "../components/styled/TextArea";
import useHttp from "../Hooks/use-http";
import CONSTANT from "../utils/constant";
import OrderList from "../components/general/OrderList";

const orderStatusIndex = {
  Completed: 1,
  Accepted: 2,
  Pending: 3,
  Rejected: 4,
}

const TrackOrder = ({ hotelName }) => {
  const [ordersList, setOrdersList] = useState([]);
  const navigate = useNavigate();
  const { orderId } = useParams()
  const location = useLocation();
  const api = useHttp()
  const [type, setType] = useState(null)
  const curOrder = useSelector((state) => state.order.order);
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");


  // console.log(ordersList);

  let price = 0
  ordersList?.forEach(ele => {
    // console.log(ele);
    if (ele?.status !== "Rejected") { price = price + +ele?.itemAmount + +ele?.taxAmount }

  })

  // console.log(sum, 'price');


  const allCancelled = ordersList?.every(item => item.status === 'Rejected');
  // Check if at least one status is 'pending', or 'accepted'
  const hasPending = ordersList?.some(item => item.status === 'Pending');
  const hasAccepted = ordersList?.some(item => item.status === 'Accepted');
  const hasCompleted = ordersList?.some(item => item.status === 'Completed');

  let displayStatus = '';

  if (allCancelled) {
    displayStatus = 'Cancelled';
  } else if (hasCompleted) {
    displayStatus = 'Completed';
  } else if (hasAccepted) {
    displayStatus = 'Accepted';
  } else if (hasPending) {
    displayStatus = 'Pending';
  }

  // console.log(displayStatus);
  // console.log(ordersList);

  const itemType = location.state?.itemType;

  // if (Object.keys(curOrder).length === 0) {
  //   navigate("/order-history");
  // }





  useEffect(() => {
    const ORDERS_LIST_API = { ...CONSTANT.URLS.getOrdersByParentId }
    ORDERS_LIST_API.endpoint = `${ORDERS_LIST_API.endpoint}?parentId=${orderId}`

    api.sendRequest(ORDERS_LIST_API, (res) => {
      setOrdersList(res?.data?.orders.sort((a, b) => orderStatusIndex[a?.status] - orderStatusIndex[b?.status]));
    })
  }, [])

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.whiteColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
    >
      <Box px={2}>
        <TrackOrderHeader
          orderId={curOrder?.id}
          curOrder={curOrder}
          itemType={itemType}
          status={displayStatus}
        />
      </Box>
      <Box px={2} flex="0" bgcolor="#fff" borderRadius="8px 8px 0 0">
        <Stack direction="row" justifyContent='space-between'>
          <Stack>
            <PageSubHeading mt={2.5}>Order #{ordersList[0]?.parentId}</PageSubHeading>
            <PageSubHeading sx={{ fontSize: '12px', color: '#757575' }} ml={0.5} >{ordersList[0]?.restaurantItem?.category?.restaurent?.name || ordersList[0]?.houseKeepingItem?.category?.serviceProvider?.name}</PageSubHeading>
          </Stack>
          {displayStatus !== 'Cancelled' && <PageSubHeading mt={4}>Total - <span style={{ color: "#32842B" }}>{currencySymbol} {price}</span></PageSubHeading>}
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          className="track-order-items"
          sx={{ mt: 1, pb: 0.5 }}
        >

          <OrderIHistoryCompletedResraurantItem

            order={ordersList}
            hotelName={hotelName}
            setType={setType}
          />

          {/* {curOrder?.houseKeepingItemId && (
            <OrderIHistoryCompletedHouseKeepingItem
              key={curOrder?.id}
              order={curOrder}
              hotelName={hotelName}
            />
          )} */}
        </Stack>
        {ordersList[ordersList?.length - 1]?.notes && <div style={{ marginTop: '8px', fontWeight: 700 }}>
          <p>
            Notes
          </p>
          <TextArea
            sx={{ mt: 1, p: 1, color: HCTheme.blackColorHC, }}
            name="notes"
            id="notes"
            placeholder="Add Notes"
            fullWidth
            multiline
            minRows="1"
            disabled
            value={ordersList[ordersList?.length - 1]?.notes}

          />
        </div>}
        <PageSubHeading mt={1.5}>Order Status</PageSubHeading>
        <TrackOrderTimeline status={displayStatus} order={ordersList} itemType={itemType} />
        <Box sx={{ my: 0, mb: 2 }}>
          <Link
            to={type === "houseKeeping" ? `/room-services` : `/restaurant`}
          >
            <ButtonHC
              variant="contained"
              fullWidth
              sx={{ textTransform: "capitalize", py: 1.5 }}
            >
              Browse Menu
            </ButtonHC>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default TrackOrder;
