import { Avatar, Box, List, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ProductPrice, ProductTitle } from "../styled/ProductBox";
import chevronUpIconSVG from "./../../assets/svg/chevron-up.svg";
import chevronIconSVG from "./../../assets/svg/chevron.svg";

import { useState } from "react";
import { formatDateCompletedOrder } from "../../utils/funcs";
import {
  TrackOrderItemPaper,
  TrackOrderItemTime,
  TrackOrderSecondaryText,
} from "../styled/TrackOrder";

import foodImg from '../../assets/svg/image 1.svg'
import open from '../../assets/svg/open.svg'
import close from '../../assets/svg/close.svg'


const DUMMY_DATA = [
  {
    id: 1,
    name: 'test 1',
    image: foodImg,
    quantity: 1,
    price: 4000
  },
  {
    id: 2,
    name: 'test 2',
    image: foodImg,
    quantity: 13,
    price: 4000
  },
  {
    id: 3,
    name: 'test 3',
    image: foodImg,
    quantity: 14,
    price: 4000
  },
  {
    id: 4,
    name: 'test 4',
    image: foodImg,
    quantity: 17,
    price: 4000
  },
]
const newArray = DUMMY_DATA.slice(1);

const OrderIHistoryCompletedHouseKeepingItem = ({ order, hotelName }) => {
  const [showMore, setShowMore] = useState(false)
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  return (
    <>

      <div>

        <TrackOrderItemPaper elevation={0}>
          <Stack direction="" justifyContent="space-between">
            <Avatar
              variant="rounded"
              alt="Remy Sharp"
              src={DUMMY_DATA[0].image}
              sx={{ width: 64, height: 64, mr: 1.25 }}
            />
            <Stack justifyContent="center" flex="1">
              <ProductTitle>{DUMMY_DATA[0].name} </ProductTitle>

            </Stack>
            <Box display="flex" flexDirection='column' alignItems="center" ml={3} mr={2} mt={1.5}>
              <TrackOrderSecondaryText><Stack direction="row" alignItems="center">
                {DUMMY_DATA[0].quantity}&nbsp;
              </Stack> Qty</TrackOrderSecondaryText>
              <ProductPrice color="#32842B">
                {currencySymbol} {DUMMY_DATA[0].price}
              </ProductPrice>
            </Box>
          </Stack>

        </TrackOrderItemPaper>
      </div>
      {showMore && newArray.map(ele =>
        <TrackOrderItemPaper elevation={1}>
          <div style={{ marginTop: '4px' }}>
            <Stack direction="" justifyContent="space-between">
              <Avatar
                variant="rounded"
                alt="Remy Sharp"
                src={ele.image}
                sx={{ width: 64, height: 64, mr: 1.25 }}
              />
              <Stack justifyContent="center" flex="1">
                <ProductTitle>{ele.name} </ProductTitle>
                <Stack direction="row" alignItems="start">
                  <Box>


                  </Box>
                </Stack>
              </Stack>
              <Box display="flex" flexDirection='column' alignItems="center" ml={3} mr={2} mt={1.5}>
                <TrackOrderSecondaryText><Stack direction="row" alignItems="center">
                  {ele.quantity}&nbsp;
                </Stack> Qty</TrackOrderSecondaryText>
                <ProductPrice color="#32842B">
                  {currencySymbol} {ele.price}
                </ProductPrice>
              </Box>
            </Stack>
          </div>
        </TrackOrderItemPaper>)}
      {DUMMY_DATA.length > 1 && <div onClick={() => {
        setShowMore(prev => !prev)
      }} style={{ margin: '4px auto', }} >
        <img src={!showMore ? close : open} /> View {!showMore ? 'More' : 'Less'}
      </div>}

    </>

  );
};

export default OrderIHistoryCompletedHouseKeepingItem;
