import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useHttp from "../../Hooks/use-http";
import CONSTANT from "../../utils/constant";
import { HCTheme } from "../../utils/theme";
import { PageSubHeading, WelcomeHeading } from "../styled/PageSubHeading";

const SendingOrder = ({ parentId, gif }) => {
  const cancleOrders = useHttp();
  let navigate = useNavigate();

  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const [isCancleButtonVisible, setIsCanselButtonVisible] = useState(false);

  const handlerCancelOrders = () => {
    cancleOrders.sendRequest(
      CONSTANT.URLS.cancelOrders,
      () => {
        navigate("/home");
      },
      { parentId },
      "Your orders cancled successfuly"
    );
  };

  const handleClickOpen = () => {
    setIsConfirmDialog(true);
  };

  const handleClose = () => {
    setIsConfirmDialog(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsCanselButtonVisible(true);
    }, 30 * 1000);
  }, []);

  return (
    <Fragment>
      <Container
        maxWidth="sm"
        disableGutters={true}
        sx={{
          position: "relative",
          minHeight: window.innerHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "strach",
          overflow: "hidden",
          bgcolor: HCTheme.whiteColorHC,
        }}
      >
        <Stack
          sx={{
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            className="auth-falback-logo-box"
          >
            <img
              src={gif}
              style={{ width: "100%", transform: "scale(1.3)" }}
              alt=""
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
              my: 8,
            }}
          >
            <WelcomeHeading sx={{ fontStyle: "unset" }}>
              Sending Order
            </WelcomeHeading>
            <PageSubHeading>Please wait..</PageSubHeading>

            <Button
              sx={{
                mt: 2,
                textTransform: "unset",
                color: "#FC5012",
                visibility: isCancleButtonVisible ? "visible" : "hidden",
              }}
              onClick={() => {
                handleClickOpen();
              }}
            >
              Cancel Orders
            </Button>
          </Box>
        </Stack>
      </Container>
      <div>
        <Dialog
          open={isConfirmDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="confirm-discard-order-dialog"
        >
          <DialogTitle id="alert-dialog-title">Cancel Orders</DialogTitle>
          <DialogContent>
            <DialogContentText color="pimary" id="alert-dialog-description">
              Are you sure want to cancel orders?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlerCancelOrders} disableRipple>
              Yes
            </Button>
            <Button onClick={handleClose} disableRipple>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default SendingOrder;
