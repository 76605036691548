import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const CartItemBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  border: "1px solid #D0D0D0",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
}));

export const CartItemHeader = styled(Typography)(({ theme, status }) => ({
  borderRadius: `${theme.spacing(1.5)} ${theme.spacing(1.5)} 0 0`,
  background: "#D9D9D9",
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "14px",
  color:
    status === "Pending"
      ? "#FC5012"
      : status === "Accepted"
        ? theme.palette.success.main
        : theme.palette.error.main,
}));

export const CartItemFooter = styled(Typography)(({ theme, status }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "10px",
  lineHeight: "14px",
  color: "#FC5012",
  display: "flex",
  alignItems: "center",
}));

export const CartItemTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "27px",
  color: theme.palette.primary.main,
}));

export const CartSummaryListItemText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22px",
  color: theme.palette.primary.main,
}));
export const CartCountText = styled(CartSummaryListItemText)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));
export const CartTotalWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: "10px 10px 0px 0px",
  position: "fixed",
  width: "100%",
  maxWidth: "600px",
  bottom: "0rem",
  zIndex: 1500,
}));
export const CartTotalText = styled(Box)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "10px",
  lineHeight: "14px",
  color: theme.palette.primary.main,
}));

export const CartPlaceOrderBtn = styled(Button)(({ theme }) => ({
  background: "#121212",
  width: '130px',
  borderRadius: "12px",
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22px",
  display: "flex",
  alignItems: "flex-end",
  color: "#F1F1F1",
  "&:hover": {
    boxShadow: "none",
    background: theme.palette.primary.main,
  },
  "&:active": {
    boxShadow: "none",
    background: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "none",
    background: theme.palette.primary.main,
  },
}));
