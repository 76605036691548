import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const OrderHistorySecondaryText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#AEAFAF",
}));

export const OrderHistoryOrderIDText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  display: "flex",
  alignItems: "flex-end",
  color: "#000000",
  "& span": {
    fontWeight: 900,
  },
}));
