import SearchIcon from "@mui/icons-material/Search";
import { Drawer, InputAdornment, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UIActions } from "../../store/ui-slice";
import {
    detectScrollOnList,
    getHeaderLogoHight,
    getHeaderLogoMargin,
} from "../../utils/funcs";
import { SearchBox } from "../styled/SearchBox";

let oneTime = true;
let isScrolled = false;
let from;

const LayoutComponent = (props) => {
    const DeviceHeight = window.innerHeight;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const dispatch = useDispatch();

    const handlerDrawer = () => {
        const drawerElem = document.querySelector(".MuiDrawer-paperAnchorBottom");
        const headerSlidingLogo = document.getElementById("headerSlidingLogo");
        if (drawerElem) {
            if (oneTime) {
                drawerElem.style.height = "calc(50vh - 110px)";
                headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
                headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
                oneTime = false;
            }
            if (isDrawerOpen) {
                drawerElem.style.height = "calc(100% - 190px)";
                headerSlidingLogo.style.height = "0px";
                headerSlidingLogo.style.opacity = 0;
                dispatch(UIActions.showNavLogo({}));
            } else {
                drawerElem.style.height = "calc(50vh - 110px)";
                headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
                headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
                headerSlidingLogo.style.opacity = 1;
                dispatch(UIActions.hideNavLogo({}));
            }
        }
    };

    const handlerDrawerFirst = () => {
        setTimeout(() => {
            const drawerElem = document.querySelector(".MuiDrawer-paperAnchorBottom");
            const headerSlidingLogo = document.getElementById("headerSlidingLogo");

            if (drawerElem) {
                if (oneTime) {
                    drawerElem.style.height = "calc(50vh - 110px)";
                    headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
                    headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
                    oneTime = false;
                }
                if (isDrawerOpen) {
                    drawerElem.style.height = "calc(100% - 190px)";
                    headerSlidingLogo.style.height = "0px";
                    headerSlidingLogo.style.opacity = 0;
                    dispatch(UIActions.showNavLogo({}));
                } else {
                    drawerElem.style.height = "calc(50vh - 110px)";
                    headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
                    headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
                    headerSlidingLogo.style.opacity = 1;
                    dispatch(UIActions.hideNavLogo({}));
                }
            }
        }, 100);
    };



    return (
        <Box style={{}}>
            <div style={{ background: "white", height: !props.height ? '100vh' : '', zIndex: '-1' }}>
                <Box
                    sx={{ px: 2, pb: 2 }}
                    onTouchStart={(e) => {
                        from = e.touches[0].screenY;
                    }}
                    onTouchEnd={(e) => {
                        setIsDrawerOpen((previousState) =>
                            detectScrollOnList(
                                e.changedTouches[0].screenY,
                                from,
                                previousState
                            )
                        );
                    }}
                    style={{
                        position: "",
                        zIndex: "10",
                        background: "white",
                        width: "100%",
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",

                    }}
                >
                    <Box className="drawerHandleBox">

                    </Box>
                    <Box>

                        <SearchBox
                            placeholder="Search..."
                            className="search-box"
                            value={props?.filterString}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            onChange={props.handlerFilterChange}
                            fullWidth
                        />

                        <Stack
                            direction="row"
                            spacing={1.25}
                            sx={{ overflow: "scroll", mt: 2, mb: 0.2 }}
                        >
                            {props.children[0]}
                        </Stack>
                    </Box>
                </Box>
                <Box
                    sx={{ px: 2, pb: 2 }}
                    style={{
                        marginTop: isDrawerOpen ? "11px" : "0px",
                    }}
                    className="overFlowScroll"
                    onTouchStart={(e) => {
                        from = e.touches[0].screenY;
                    }}
                    onTouchEnd={(e) => {
                        setIsDrawerOpen((previousState) =>
                            detectScrollOnList(
                                e.changedTouches[0].screenY,
                                from,
                                previousState,
                                isScrolled
                            )
                        );
                    }}
                    onScroll={(e) => {
                        isScrolled = e.target.scrollTop > 0;
                    }}
                >
                    <Box className="overFlowScroll">{props.children[1]}</Box>
                </Box>
            </div>
        </Box>
    );
};

LayoutComponent.defaultProps = {
    handlerFilterChange: () => { },
    filterString: "",
    isSearchable: false,
};

export default LayoutComponent;
