const TrackOrderSVG = () => {
  return (
    <svg
      width={236}
      height={187}
      viewBox="0 0 236 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_874_2184)">
        <path
          d="M114.591 187C144.008 187 167.855 163.143 167.855 133.713C167.855 104.283 144.008 80.4249 114.591 80.4249C85.1744 80.4249 61.3274 104.283 61.3274 133.713C61.3274 163.143 85.1744 187 114.591 187Z"
          fill="#0C0F0A"
        />
        <path
          d="M140.823 111.231C141.504 111.784 131.553 125.171 118.6 141.129C115.838 144.48 113.19 147.696 110.755 150.65L109.683 151.952L108.532 150.766C98.4185 140.339 91.5103 132.594 92.0019 132.106C92.4936 131.618 100.229 138.526 110.653 148.646L108.43 148.76C110.822 145.771 113.422 142.518 116.134 139.124C129.086 123.165 140.141 110.678 140.823 111.231Z"
          fill="white"
        />
        <path
          d="M190.252 112.245C188.671 106.646 181.4 107.189 181.4 107.189L181.599 111.084C185.802 110.36 188.417 115.287 188.417 115.287L190.252 112.245Z"
          fill="#0C0F0A"
        />
        <path
          d="M35.9744 123.983C34.3958 118.386 27.1245 118.929 27.1245 118.929L27.3237 122.821C31.527 122.097 34.1391 127.024 34.1391 127.024L35.9744 123.983Z"
          fill="#AEAFAF"
        />
        <path
          d="M120.948 46.5508C129.383 49.1075 134.632 39.3759 134.632 39.3759L129.504 36.4493C126.993 42.4131 118.569 41.7229 118.569 41.7229L120.948 46.5508Z"
          fill="#AEAFAF"
        />
        <path
          d="M74.1388 57.0292C70.2143 57.4445 69.7444 62.3762 69.7444 62.3762L72.37 62.6878C72.366 59.7892 75.9627 58.6049 75.9627 58.6049L74.1388 57.0292Z"
          fill="#0C0F0A"
        />
        <path
          d="M235.953 93.0817C236.522 89.1736 231.865 87.4965 231.865 87.4965L230.911 89.9653C233.718 90.6795 233.973 94.4595 233.973 94.4595L235.953 93.0817Z"
          fill="#AEAFAF"
        />
        <path
          d="M97.043 5.58386C97.6125 1.67575 92.9538 -0.00135803 92.9538 -0.00135803L91.9995 2.46742C94.8063 3.18165 95.0616 6.96159 95.0616 6.96159L97.043 5.58386Z"
          fill="#0C0F0A"
        />
        <path
          d="M0.0435123 68.6227C-0.662248 74.7514 7.45105 76.2351 7.45105 76.2351L8.916 72.2255C4.04765 71.7857 3.34778 66.0483 3.34778 66.0483L0.0435123 68.6227Z"
          fill="#0C0F0A"
        />
        <path
          d="M180.625 46.4213C184.563 46.645 185.827 41.856 185.827 41.856L183.286 41.1214C182.82 43.9833 179.081 44.5694 179.081 44.5694L180.625 46.4213Z"
          fill="#0C0F0A"
        />
        <path
          d="M202.153 62.4967C202.153 62.6738 200.697 62.8164 198.9 62.8164C197.104 62.8164 195.645 62.6738 195.645 62.4967C195.645 62.3196 197.102 62.1796 198.9 62.1796C200.698 62.1796 202.153 62.3219 202.153 62.4967Z"
          fill="#AEAFAF"
        />
        <path
          d="M205.612 53.6406C205.787 53.6406 205.929 55.1202 205.929 56.9441C205.929 58.7679 205.786 60.2476 205.612 60.2476C205.438 60.2476 205.292 58.7697 205.292 56.9441C205.292 55.1184 205.435 53.6406 205.612 53.6406Z"
          fill="#AEAFAF"
        />
        <path
          d="M209.292 62.2847C209.292 62.1095 210.749 61.9677 212.545 61.9677C214.341 61.9677 215.8 62.1104 215.8 62.2847C215.8 62.4591 214.343 62.6045 212.545 62.6045C210.747 62.6045 209.292 62.4618 209.292 62.2847Z"
          fill="#AEAFAF"
        />
        <path
          d="M205.835 71.1403C205.661 71.1403 205.519 69.6625 205.519 67.8391C205.519 66.0157 205.661 64.5356 205.835 64.5356C206.01 64.5356 206.152 66.0135 206.152 67.8391C206.152 69.6648 206.011 71.1403 205.835 71.1403Z"
          fill="#AEAFAF"
        />
        <path
          d="M211.097 67.3428C210.961 67.4546 210.334 66.9256 209.694 66.1602C209.053 65.3948 208.646 64.6847 208.78 64.5705C208.914 64.4564 209.543 64.9876 210.183 65.7531C210.824 66.5185 211.231 67.2304 211.097 67.3428Z"
          fill="#AEAFAF"
        />
        <path
          d="M210.792 57.3001C210.916 57.4223 210.481 58.0655 209.817 58.7349C209.154 59.4043 208.513 59.8481 208.389 59.724C208.265 59.5999 208.702 58.9568 209.366 58.2869C210.029 57.6171 210.668 57.176 210.792 57.3001Z"
          fill="#AEAFAF"
        />
        <path
          d="M203.334 59.8571C203.219 59.993 202.617 59.676 201.984 59.1488C201.352 58.6216 200.933 58.0886 201.045 57.9518C201.156 57.815 201.761 58.1329 202.391 58.6601C203.022 59.1873 203.445 59.7244 203.334 59.8571Z"
          fill="#AEAFAF"
        />
        <path
          d="M203.04 64.2857C203.168 64.4039 202.785 65.0348 202.183 65.696C201.581 66.3572 200.989 66.797 200.859 66.6793C200.728 66.5615 201.111 65.9283 201.713 65.2667C202.316 64.605 202.91 64.1656 203.04 64.2857Z"
          fill="#AEAFAF"
        />
        <path
          d="M24.9235 44.3556C24.9235 44.5309 23.4649 44.6726 21.6681 44.6726C19.8714 44.6726 18.415 44.53 18.415 44.3556C18.415 44.1812 19.8718 44.0359 21.6681 44.0359C23.4645 44.0359 24.9235 44.1785 24.9235 44.3556Z"
          fill="#0C0F0A"
        />
        <path
          d="M28.3777 35.4973C28.5547 35.4973 28.6946 36.9769 28.6946 38.8008C28.6946 40.6246 28.5542 42.1042 28.3777 42.1042C28.2011 42.1042 28.0608 40.6264 28.0608 38.8008C28.0608 36.9751 28.2029 35.4973 28.3777 35.4973Z"
          fill="#0C0F0A"
        />
        <path
          d="M32.0581 44.14C32.0581 43.9652 33.5167 43.823 35.313 43.823C37.1093 43.823 38.5661 43.9657 38.5661 44.14C38.5661 44.3144 37.1093 44.4598 35.313 44.4598C33.5167 44.4598 32.0581 44.3185 32.0581 44.14Z"
          fill="#0C0F0A"
        />
        <path
          d="M28.604 52.9997C28.427 52.9997 28.2844 51.5201 28.2844 49.6963C28.2844 47.8724 28.427 46.3928 28.604 46.3928C28.781 46.3928 28.9209 47.8706 28.9209 49.6963C28.9209 51.5219 28.7788 52.9997 28.604 52.9997Z"
          fill="#0C0F0A"
        />
        <path
          d="M33.8629 49.1994C33.7271 49.3136 33.1019 48.7841 32.4632 48.0187C31.8244 47.2533 31.4156 46.5409 31.5496 46.4272C31.6836 46.3135 32.3106 46.8443 32.9494 47.6097C33.5881 48.3751 33.9969 49.0871 33.8629 49.1994Z"
          fill="#0C0F0A"
        />
        <path
          d="M33.5596 39.1567C33.6836 39.2808 33.2463 39.9239 32.5831 40.5915C31.9199 41.2591 31.2811 41.7048 31.1571 41.5807C31.033 41.4566 31.4685 40.8134 32.1318 40.1436C32.795 39.4738 33.4333 39.0326 33.5596 39.1567Z"
          fill="#0C0F0A"
        />
        <path
          d="M26.0991 41.7152C25.9873 41.8511 25.3852 41.534 24.7523 41.0068C24.1194 40.4797 23.7007 39.9466 23.8125 39.8098C23.9243 39.673 24.5264 39.991 25.1593 40.5182C25.7922 41.0453 26.2132 41.5811 26.0991 41.7152Z"
          fill="#0C0F0A"
        />
        <path
          d="M25.8062 46.1423C25.9366 46.2605 25.5541 46.8914 24.9515 47.5527C24.349 48.2139 23.755 48.6537 23.6251 48.5359C23.4952 48.4182 23.8795 47.785 24.4816 47.1256C25.0837 46.4661 25.6763 46.0241 25.8062 46.1423Z"
          fill="#0C0F0A"
        />
        <g clipPath="url(#clip1_874_2184)">
          <path
            d="M190.252 111.946C188.671 106.362 181.4 106.904 181.4 106.904L181.599 110.788C185.802 110.066 188.417 114.98 188.417 114.98L190.252 111.946Z"
            fill="#4CD964"
          />
          <path
            d="M35.9744 123.653C34.3958 118.071 27.1245 118.613 27.1245 118.613L27.3237 122.494C31.527 121.771 34.1391 126.686 34.1391 126.686L35.9744 123.653Z"
            fill="#EBCD60"
          />
          <path
            d="M120.948 46.4268C129.383 48.9767 134.632 39.271 134.632 39.271L129.504 36.3522C126.993 42.3001 118.569 41.6117 118.569 41.6117L120.948 46.4268Z"
            fill="#EBCD60"
          />
          <path
            d="M74.1388 56.8773C70.2143 57.2915 69.7444 62.2101 69.7444 62.2101L72.37 62.5208C72.366 59.63 75.9627 58.4488 75.9627 58.4488L74.1388 56.8773Z"
            fill="#4CD964"
          />
          <path
            d="M235.953 92.8338C236.522 88.9361 231.865 87.2635 231.865 87.2635L230.911 89.7257C233.718 90.438 233.973 94.2078 233.973 94.2078L235.953 92.8338Z"
            fill="#EBCD60"
          />
          <path
            d="M97.043 5.56898C97.6125 1.67128 92.9538 -0.00135803 92.9538 -0.00135803L91.9995 2.46085C94.8063 3.17317 95.0616 6.94304 95.0616 6.94304L97.043 5.56898Z"
            fill="#4CD964"
          />
          <path
            d="M0.0435123 68.4399C-0.662248 74.5522 7.45105 76.032 7.45105 76.032L8.916 72.0331C4.04765 71.5945 3.34778 65.8724 3.34778 65.8724L0.0435123 68.4399Z"
            fill="#4CD964"
          />
          <path
            d="M180.625 46.2976C184.563 46.5208 185.827 41.7445 185.827 41.7445L183.286 41.0119C182.82 43.8662 179.081 44.4507 179.081 44.4507L180.625 46.2976Z"
            fill="#4CD964"
          />
          <path
            d="M202.153 62.3302C202.153 62.5068 200.697 62.6491 198.9 62.6491C197.104 62.6491 195.645 62.5068 195.645 62.3302C195.645 62.1536 197.102 62.014 198.9 62.014C200.698 62.014 202.153 62.1558 202.153 62.3302Z"
            fill="#EBCD60"
          />
          <path
            d="M205.612 53.4977C205.787 53.4977 205.929 54.9734 205.929 56.7924C205.929 58.6114 205.786 60.0871 205.612 60.0871C205.438 60.0871 205.292 58.6132 205.292 56.7924C205.292 54.9716 205.435 53.4977 205.612 53.4977Z"
            fill="#EBCD60"
          />
          <path
            d="M209.292 62.1188C209.292 61.944 210.749 61.8026 212.545 61.8026C214.341 61.8026 215.8 61.9449 215.8 62.1188C215.8 62.2927 214.343 62.4377 212.545 62.4377C210.747 62.4377 209.292 62.2954 209.292 62.1188Z"
            fill="#EBCD60"
          />
          <path
            d="M205.835 70.9508C205.661 70.9508 205.519 69.477 205.519 67.6584C205.519 65.8399 205.661 64.3637 205.835 64.3637C206.01 64.3637 206.152 65.8376 206.152 67.6584C206.152 69.4792 206.011 70.9508 205.835 70.9508Z"
            fill="#EBCD60"
          />
          <path
            d="M211.097 67.1634C210.961 67.275 210.334 66.7474 209.694 65.984C209.053 65.2206 208.646 64.5124 208.78 64.3985C208.914 64.2847 209.543 64.8145 210.183 65.5779C210.824 66.3413 211.231 67.0514 211.097 67.1634Z"
            fill="#EBCD60"
          />
          <path
            d="M210.792 57.1474C210.916 57.2694 210.481 57.9108 209.817 58.5784C209.154 59.246 208.513 59.6887 208.389 59.5649C208.265 59.4412 208.702 58.7997 209.366 58.1317C210.029 57.4636 210.668 57.0237 210.792 57.1474Z"
            fill="#EBCD60"
          />
          <path
            d="M203.334 59.6977C203.219 59.8332 202.617 59.517 201.984 58.9912C201.352 58.4655 200.933 57.9338 201.045 57.7974C201.156 57.661 201.761 57.9781 202.391 58.5039C203.022 59.0296 203.445 59.5654 203.334 59.6977Z"
            fill="#EBCD60"
          />
          <path
            d="M203.04 64.1144C203.168 64.2323 202.785 64.8615 202.183 65.521C201.581 66.1805 200.989 66.6191 200.859 66.5016C200.728 66.3842 201.111 65.7527 201.713 65.0928C202.316 64.4329 202.91 63.9947 203.04 64.1144Z"
            fill="#EBCD60"
          />
          <path
            d="M24.9235 44.2375C24.9235 44.4123 23.4649 44.5536 21.6681 44.5536C19.8714 44.5536 18.415 44.4114 18.415 44.2375C18.415 44.0636 19.8718 43.9186 21.6681 43.9186C23.4645 43.9186 24.9235 44.0608 24.9235 44.2375Z"
            fill="#4CD964"
          />
          <path
            d="M28.3777 35.4027C28.5547 35.4027 28.6946 36.8784 28.6946 38.6974C28.6946 40.5164 28.5542 41.9921 28.3777 41.9921C28.2011 41.9921 28.0608 40.5182 28.0608 38.6974C28.0608 36.8766 28.2029 35.4027 28.3777 35.4027Z"
            fill="#4CD964"
          />
          <path
            d="M32.0581 44.0224C32.0581 43.8481 33.5167 43.7063 35.313 43.7063C37.1093 43.7063 38.5661 43.8485 38.5661 44.0224C38.5661 44.1963 37.1093 44.3413 35.313 44.3413C33.5167 44.3413 32.0581 44.2004 32.0581 44.0224Z"
            fill="#4CD964"
          />
          <path
            d="M28.604 52.8586C28.427 52.8586 28.2844 51.3829 28.2844 49.5639C28.2844 47.7449 28.427 46.2692 28.604 46.2692C28.781 46.2692 28.9209 47.7431 28.9209 49.5639C28.9209 51.3847 28.7788 52.8586 28.604 52.8586Z"
            fill="#4CD964"
          />
          <path
            d="M33.8629 49.0684C33.7271 49.1822 33.1019 48.6541 32.4632 47.8908C31.8244 47.1274 31.4156 46.4169 31.5496 46.3035C31.6836 46.1901 32.3106 46.7195 32.9494 47.4829C33.5881 48.2463 33.9969 48.9563 33.8629 49.0684Z"
            fill="#4CD964"
          />
          <path
            d="M33.5596 39.0524C33.6836 39.1762 33.2463 39.8176 32.5831 40.4834C31.9199 41.1492 31.2811 41.5937 31.1571 41.4699C31.033 41.3461 31.4685 40.7047 32.1318 40.0367C32.795 39.3686 33.4333 38.9287 33.5596 39.0524Z"
            fill="#4CD964"
          />
          <path
            d="M26.0991 41.604C25.9873 41.7396 25.3852 41.4234 24.7523 40.8976C24.1194 40.3718 23.7007 39.8402 23.8125 39.7038C23.9243 39.5673 24.5264 39.8844 25.1593 40.4102C25.7922 40.936 26.2132 41.4703 26.0991 41.604Z"
            fill="#4CD964"
          />
          <path
            d="M25.8062 46.0194C25.9366 46.1373 25.5541 46.7665 24.9515 47.426C24.349 48.0855 23.755 48.5241 23.6251 48.4066C23.4952 48.2892 23.8795 47.6577 24.4816 47C25.0837 46.3424 25.6763 45.9015 25.8062 46.0194Z"
            fill="#4CD964"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_874_2184">
          <rect width={236} height={187} fill="white" />
        </clipPath>
        <clipPath id="clip1_874_2184">
          <rect width={236} height="186.502" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrackOrderSVG;
