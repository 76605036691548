import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Avatar, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { orderActions } from "../../store/order-slice";
import { ButtonHC } from "../styled/Button";
import {
    ProductPaper,
    ProductPrice,
    ProductTime,
    ProductTitle,
} from "../styled/ProductBox";
import orderImg from '../../assets/svg/order.svg'
import moment from "moment";
import OrderHistoryRestaurantProduct from "./OrderHistoryRestaurantProduct";
import OrderHistoryRoomServiceProduct from "./RoomServiceOrderHistoryProduct";
import { useState } from "react";
import { getDateTime } from "../../utils/funcs";

const OrderList = ({ order, status }) => {
    // const [price, setPrice] = useState(null)
    const dispatch = useDispatch();
    const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
    const offset = localStorage.getItem("timezone");
    // console.log(offset);

    let price = 0;
    order?.items?.forEach(ele => {
        // console.log(ele);
        if (ele?.status !== "Rejected") {

            price = price + +(ele?.itemAmount) + +(ele?.taxAmount)
        }



    })



    // console.log(order);

    const allCancelled = order?.items?.every(item => item.status === 'Cancelled');
    // Check if at least one status is 'pending', 'completed', or 'accepted'
    const hasPending = order?.items?.some(item => item.status === 'Pending');
    const hasCompleted = order?.items?.some(item => item.status === 'Completed');
    const hasAccepted = order?.items?.some(item => item.status === 'Accepted');

    let displayStatus = '';

    if (allCancelled) {
        displayStatus = 'cancel';
    } else if (hasCompleted) {
        displayStatus = 'completed';
    } else if (hasAccepted) {
        displayStatus = 'accepted';
    } else if (hasPending) {
        displayStatus = 'pending';
    }

    // console.log(order, 'ord');
    // console.log(displayStatus, 'ord');


    return (
        <ProductPaper>
            <div onClick={() => dispatch(orderActions.setOrder(order))}>
                <Link
                    to={`/trackorder/${order?.parentId}`}
                    state={{
                        itemType: "restaurant",
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Avatar
                            variant="rounded"
                            alt="Remy Sharp"
                            src={order?.image}
                            sx={{ width: 60, height: 60, mr: 1.25 }}
                        />
                        <Stack justifyContent="center" flex="1">
                            <ProductTitle>
                                <span style={{ marginLeft: "0.2rem" }}>

                                    {order?.name}
                                </span></ProductTitle>
                            <p style={{ fontSize: "12px", marginLeft: "0.2rem", fontWeight: 500, color: '#757575' }}>Order #{order?.parentId}</p>
                            <Stack direction="row" spacing={2}>
                                <Stack justifyContent="center">

                                    <ProductTime sx={false ? {} : { mt: 0.5 }}>
                                        <Stack direction="row" alignItems="center">
                                            <AccessTimeIcon sx={{ mr: 0.2, fontSize: '16px' }} />
                                            {/* <span style={{ fontSize: "10px" }}>{moment.utc(order?.orderTime).local().format('HH:mm A')}</span>  <CalendarMonthIcon sx={{ ml: 1, mr: 0.2, fontSize: '16px' }} /> <span style={{ fontSize: "10px" }}>{moment.utc(order?.createdAt).local().format('DD MMM, YYYY')}</span> */}
                                            <span style={{ fontSize: "10px" }}>{getDateTime(order?.orderTime, offset, 'hh:mm A')}</span>  <CalendarMonthIcon sx={{ ml: 1, mr: 0.2, fontSize: '16px' }} /> <span style={{ fontSize: "10px" }}>{getDateTime(order?.orderTime, offset, 'DD MMM, YYYY')}</span>

                                        </Stack>
                                    </ProductTime>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    {order?.items?.map(ele => {
                        if (ele?.restaurantItemId) {
                            return <OrderHistoryRestaurantProduct order={ele} />
                        }
                        else if (ele?.houseKeepingItemId) {
                            return <OrderHistoryRoomServiceProduct key={ele?.id} order={ele} />
                        }
                    })}
                    {status !== 'cancelled' && <>
                        <div className="dash-border" />
                        <Stack direction="row" justifyContent="flex-start" sx={{ ml: 1.25, mt: 1 }}>

                            <Stack flex="1">
                                <>
                                    <Stack direction='row' justifyContent="space-between">
                                        <p style={{ fontSize: '20px', marginTop: '2px', fontWeight: '700' }}>Total</p>
                                        <p style={{ fontSize: '20px', color: '#32842B', fontWeight: '700' }} sx={{ mt: 0 }}>
                                            {currencySymbol}  {price}
                                        </p>

                                    </Stack>
                                </>
                            </Stack>

                        </Stack>
                    </>}
                </Link>
            </div>
        </ProductPaper>
    );
};

export default OrderList;
