import { Box } from "@mui/material";
import { Fragment, useEffect, useRef } from "react";
import useOnScreen from "../../Hooks/use-visible";
import Guides from "../Restaurant/Guides";
import { PageSubHeading } from "../styled/PageSubHeading";

const DeviceSec = ({ category, onUpdateVisibility }) => {
  const DeviceSecRef = useRef(null);
  const isVisible = useOnScreen(DeviceSecRef);

  useEffect(() => {
    onUpdateVisibility(category?.id, isVisible);
  }, [isVisible]);

  return (
    <Box
      className="catSec overFlowScroll"
      p={2}
      id={category?.id}
      ref={DeviceSecRef}
    >
      <Fragment>
        <PageSubHeading sx={{ mt: 0.5, mb: 2 }}>
          {category?.name}
        </PageSubHeading>
        <Guides guides={category?.Items} />
      </Fragment>
    </Box>
  );
};

export default DeviceSec;
