import React from 'react'
import { Stack } from '@mui/material'
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ProductPaper, ProductTitle } from '../styled/ProductBox'
import veg from '../../assets/svg/veg.svg'
import nonVeg from '../../assets/svg/nonVeg.svg'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const TruncateText = ({ text, maxWords }) => {
    const words = text.split(' ');

    if (words.length > maxWords) {
        const truncatedText = words.slice(0, maxWords).join(' ') + '...';
        return <span>{truncatedText}</span>;
    }

    return <span>{text}</span>;
};

const ServiceProviderList = ({ id, image, name, openTime, closeTime, desciption }) => {

    const navigate = useNavigate();

    const serviceItem = () => {
        navigate(`/room-services/${id}`)

    }

    return (
        <>

            <div className='' style={{ margin: '8px 0px' }} onClick={serviceItem}>

                <ProductPaper>


                    <Stack
                        direction="column"
                        spacing={2}
                    >
                        <div>

                            <Stack>
                                <img src={image} alt='service provider' height={170} />
                            </Stack>
                            <ProductTitle sx={{ mt: '6px' }}>
                                {name}
                            </ProductTitle>
                            <Stack direction='row' sx={{ mt: '6px' }}>

                                <div style={{ margin: '0px 5px' }}>
                                    <AccessTimeIcon sx={{ fontSize: '18px' }} />
                                </div>
                                <div style={{ margin: '0px 0px', fontSize: '14px' }}>{moment.utc(openTime, 'HH:mm:ss').local().format('hh:mm A')} - {moment.utc(closeTime, 'HH:mm:ss').local().format('hh:mm A')}</div>
                            </Stack>
                            <div style={{ fontSize: '15px', fontWeight: '600' }}>

                                <TruncateText text={desciption} maxWords={12} />
                            </div>
                        </div>
                    </Stack>
                </ProductPaper>
            </div>

        </>
    )
}

export default ServiceProviderList