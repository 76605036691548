import { Box } from "@mui/material";
import { Container } from "@mui/system";
import FeedbackDrawer from "../components/Feedback/FeedbackDrawer";
import RestaurantHeader from "../components/Restaurant/RestaurantHeader";
import { HCTheme } from "../utils/theme";

const Feedback = ({ roomNum }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.blackColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
      id="cntr"
    >
      <Box>
        <RestaurantHeader roomNum={roomNum} navigatePath="/" />
      </Box>
      <FeedbackDrawer />
    </Container>
  );
};

export default Feedback;
