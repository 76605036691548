import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { HCTheme } from "../../../utils/theme";

export const TrackOrderHeader = styled(Box)(({ theme, status }) => ({
  border: "1px solid red",
  height: "100%",
}));

export const TrackYourOrderLink = styled(Box)(({ theme, status }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "16px",
  color: HCTheme.whiteColorHC,
  "& span": {
    marginLeft: "10px",
  },
}));

export const RoomNumText = styled(Typography)(({ theme, status }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "16px",
  color: HCTheme.whiteColorHC,
}));

export const RestaurantHeaderText = styled(Typography)(({ theme, status }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "25px",
  display: "flex",
  alignItems: "flex-end",
  color: "#F1F1F1",
}));

export const RestaurantHeaderImg = styled(Box)(({ theme, status }) => ({
  filter: "invert(1)",
  width: "132px",
  height: "132px",
  marginTop: "1.5rem",
}));
