import { Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./components/routes/PrivateRoutes ";
import PublicRoutes from "./components/routes/PublicRoutes";
import useHttp from "./Hooks/use-http";
import CONSTANT from "./utils/constant";
import { messaging } from "./utils/firebase-config";
import { getToken } from "firebase/messaging";

function App() {
  const api = useHttp();
  const navigate = useNavigate();

  // const [hotelName, setHotelName] = useState("");
  // const [roomNum, setRoomNum] = useState("");
  const hotelName = localStorage.getItem("hotelName");
  const roomNum = localStorage.getItem("roomNum");



  // const handlerCheckToken = useCallback((responseData) => {
  //   console.log(responseData);
  //   setHotelName(responseData?.hotelName);
  //   setRoomNum(responseData?.roomNO);
  //   localStorage.setItem("CURRENCY_SYMBOL", responseData?.myCurrency?.symbol);
  //   if (+responseData.status !== 200 || !responseData?.isActive) {
  //     localStorage.removeItem("authToken");
  //     navigate("/auth-fallback");
  //   }
  // }, []);

  // const verifyToken = () => {
  //   checkToken.sendRequest(CONSTANT.URLS.getHotelsDetails, ()=>{}, {
  //     token: localStorage.getItem("authToken"),
  //   });
  // };
  // useEffect(() => {
  //   if (localStorage.getItem("authToken")) {
  //     // verifyToken();
  //   }
  // }, []);

  const isLogin = Boolean(localStorage.getItem("authToken"));



  const requestNotificationPermission = () => {
    // navigator.serviceWorker.register('sw.js');

    // Notification.requestPermission(function (result) {
    //   if (result === 'granted') {
    //     navigator.serviceWorker.ready.then(function (registration) {
    //       registration.showNotification('Notification with ServiceWorker');
    //       if (permission === 'granted') {
    //         getToken(messaging, {
    //           vapidKey: 'BByr5o70_73aArxu7QbXj_fgkY0zPF0cy5bTT7Ky0MJKsYszoMEg4JWruRXmM7bgXG3gqunmI_NkBKV2t1VDtxI'
    //         }).then((currentToken) => {
    //           if (currentToken) {
    //             // Send the token to your server and update the UI if necessary
    //             console.log(currentToken);
    //             // alert(currentToken)
    //             api.sendRequest(CONSTANT.URLS.updateFcmToken, () => { }, { fcm: currentToken }, 'Update Token')
    //             // toast('success')

    //             // ...
    //           } else {
    //             // Show permission request UI
    //             console.log('No registration token available. Request permission to generate one.');
    //             // toast('No registration token available. Request permission to generate one.')
    //             // ...
    //           }
    //         }).catch((err) => {
    //           console.log('An error occurred while retrieving token. ', err);
    //           // toast('An error occurred while retrieving token. ', err)
    //           // toast(err)
    //           // ...
    //         });
    //       }
    //       else {
    //         // console.log(permission, 'dfajs');
    //         // alert('Please Grant the permission')
    //         toast('Please Grant the permission')
    //       }
    //     });
    //   }
    // });
    Notification.requestPermission().then((permission) => {
      // console.log(permission);
      if (permission === 'granted') {
        getToken(messaging, {
          vapidKey: 'BByr5o70_73aArxu7QbXj_fgkY0zPF0cy5bTT7Ky0MJKsYszoMEg4JWruRXmM7bgXG3gqunmI_NkBKV2t1VDtxI'
        }).then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // console.log(currentToken);
            // alert(currentToken)
            api.sendRequest(CONSTANT.URLS.updateFcmToken, () => { }, { fcm: currentToken })
            // toast('success')

            // ...
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // toast('No registration token available. Request permission to generate one.')
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // toast('An error occurred while retrieving token. ', err)
          // toast(err)
          // ...
        });
      }
      else {
        // console.log(permission, 'dfajs');
        // alert('Please Grant the permission')
        console('Please Grant the permission')
      }
    });

  }
  useEffect(() => {

    if (isLogin) {

      try {
        // window.Notification.requestPermission().then(function (permission) {

        //   // console.log(permission);
        //   if (permission === 'granted') {
        //     getToken(messaging, {
        //       vapidKey: 'BByr5o70_73aArxu7QbXj_fgkY0zPF0cy5bTT7Ky0MJKsYszoMEg4JWruRXmM7bgXG3gqunmI_NkBKV2t1VDtxI'
        //     }).then((currentToken) => {
        //       if (currentToken) {
        //         // Send the token to your server and update the UI if necessary
        //         console.log(currentToken);
        //         // alert(currentToken)
        //         api.sendRequest(CONSTANT.URLS.updateFcmToken, () => { }, { fcm: currentToken }, 'Update Token')
        //         // toast('success')

        //         // ...
        //       } else {
        //         // Show permission request UI
        //         console.log('No registration token available. Request permission to generate one.');
        //         // toast('No registration token available. Request permission to generate one.')
        //         // ...
        //       }
        //     }).catch((err) => {
        //       console.log('An error occurred while retrieving token. ', err);
        //       // toast('An error occurred while retrieving token. ', err)
        //       // toast(err)
        //       // ...
        //     });
        //   }
        //   else {
        //     // console.log(permission, 'dfajs');
        //     // alert('Please Grant the permission')
        //     toast('Please Grant the permission')
        //   }

        // })
        requestNotificationPermission()
      }
      catch (error) {

        console.log(error);
      }
    }
  }, [])

  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<div className="loading" />}>
        {!isLogin ? (
          <PublicRoutes />
        ) : (
          <PrivateRoutes hotelName={hotelName} roomNum={roomNum} />
        )}
      </Suspense>
    </div>
  );
}

export default App;
