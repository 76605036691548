import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Avatar, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { CartItemFooter } from "../styled/Cart";
import {
  ProductPaper,
  ProductPrice,
  ProductTime,
  ProductTitle,
} from "../styled/ProductBox";

const RoomServiceCartProduct = ({ order }) => {
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  const status = order?.status;
  const isRejected = status === "Rejected";
  return (
    <ProductPaper isRejected={isRejected}>
      <Stack direction="row" justifyContent="space-between">
        <Avatar
          variant="rounded"
          alt="Remy Sharp"
          src={order?.houseKeepingItem?.image}
          sx={{
            width: 76,
            height: 76,
            mr: 1.25,
            filter: isRejected ? "brightness(0.7)" : "unset",
          }}
        />

        <Stack
          justifyContent={status === "Rejected" ? "center" : "space-between"}
          flex="1"
        >
          <ProductTitle>{order?.houseKeepingItem?.name}</ProductTitle>
          {status !== "Rejected" && (
            <Stack direction="row" spacing={2}>
              <Box>
                {order?.houseKeepingItem?.price > 0 && (
                  <ProductPrice color="#FC5012">
                    {currencySymbol} {order?.price}{" "}
                  </ProductPrice>
                )}
                <ProductTime>
                  <Stack direction="row" alignItems="center">
                    <AccessTimeIcon sx={{ mr: 1 }} />{" "}
                    {order?.houseKeepingItem?.time}
                    min
                    {order?.houseKeepingItem?.price > 0 && (
                      <Stack ml={2} direction="row" alignItems="center">
                        Qty {order?.quantity}
                      </Stack>
                    )}
                  </Stack>
                </ProductTime>
              </Box>
            </Stack>
          )}
          {status === "Rejected" && (
            <CartItemFooter status={status} sx={{ pt: 0.75 }}>
              <span style={{ fontWeight: "700" }}>
                <ErrorOutlineIcon
                  sx={{ mr: 0.5, mt: "1px" }}
                  style={{ fontSize: "14px" }}
                />
              </span>
              Unavailable
            </CartItemFooter>
          )}
        </Stack>
        <Box display="flex" alignItems="center" ml={4.5}></Box>
      </Stack>
    </ProductPaper>
  );
};

export default RoomServiceCartProduct;
