import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GradingIcon from "@mui/icons-material/Grading";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Badge, Box, IconButton, Stack } from "@mui/material";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHeaderLineDetails } from "../../utils/funcs";
import { HCTheme } from "../../utils/theme";
import Logo from "../general/Logo";
import {
  RestaurantHeaderText,
  RoomNumText,
  TrackYourOrderLink,
} from "../styled/Restaurant";
import { LightTooltip } from "../styled/Tooltip";
import navLogo from "./../../assets/svg/Logo.svg";

const RestaurantHeader = ({
  roomNum,
  navigatePath,
  title,
  onClickCartIcon,
  onClickoOrderHistoryIcon,
  showIcons,
  badgeData,
  showText = null,
}) => {
  let navigate = useNavigate();
  const isNavLogoVisible = useSelector((state) => state.ui.isNavLogoVisible);
  const DeviceHeight = window.innerHeight;
  return (
    <Stack style={{ touchAction: "none" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ zIndex: 1300 }}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >

        <IconButton
          aria-label="back"
          sx={{ p: 0 }}
          disableRipple
          onClick={() => navigate(navigatePath)}
        >
          <ArrowBackIcon sx={{ color: HCTheme.whiteColorHC, ml: 2 }} />
        </IconButton>
        <RestaurantHeaderText
          sx={{ ml: 2 }}

        >
          {title}
        </RestaurantHeaderText>
        <img
          src={navLogo}
          style={{
            width: "48px",
            marginRight: "auto",
            marginLeft: "1rem",
            transition: "0.7s",
            opacity: 0,
          }}
          alt=""
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mr={2}
        >
          {/* <RoomNumText sx={{ color: HCTheme.whiteColorHC }}>
            {roomNum}
          </RoomNumText> */}
          {showIcons && (
            <Fragment>
              <LightTooltip title="Cart">
                <TrackYourOrderLink onClick={onClickCartIcon}>
                  <Box>
                    <Badge
                      variant="dot"
                      invisible={!badgeData?.isRestaurantCartItems}
                    >
                      <ShoppingCartOutlinedIcon
                        sx={{ color: HCTheme.whiteColorHC }}
                      />
                    </Badge>
                  </Box>
                </TrackYourOrderLink>
              </LightTooltip>
              {/* <LightTooltip title="Order History">
                <TrackYourOrderLink onClick={onClickoOrderHistoryIcon}>
                  <Box>
                    <Badge
                      variant="dot"
                      invisible={!badgeData?.isRestaurantOrders}
                    >
                      <GradingIcon sx={{ color: HCTheme.whiteColorHC }} />
                    </Badge>
                  </Box>
                </TrackYourOrderLink>
              </LightTooltip> */}
            </Fragment>
          )}
        </Stack>
      </Stack>
      {showText && <Box display="flex" flex="1" alignItems="center" className="upper-header">
        <Logo id="headerSlidingLogo" />
      </Box>}
      {showText && <Box>
        <RestaurantHeaderText
          sx={{ ml: 2 }}

        >
          {showText}
        </RestaurantHeaderText>
      </Box>}
    </Stack>
  );
};

export default RestaurantHeader;
