import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Avatar, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { orderActions } from "../../store/order-slice";
import { ButtonHC } from "../styled/Button";
import {
  ProductPaper,
  ProductPrice,
  ProductTime,
  ProductTitle,
} from "../styled/ProductBox";

const OrderHistoryRoomServiceProduct = ({
  order,
  onIncrement,
  onDecrement,
}) => {
  const dispatch = useDispatch();
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");


  return (
    <>

      <div>

        <Stack direction="row" justifyContent="flex-start" sx={{ ml: 0, mt: 1 }}>
          <Avatar
            variant="rounded"
            alt="Remy Sharp"
            src={order?.houseKeepingItem?.image}
            sx={{ width: 20, height: 20, mr: 1, ml: 1 }}
          />
          <Stack flex="1">
            <>
              <Stack direction='row' justifyContent="space-between">
                <p style={{ fontSize: '12px', marginTop: '2px', fontWeight: '700' }}> <span style={{ color: '#757575', fontWeight: '700' }}>{order?.quantity} X </span> {order?.houseKeepingItem?.name}</p>
                <ProductPrice color={order?.status === 'Rejected' ? '#CD0000' : '#32842B'} sx={{ mt: 0 }}>
                  {currencySymbol} {(+order?.itemAmount + +order?.taxAmount)}
                </ProductPrice>

              </Stack>
            </>
          </Stack>

        </Stack>

      </div>

    </ >
  );
};

export default OrderHistoryRoomServiceProduct;
