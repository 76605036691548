import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Avatar, Box } from "@mui/material";
import { Stack } from "@mui/system";
import { CartItemFooter, CartItemTitle } from "../styled/Cart";
import { ProductPaper, ProductPrice, ProductTime } from "../styled/ProductBox";

const CartItem = ({ order }) => {
  const status = order?.status;
  const isRejected = status === "Rejected";
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  return (
    <ProductPaper isRejected={isRejected}>
      <Stack>
        <Box>
          <Stack direction="row" justifyContent="space-between">
            <Avatar
              variant="rounded"
              alt="Remy Sharp"
              src={order?.restaurantItem?.image}
              sx={{
                width: 76,
                height: 76,
                mr: 1.25,
                filter: isRejected ? "brightness(0.7)" : "unset",
              }}
            ></Avatar>

            <Stack
              justifyContent={
                status === "Rejected" ? "center" : "space-between"
              }
              flex="1"
            >
              <CartItemTitle>{order?.restaurantItem?.name}</CartItemTitle>
              {status !== "Rejected" && (
                <Stack justifyContent="" direction="row" flex="1" spacing={2}>
                  <Stack direction="column" justifyContent="space-between">
                    <ProductPrice color="#FC5012">
                      {currencySymbol} {order?.price}{" "}
                    </ProductPrice>
                    <Box>
                      <ProductTime>
                        <Stack direction="row" alignItems="center">
                          <AccessTimeIcon sx={{ mr: 1 }} /> 2 min
                          <Stack ml={2} direction="row" alignItems="center">
                            Qty {order?.quantity}
                          </Stack>
                        </Stack>
                      </ProductTime>
                      <ProductTime></ProductTime>
                    </Box>
                  </Stack>
                  <Box display="flex" alignItems="center" ml={4.5}></Box>
                </Stack>
              )}
              {status === "Rejected" && (
                <CartItemFooter status={status} sx={{ pt: 0.75 }}>
                  <span style={{ fontWeight: "700" }}>
                    <ErrorOutlineIcon
                      sx={{ mr: 0.5, mt: "1px" }}
                      style={{ fontSize: "14px" }}
                    />
                  </span>
                  Unavailable
                </CartItemFooter>
              )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </ProductPaper>
  );
};

export default CartItem;
