import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBgFBesE_H0k7TlSo7RJeetR09MeIiCByc",
  authDomain: "hotelcom-de0d1.firebaseapp.com",
  databaseURL: "https://hotelcom-de0d1-default-rtdb.firebaseio.com",
  projectId: "hotelcom-de0d1",
  storageBucket: "hotelcom-de0d1.appspot.com",
  messagingSenderId: "776795925150",
  appId: "1:776795925150:web:8572088cce20d33b23e98f",
  measurementId: "G-ZPBB8SM7RV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime
const database = getDatabase(app);

// Initialize Firebase Cloud Messaging and get a reference to the service

export default database;
export const messaging = getMessaging(app);
