import { Drawer, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import { UIActions } from "../../store/ui-slice";
import CONSTANT from "../../utils/constant";
import { getHeaderLogoHight, getHeaderLogoMargin } from "../../utils/funcs";
import LoadingSpinner from "../general/LoadingSpinner";
import { ButtonHC } from "../styled/Button";
import { CallBoxPaper, CallBoxRoleText } from "../styled/call-services";
import { PolicyLinkNew } from "../styled/HotelDetails";
import CallBox from "./CallBox";
import feedback from '../../assets/svg/feedback.svg'

let oneTime = true;

export default function NewCallServicesDrawer(props) {
  const getContacts = useHttp();
  const [contacts, setContacts] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const dispatch = useDispatch();
  const DeviceHeight = window.innerHeight;

  const handlerDrawer = () => {
    const drawerElem = document.querySelector(".MuiDrawer-paperAnchorBottom");
    const headerSlidingLogo = document.getElementById("headerSlidingLogo");

    if (drawerElem) {
      if (oneTime) {
        drawerElem.style.height = "calc(45vh)";
        headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
        headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
        oneTime = false;
      }
      if (isDrawerOpen) {
        drawerElem.style.height = "calc(100% - 110px)";
        headerSlidingLogo.style.height = "0px";
        headerSlidingLogo.style.opacity = 0;
        dispatch(UIActions.showNavLogo({}));
      } else {
        drawerElem.style.height = "calc(45vh)";
        headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
        headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
        headerSlidingLogo.style.opacity = 1;
        dispatch(UIActions.hideNavLogo({}));
      }
    }
  };

  const handlerDrawerFirst = () => {
    setTimeout(() => {
      const drawerElem = document.querySelector(".MuiDrawer-paperAnchorBottom");
      const headerSlidingLogo = document.getElementById("headerSlidingLogo");

      if (drawerElem) {
        if (oneTime) {
          drawerElem.style.height = "calc(45vh)";
          headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
          headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
          oneTime = false;
        }
        if (isDrawerOpen) {
          drawerElem.style.height = "calc(100% - 110px)";
          headerSlidingLogo.style.height = "0px";
          headerSlidingLogo.style.opacity = 0;
          dispatch(UIActions.showNavLogo({}));
        } else {
          drawerElem.style.height = "calc(45vh)";
          headerSlidingLogo.style.height = getHeaderLogoHight(DeviceHeight);
          headerSlidingLogo.style.margin = getHeaderLogoMargin(DeviceHeight);
          headerSlidingLogo.style.opacity = 1;
          dispatch(UIActions.hideNavLogo({}));
        }
      }
    }, 500);
  };

  const handlerGetContacts = React.useCallback((responseData) => {
    setContacts(responseData.data);
  }, []);

  useEffect(() => {
    handlerDrawerFirst(isDrawerOpen);
  }, []);

  const loadContacts = () => {
    getContacts.sendRequest(CONSTANT.URLS.getContacts, handlerGetContacts, {});
  };

  useEffect(() => {
    loadContacts();
  }, []);

  useEffect(() => {
    handlerDrawer(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    if ((to, from)) {
      if (to - from < 40) {
        setIsDrawerOpen(true);
      }
      if (to - from > 40) {
        setIsDrawerOpen(false);
      }
    }
  }, [from, to]);

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={true}
        onClose={() => setIsDrawerOpen(false)}
        hideBackdrop={true}
        sx={{ bgcolor: "transparent" }}
      >
        <Box
          sx={{ px: 2, pb: 2 }}
          onTouchStart={(e) => {
            setFrom(e.touches[0].screenY);
          }}
          onTouchEnd={(e) => {
            setTo(e.changedTouches[0].screenY);
          }}
          style={{
            position: "absolute",
            zIndex: "10",
            background: "white",
            width: "100%",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            top: "-38px",
          }}
        >
          <Box className="drawerHandleBox">
            <Box className="drawerHandle"></Box>
          </Box>
        </Box>
        <Box sx={{ px: 2, pb: 2 }}>
          <Stack spacing={3}>
            <CallBoxPaper sx={{ py: 2, px: 2, mt: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row">
                  <div>
                    <img style={{
                      width: '50px',
                      height: '50px'
                    }} src={feedback} alt="avatar" />
                  </div>
                  <CallBoxRoleText sx={{ ml: 1, mt: 0.5 }}>Review & Comments</CallBoxRoleText>
                </Stack>
                <Box>
                  <Link to="/feedback">
                    <ButtonHC
                      variant="contained"
                      sx={{ textTransform: "capitalize", py: 1 }}
                    >
                      Feedback
                    </ButtonHC>
                  </Link>
                </Box>
              </Stack>
            </CallBoxPaper>
            {getContacts.isLoading && <LoadingSpinner />}
            {!getContacts.isLoading &&
              contacts.map((contact) => (
                <CallBox
                  name={contact?.name}
                  role={contact?.department}
                  ContactNumber={contact?.mobile}
                />
              ))}
          </Stack>

          <PolicyLinkNew sx={{ mt: 2.5, textAlign: "center" }} style={{
            maxWidth: '170px',
            textAlign: 'center',
            margin: ' 0px auto',
            marginTop: '10px'
          }}>
            <a
              href={CONSTANT.hotelcomPolicyUrl}
              target="_blank"
              rel="noreferrer"

            >
              Terms and Conditions of Hotelcom
            </a>
          </PolicyLinkNew>
        </Box>
      </Drawer>
    </Box>
  );
}
