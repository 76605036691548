import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RestaurantHeader from "../components/Restaurant/RestaurantHeader";
import NewHousekeepingDrawer from "../components/RoomServices/NewRoomServicesDrawer";
import useHttp from "../Hooks/use-http";
import CONSTANT from "../utils/constant";
import { HCTheme } from "../utils/theme";
import ServiceHeader from "../components/RoomServices/ServiceHeader";

const RoomServices = ({ roomNum }) => {
  const navigate = useNavigate();
  const getBadgeData = useHttp();
  const [badgeData, setBadgeData] = useState({});

  const handlerGetBadgeData = useCallback((responseData) => {
    setBadgeData(responseData?.data?.track);
  }, []);

  const loadBadgeData = () => {
    getBadgeData.sendRequest(
      CONSTANT.URLS.getBadgeData,
      handlerGetBadgeData,
      {}
    );
  };

  const handlerChangeCart = () => {
    loadBadgeData();
  };

  useEffect(() => {
    loadBadgeData();
  }, []);
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100vh",
        background: HCTheme.blackColorHC,
        display: "flex",
        flexDirection: "column",
        p: 0,
      }}
      id="cntr"
    >
      <Box>
        <ServiceHeader
          title={
            <>
              Services
            </>
          }
          roomNum={roomNum}
          navigatePath="/home"
          onClickCartIcon={() => {
            navigate("/pre-cart?section=houseKeeping", {
              state: { title: "My Cart" },
            });
          }}
          // onClickoOrderHistoryIcon={() => {
          //   navigate(`/order-history?section=houseKeeping`);
          // }}
          showIcons
          badgeData={badgeData}
        />
      </Box>
      <NewHousekeepingDrawer onChangeCart={handlerChangeCart} />
    </Container>
  );
};

export default RoomServices;
