const { createSlice } = require("@reduxjs/toolkit");

const initialUIState = {
  isNavLogoVisible: false,
};

const UISlice = createSlice({
  name: "ui",
  initialState: initialUIState,
  reducers: {
    showNavLogo: (state, action) => {
      state.isNavLogoVisible = true;
    },
    hideNavLogo: (state, action) => {
      state.isNavLogoVisible = false;
    },
  },
});

export const UIActions = UISlice.actions;

export default UISlice.reducer;
