import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import {
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useCallback, useState } from "react";
import useHttp from "../../Hooks/use-http";
import { HCTheme } from "../../utils/theme";
import { ButtonHC } from "../styled/Button";
import { CartCountText, CartItemTitle } from "../styled/Cart";
import { ProductPaper, ProductPrice, ProductTime } from "../styled/ProductBox";

const PreCartHouseKeepingItem = ({
  item,
  onIncrement,
  onDecrement,
  myCart,
}) => {
  const cartItems = myCart?.cartItem;

  // console.log(item);

  const removeFromCart = useHttp();
  const decreaseQnntByOne = useHttp();
  const increaseQnntByOne = useHttp();
  const currencySymbol = localStorage.getItem("CURRENCY_SYMBOL");
  const [count, setCount] = useState(
    cartItems.filter((cartItem) => {
      return cartItem?.houseKeepingItem?.id == item?.houseKeepingItem?.id;
    })[0]?.quantity || 0
  );

  const isLoading = () => {
    return (
      removeFromCart.isLoading ||
      increaseQnntByOne.isLoading ||
      decreaseQnntByOne.isLoading
    );
  };

  const handlerRemoveFromCartResponse = useCallback((responseData) => {
    // setCount(0);
    onDecrement(responseData?.cart);
  }, []);

  const handlerRemoveFromCart = (id) => {
    const urlRemoveFromCart = {
      type: "DELETE",
      endpoint: `/cart/items/remove/cart/items/${item?.id}?section=houseKeeping`,
    };

    removeFromCart.sendRequest(
      urlRemoveFromCart,
      handlerRemoveFromCartResponse,
      {}
    );
  };

  const handlerDecreaseQnntByOneResponse = useCallback((responseData) => {
    setCount((prev) => prev - 1);
    onDecrement(responseData?.cart);
  }, []);

  const handlerDecreaseQnntByOne = () => {
    const patchDecreaseQnntByOne = {
      type: "PATCH",
      endpoint: `/cart/items/decrease/quantity/id/${item?.id}?section=houseKeeping`,
    };

    decreaseQnntByOne.sendRequest(
      patchDecreaseQnntByOne,
      handlerDecreaseQnntByOneResponse,
      {}
    );
  };

  const handlerDecrement = (id) => {
    if (count == 1) {
      handlerRemoveFromCart(id);
    } else {
      handlerDecreaseQnntByOne();
    }
  };

  const handlerIncreaseQnntByOneResponse = useCallback((responseData) => {
    setCount((prev) => prev + 1);
    onIncrement(responseData?.cart);
  }, []);

  const handlerIncreaseQnntByOne = () => {
    const patchIncreaseQnntByOne = {
      type: "PATCH",
      endpoint: `/cart/items/increase/quantity/id/${item?.id}?section=houseKeeping`,
    };

    increaseQnntByOne.sendRequest(
      patchIncreaseQnntByOne,
      handlerIncreaseQnntByOneResponse,
      {}
    );
  };

  return (
    <ProductPaper>

      <Stack direction="row" justifyContent="space-between">
        <Avatar
          variant="rounded"
          src={item?.houseKeepingItem?.image}
          alt="Remy Sharp"
          sx={{ width: 120, height: 100, mr: 1.25 }}
        ></Avatar>



        <Stack justifyContent='' flex="1">
          <Stack direction='row' sx={{ mt: 1, mx: 1 }} justifyContent="space-between" flex="1">
            <Stack>
              <CartItemTitle sx={{ mt: 1.5 }}>{item?.houseKeepingItem?.name}</CartItemTitle>
            </Stack>
            <Stack>
              <ProductPrice color="#32842B">
                {currencySymbol} {item?.houseKeepingItem?.price}
              </ProductPrice>
            </Stack>
            {/* <Stack justifyContent="" direction="row" flex="1">
            <Stack justifyContent="">
              <ProductPrice color="#FC5012">
                ({currencySymbol}) {item?.price}{" "}
              </ProductPrice>
              <Stack direction="row" alignItems="" spacing={2}>
                <ProductTime>
                  <Stack direction="row" alignItems="">
                    <AccessTimeIcon sx={{ mr: 1 }} /> 2 min
                  </Stack>
                </ProductTime>
              </Stack>
            </Stack>
          </Stack> */}

          </Stack>
          <Box direction="row" display="flex" alignItems="center" mx={0.5}>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
              sx={{ background: "rgb(8, 10, 7)", width: '100%', display: 'flex', justifyContent: 'space-between' }}
              style={{ height: '35px' }}

            >
              <IconButton
                variant="contained"
                color="secondary"
                onClick={() => handlerDecrement(item?.id)}
                disabled={+count === 0}
              >
                <RemoveIcon />
              </IconButton>
              <IconButton variant="contained" color="secondary">
                {isLoading() ? (
                  <CircularProgress
                    style={{
                      color: HCTheme.whiteColorHC,
                      width: "24px",
                      height: "24px",
                    }}
                  />
                ) : (
                  <CartCountText>{count}</CartCountText>
                )}
              </IconButton>
              <IconButton
                variant="contained"
                color="secondary"
                onClick={handlerIncreaseQnntByOne}
              >
                <AddIcon />
              </IconButton>
            </ButtonGroup>
          </Box>

        </Stack>
      </Stack>


    </ProductPaper>
  );
};

export default PreCartHouseKeepingItem;
