import { Chip } from "@mui/material";
import { styled } from "@mui/system";

export const ChipHC = styled(Chip)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  borderRadius: "8px",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22px",
}));
