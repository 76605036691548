import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const PolicyText = styled(Typography)(({ theme }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#A7A7A7",
}));
export const PolicyLink = styled(Typography)(({ theme, underlined }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#0757CE",
  position: "absolute",
  bottom: "0",
  width: "100%",
  padding: "1rem",
}));
export const PolicyLinkNew = styled(Typography)(({ theme, underlined }) => ({
  fontFamily: "Satoshi-Variable",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#0757CE",
  width: "100%",
  padding: "1rem",
}));
