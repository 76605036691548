import { Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CONSTANT, { Token } from "../../utils/constant";
import useHttp from "../../Hooks/use-http";

const SignInWithURL = () => {
  const params = useParams();
  const api = useHttp();
  const navigate = useNavigate();
  // const [authToken, setAuthToken] = useState(null)

  const token = localStorage.getItem("authToken");

  const Services = axios.create({
    baseURL: CONSTANT.baseUrl,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  });

  console.log(localStorage.getItem("authToken"));

  //     const increaseScan = () => {
  //         // window.location.reload();
  //     try {
  //       (async () => {
  //         await Services.patch(CONSTANT.URLS.increaseScan.endpoint, {});
  //         setTimeout(() => {
  //           window.location.assign(`/home`);
  //         }, 1000);
  //       })();
  //     } catch (error) {}
  //   };

  useEffect(() => {
    // localStorage.setItem("authToken", params.token);
    Token.token = params.token;
    const payload = {
      token: params.token,
    };
    api.sendRequest(
      CONSTANT.URLS.loginToken,
      (res) => {
        // console.log(res);
        localStorage.setItem("authToken", res?.token);
        localStorage.setItem("CURRENCY_SYMBOL", res?.myCurrency?.symbol);
        localStorage.setItem("hotelName", res?.hotel?.name);
        localStorage.setItem("timezone", res?.hotel?.timezone);
        localStorage.setItem("logo", res?.hotel?.image);
        localStorage.setItem("roomNum", res?.roomNO);
        localStorage.setItem("plan", res?.hotel?.plan);
        localStorage.setItem("roomId", res?.roomId);

        if (+res.status !== 200 || !res?.isActive) {
          localStorage.clear();
          navigate("/auth-fallback");
        }
        window.location.assign(`/home`);
        //   window.location.reload();
        //    window.location.assign(`/home`);
      },
      payload
    );

    // increaseScan();
  }, [token]);

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default SignInWithURL;
